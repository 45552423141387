import { reactLocalStorage } from 'reactjs-localstorage'
import { noDecimalFormat } from '../helpers/formatters'
import { history } from '../App'

export const initDonutChart = (data, type, pluralType) => {
  if (!data || !data.series) return { title: { text: '' } }

  const colors = { 1: '#F3984B', 2: '#B9525A', 3: '#515BB3', 4: '#a78742' }

  const coloredData = data.series[0].data.map((item) => ({
    ...item,
    color: colors[item.id],
  }))

  const series = {
    ...data.series[0],
    data: coloredData,
  }

  return data.series
    ? {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: '100%',
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          pointFormat: '{point.y:.2f} - <b>{point.percentage:.0f}%</b>',
        },
        legend: {
          floating: true,
          verticalAlign: 'middle',
          layout: 'vertical',
          itemStyle: {
            fontWeight: 'normal',
          },
          labelFormatter: function() {
            const value = noDecimalFormat(parseInt(this.y, 10))
            const separator = this.id === 2 ? '<br>' : ' '
            const formattedType = this.y > 1 ? pluralType : type
            const label = `${this.name} :${separator}${value} ${formattedType}`
            return this.percentage > 50
              ? `<b>${label}</b><br/>`
              : `${label}<br/>`
          },
        },
        plotOptions: {
          pie: {
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
          series: {
            point: {
              events: {
                click: function(event) {
                  // GTT : uggly way to pass input throw <> pages
                  reactLocalStorage.set('billsStatutDefaultFilter', this.id)
                  //reactLocalStorage.set('billsEtatDefaultFilter', 'true');
                  reactLocalStorage.set(
                    'bills' + this.series.name + 'DefaultFilter',
                    this.id
                  )
                  //redirect tpo third tab in control/ page
                  history.push('/control/3')
                },
              },
            },
          },
        },
        series: [
          {
            type: 'pie',
            innerSize: '90%',
            ...series,
          },
        ],
      }
    : {}
}

export const initColumnChart = (data, stacked = false) => {
  if (data.series) {
    Object.keys(data.series).map((i) =>
      Object.keys(data.series[i].data).map(
        (j) => (data.series[i].data[j].x = parseFloat(data.series[i].data[j].x))
      )
    )
    return {
      ...data,
      chart: {
        type: 'column',
      },
      plotOptions: {
        column: {
          stacking: stacked ? 'normal' : '',
        },
      },
      colors: [
        '#31acea',
        '#c53ed1',
        '#00c793',
        '#ff4669',
        '#61687f',
        '#f4c202',
        '#b08b79',
        '#394eb6',
        '#ff560a',
        '#ff8a60',
        '#e84ea5',
        '#00bbd4',
        '#6535b9',
        '#69c9b0',
        '#cddc22',
        '#b9d8d8',
      ],
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: stacked
          ? '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>Total: <b>{point.stackTotal:.2f}</b>'
          : '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
      },
      yAxis: {
        title: '',
      },
      xAxis: {
        type: 'datetime',
      },
    }
  } else {
    return {}
  }
}

export const initBarChart = (data) => {
  const colors = [
    '#31acea',
    '#c53ed1',
    '#00c793',
    '#ff4669',
    '#61687f',
    '#f4c202',
    '#b08b79',
    '#394eb6',
    '#ff560a',
    '#ff8a60',
    '#e84ea5',
    '#00bbd4',
    '#6535b9',
    '#69c9b0',
    '#cddc22',
    '#b9d8d8',
  ]
  if (data.series) {
    const series = [
      {
        name: data.series[0].name,
        data: data.series[0].data.map((i, index) => ({
          y: i.y,
          color: colors[index % 16],
        })),
      },
    ]

    return {
      ...data,
      series,
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
      },
      yAxis: {
        title: '',
      },
      legend: {
        enabled: false,
      },
    }
  } else {
    return {}
  }
}

export const initLineChart = (data, rangeSelector) => {
  if (data.series) {
    Object.keys(data.series).map((i) => {
      data.series[i].lineWidth = 0.6
      return Object.keys(data.series[i].data).map(
        (j) => (data.series[i].data[j].x = parseFloat(data.series[i].data[j].x))
      )
    })
    return {
      ...data,
      chart: {
        zoomType: 'x',
        type: 'line',
        resetZoomButton: {
          position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: -70,
            y: 10,
          },
          relativeTo: 'chart',
        },
      },
      colors: [
        '#31acea',
        '#c53ed1',
        '#00c793',
        '#ff4669',
        '#61687f',
        '#f4c202',
        '#b08b79',
        '#394eb6',
        '#ff560a',
        '#ff8a60',
        '#e84ea5',
        '#00bbd4',
        '#6535b9',
        '#69c9b0',
        '#cddc22',
        '#b9d8d8',
      ],
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        opposite: false,
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        maxHeight: 80,
        itemWidth: 300,
        margin: 8,
        y: 0,
        navigation: {
          animation: true,
          arrowSize: 12,
          style: {
            fontWeight: 'bold',
            fontSize: '12px',
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            verticalAlign: 'top',
          },
        },
      },
    }
  } else {
    return {}
  }
}

export const cotationChart = (data, rangeSelector, color) => {
  if (data.series) {
    data.series = data.series.map((e) => ({
      ...e,
      data: e.data.filter((i) => i.y !== null),
    }))

    /*const values = [].concat(...data.series.map(e => e.data.map(i => i.y)));
		const min = Math.min(...values);
		const max = Math.max(...values);*/

    const colors = color
      ? [color]
      : [
          '#31acea',
          '#c53ed1',
          '#00c793',
          '#ff4669',
          '#61687f',
          '#f4c202',
          '#b08b79',
          '#394eb6',
          '#ff560a',
          '#ff8a60',
          '#e84ea5',
          '#00bbd4',
          '#6535b9',
          '#69c9b0',
          '#cddc22',
          '#b9d8d8',
        ]

    return {
      ...data,
      ...rangeSelector,
      chart: {
        type: 'line',
      },
      colors,
      title: {
        text: '',
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
      },
      plotOptions: {
        series: {
          turboThreshold: 0,
        },
      },
      yAxis: {
        opposite: false,
        /*min,
					max*/
      },
    }
  } else {
    return {}
  }
}

export const initCombinePieColChart = (pie, col) => {
  if (pie.series && col.series) {
    let colSeries = []
    Object.keys(col.series).map((i) =>
      Object.keys(col.series[i].data).map(
        (j) => (col.series[i].data[j].x = parseFloat(col.series[i].data[j].x))
      )
    )
    colSeries = Object.keys(col.series).map((i) => ({
      ...col.series[i],
      type: 'column',
    }))

    return {
      title: {
        text: '',
      },
      chart: {
        spacingTop: 85,
      },
      colors: [
        '#31acea',
        '#c53ed1',
        '#00c793',
        '#ff4669',
        '#61687f',
        '#f4c202',
        '#b08b79',
        '#394eb6',
        '#ff560a',
        '#ff8a60',
        '#e84ea5',
        '#00bbd4',
        '#6535b9',
        '#69c9b0',
        '#cddc22',
        '#b9d8d8',
      ],
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
      },
      //			legend: {
      //				enabled: false
      //			},
      yAxis: {
        title: '',
      },
      //			plotOptions: {
      //			    column: {
      //			        stacking: 'normal'
      //			    }
      //			},
      series: [
        ...colSeries,
        {
          ...pie.series[0],
          type: 'pie',
          innerSize: '75%',
          center: ['92%', -20],
          size: 150,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      xAxis: {
        type: 'datetime',
      },
    }
  } else {
    return {}
  }
}
