import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Datetime from 'react-datetime'
import moment from 'moment'
import * as user from 'commons'

import { dateFormatMonths } from '../../../helpers/formatters'
import { getDateRange, updateStartDate } from '../../../store/dateRange'

const DateRangeStartDateGlobal = ({
  dispatch,
  dateRange,
  location,
  lng,
  isDisabled,
}) => (
  <Datetime
    id="startDate"
    locale={lng}
    closeOnSelect
    timeFormat={false}
    dateFormat={dateFormatMonths()}
    viewMode="months"
    value={moment(dateRange.startDate).toDate()}
    onChange={(date) => dispatch(updateStartDate(date))}
    inputProps={{
      disabled: isDisabled,
    }}
  />
)

const mapStateToProps = (state) => ({
  dateRange: getDateRange(state),
  lng: user.lngIso(state),
})

DateRangeStartDateGlobal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  lng: PropTypes.string.isRequired,
}

export default withRouter(connect(mapStateToProps)(DateRangeStartDateGlobal))
