import React from 'react'
import Select from 'react-select'
import uniqBy from 'lodash/uniqBy'

const selectColor = '#a78742'
const focusColor = '#a7874255'

const styles = {
  control: (provided) => ({
    ...provided,
    minHeight: 'initial',
    borderRadius: 0,
    borderColor: '#d7dee4',
    boxShadow: 'unset',
    padding: 0,
    ':hover': {
      borderColor: '#d7dee4',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 2,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 2,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    padding: '4px 6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ':active': {
      background: selectColor,
      color: '#fff',
    },
    ...(!state.isFocused
      ? {}
      : {
          background: focusColor,
          color: 'unset',
        }),
    ...(!state.isSelected
      ? {}
      : {
          background: selectColor,
          color: '#fff',
        }),
  }),
}

const FilterSelect = (props) => {
  const {
    values,
    onChange,
    onSelect = () => {},
    initialValue,
    value,
    setValue,
  } = props

  const options =
    values.length === 0
      ? []
      : typeof values[0] === 'string'
      ? uniqBy(values).map((item) => ({ value: item, label: item, id: item }))
      : uniqBy(values, 'id').map((item) => ({
          value: item.label,
          label: item.label,
          id: item.id,
        }))

  React.useEffect(() => {
    if (value === null && options.length > 0 && !!initialValue) {
      const filter = initialValue === 4 ? 0 : initialValue
      const option = options.find((o) => o.id === filter)

      if (!option) return
      setValue(option.value)
      onChange(option.value)
    }
  }, [value, options, initialValue, onChange, setValue])

  return (
    <Select
      options={options}
      onChange={(option) => {
        const value = option ? option.value : ''
        onSelect()
        setValue(value)
        setTimeout(() => {
          onChange(value)
        })
      }}
      value={options.filter((option) => option.value === value)}
      placeholder=""
      isClearable={true}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      }}
      styles={styles}
    />
  )
}

export default FilterSelect
