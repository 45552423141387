import { store } from '../App'
import * as user from 'commons'

export const decimalFormat = (x, { digitCount = 2 } = {}) => {
  //let val;
  switch (user.lanId(store.getState())) {
    //anglais
    case 2:
      return new Intl.NumberFormat('en-EN', {
        maximumFractionDigits: digitCount,
        minimumFractionDigits: digitCount,
      }).format(x)
    //val = parseFloat(x).toFixed(2, 10).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    //return val !== "NaN" && val !== null && val !== undefined ? val : "-";
    case 1:
    //francais
    // eslint-disable-next-line no-fallthrough
    default:
      return new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: digitCount,
        minimumFractionDigits: digitCount,
      }).format(x)

    //val = parseFloat(x).toFixed(2, 10).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
    //return val !== "NaN" && val !== null && val !== undefined ? val : "-";
  }
}

export const noDecimalFormat = (x) => {
  //let val;
  switch (user.lanId(store.getState())) {
    case 2:
      return new Intl.NumberFormat('en-EN', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(x)
    //val = parseFloat(x).toFixed(0, 10).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    //return val !== "NaN" && val !== null && val !== undefined ? val : "-";
    case 1:
    default:
      return new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(x)
    //val = parseFloat(x).toFixed(0, 10).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
    //return val !== "NaN" && val !== null && val !== undefined ? val : "-";
  }
}

export const dateFormat = () => {
  switch (user.lanId(store.getState())) {
    case 2:
      return 'YYYY-MM-DD'
    case 1:
    default:
      return 'DD/MM/YYYY'
  }
}

export const dateFormatMonths = () => {
  switch (user.lanId(store.getState())) {
    case 2:
      return 'YYYY-MM'
    case 1:
    default:
      return 'MM/YYYY'
  }
}
