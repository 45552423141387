import React from 'react'
import ControlTables from './ControlTables'
import DateRange from '../common/DateRange'

const ControlPanel = () => (
  <div>
    <DateRange />
    <div className="billtable">
      <ControlTables />
    </div>
  </div>
)

export default ControlPanel
