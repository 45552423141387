import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { translate } from 'react-i18next'

import { getCountries, fetchCountries } from '../../../store/organisation'
import {
  getCountriesFilters,
  setCountriesFilters,
} from '../../../store/filters'

class Countries extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: this.props.filters,
    }
  }

  componentDidMount() {
    const { countries, fetchCountries } = this.props
    if (!countries) fetchCountries()
  }

  handleChange = (options) => {
    const values = options.map((option) => option.value)
    this.setState({ options: values })
    this.props.setFilters(values)
    if (typeof this.props.onChange === 'function') this.props.onChange()
  }

  render() {
    const { countries, isDisabled, t } = this.props
    const options = !countries
      ? []
      : countries.map(({ id, libelle }) => ({ value: id, label: libelle }))
    const value = this.state.options.map((id) =>
      options.find((x) => x.value === id)
    )

    return (
      <div>
        <label>{t('control.filter.pays')}</label>
        <Select
          placeholder={t('control.filter.select')}
          isMulti={true}
          isClearable={false}
          isDisabled={isDisabled}
          value={value}
          onChange={this.handleChange}
          options={options}
          isLoading={!options}
          className="react-select"
          classNamePrefix="react-select"
          styles={{
            container: (provided) => ({
              ...provided,
              width: '270px',
            }),
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  countries: getCountries(state),
  filters: getCountriesFilters(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchCountries: () => {
    dispatch(fetchCountries())
  },
  setFilters: (payload) => {
    dispatch(setCountriesFilters(payload))
  },
})

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(Countries)
)
