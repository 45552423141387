export const FETCH_BILLS_LIST_REQUEST =
  'einvoice/bills/FETCH_BILLS_LIST_REQUEST'
export const FETCH_BILLS_LIST_SUCCESS =
  'einvoice/bills/FETCH_BILLS_LIST_SUCCESS'
export const FETCH_BILLS_LIST_FAILURE =
  'einvoice/bills/FETCH_BILLS_LIST_FAILURE'

export const FETCH_BILLS_CHANGED_STATUS_REQUEST =
  'einvoice/bills/FETCH_BILLS_CHANGED_STATUS_REQUEST'
export const FETCH_BILLS_CHANGED_STATUS_SUCCESS =
  'einvoice/bills/FETCH_BILLS_CHANGED_STATUS_SUCCESS'
export const FETCH_BILLS_CHANGED_STATUS_FAILURE =
  'einvoice/bills/FETCH_BILLS_CHANGED_STATUS_FAILURE'

export const FETCH_BILL_REQUEST = 'einvoice/bills/FETCH_BILL_REQUEST'
export const FETCH_BILL_SUCCESS = 'einvoice/bills/FETCH_BILL_SUCCESS'
export const FETCH_BILL_FAILURE = 'einvoice/bills/FETCH_BILL_FAILURE'

export const PUT_BILL_REQUEST = 'einvoice/bills/PUT_BILL_REQUEST'
export const PUT_BILL_SUCCESS = 'einvoice/bills/PUT_BILL_SUCCESS'
export const PUT_BILL_FAILURE = 'einvoice/bills/PUT_BILL_FAILURE'

export const BILL_FILE_POST_REQUEST = 'einvoice/bills/BILL_FILE_POST_REQUEST'
export const BILL_FILE_POST_SUCCESS = 'einvoice/bills/BILL_FILE_POST_SUCCESS'
export const BILL_FILE_POST_FAILURE = 'einvoice/bills/BILL_FILE_POST_FAILURE'

export const BILL_FILE_DELETE_REQUEST =
  'einvoice/bills/BILL_FILE_DELETE_REQUEST'
export const BILL_FILE_DELETE_SUCCESS =
  'einvoice/bills/BILL_FILE_DELETE_SUCCESS'
export const BILL_FILE_DELETE_FAILURE =
  'einvoice/bills/BILL_FILE_DELETE_FAILURE'

export const FETCH_RELIED_BILL_REQUEST =
  'einvoice/bills/FETCH_RELIED_BILL_REQUEST'
export const FETCH_RELIED_BILL_SUCCESS =
  'einvoice/bills/FETCH_RELIED_BILL_SUCCESS'
export const FETCH_RELIED_BILL_FAILURE =
  'einvoice/bills/FETCH_RELIED_BILL_FAILURE'

export const FETCH_RELIED_COVERS_BILLS_REQUEST =
  'einvoice/bills/FETCH_RELIED_COVERS_BILLS_REQUEST'
export const FETCH_RELIED_COVERS_BILLS_SUCCESS =
  'einvoice/bills/FETCH_RELIED_COVERS_BILLS_SUCCESS'
export const FETCH_RELIED_COVERS_BILLS_FAILURE =
  'einvoice/bills/FETCH_RELIED_COVERS_BILLS_FAILURE'

export const FETCH_BILLS_BALANCE_SHEET_REQUEST =
  'einvoice/bills/FETCH_BILLS_BALANCE_SHEET_REQUEST'
export const FETCH_BILLS_BALANCE_SHEET_SUCCESS =
  'einvoice/bills/FETCH_BILLS_BALANCE_SHEET_SUCCESS'
export const FETCH_BILLS_BALANCE_SHEET_FAILURE =
  'einvoice/bills/FETCH_BILLS_BALANCE_SHEET_FAILURE'

export const FETCH_BILLS_COVER_REQUEST =
  'einvoice/bills/FETCH_BILLS_COVER_REQUEST'
export const FETCH_BILLS_COVER_SUCCESS =
  'einvoice/bills/FETCH_BILLS_COVER_SUCCESS'
export const FETCH_BILLS_COVER_FAILURE =
  'einvoice/bills/FETCH_BILLS_COVER_FAILURE'

export const FETCH_BILLS_PROVIDERS_REQUEST =
  'einvoice/bills/FETCH_BILLS_PROVIDERS_REQUEST'
export const FETCH_BILLS_PROVIDERS_SUCCESS =
  'einvoice/bills/FETCH_BILLS_PROVIDERS_SUCCESS'
export const FETCH_BILLS_PROVIDERS_FAILURE =
  'einvoice/bills/FETCH_BILLS_PROVIDERS_FAILURE'

export const FETCH_BILLS_VALIDATION_REQUEST =
  'einvoice/bills/FETCH_BILLS_VALIDATION_REQUEST'
export const FETCH_BILLS_VALIDATION_SUCCESS =
  'einvoice/bills/FETCH_BILLS_VALIDATION_SUCCESS'
export const FETCH_BILLS_VALIDATION_FAILURE =
  'einvoice/bills/FETCH_BILLS_VALIDATION_FAILURE'

export const FETCH_ALL_MONTHS_IN_PERIOD =
  'einvoice/bills/FETCH_ALL_MONTHS_IN_PERIOD'

export const BILL_FORM_UPDATE = 'einvoice/bills/BILL_FORM_UPDATE'
export const BILL_FORM_UPDATESTATUS = 'einvoice/bills/BILL_FORM_UPDATESTATUS'

export const BU_CHANGE_1 = 'einvoice/bills/BU_CHANGE_1'
export const BU_CHANGE_2 = 'einvoice/bills/BU_CHANGE_2'
export const BU_CHANGE_3 = 'einvoice/bills/BU_CHANGE_3'
export const COUNTRY_CHANGE_1 = 'einvoice/bills/COUNTRY_CHANGE_1'
export const COUNTRY_CHANGE_2 = 'einvoice/bills/COUNTRY_CHANGE_2'
export const COUNTRY_CHANGE_3 = 'einvoice/bills/COUNTRY_CHANGE_3'
export const REGION_CHANGE_1 = 'einvoice/bills/REGION_CHANGE_1'
export const REGION_CHANGE_2 = 'einvoice/bills/REGION_CHANGE_2'
export const REGION_CHANGE_3 = 'einvoice/bills/REGION_CHANGE_3'
export const PROVIDER_CHANGE_2 = 'einvoice/bills/PROVIDER_CHANGE_2'

export const CHANGE_STATUS_DEFINED = 'einvoice/bills/CHANGE_STATUS_DEFINED'

export const SELECT_BILL_IDS = 'einvoice/bills/SELECT_BILL_IDS'

export const SORT_BILLS_LIST = 'einvoice/bills/SORT_BILLS_LIST'

export const SET_POPOVER = 'einvoice/bills/SET_POPOVER'
