import * as env from '../helpers/env'

export const fetchVersion = async () => {
  let headers = new Headers()
  headers.append('Content-Type', 'application/json')
  let url = `${env.apiUrl()}version`
  return fetch(url, {
    credentials: 'include',
    headers,
  })
}
