import React, { Component } from 'react'
import { connect, ReactReduxContext } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import { Button } from 'reactstrap'

import Bill from './Bill'

import {
  getSortedBillsIds,
  getCurrentBillForm,
  getBillPutSuccess,
  getBillPutError,
  getBillsBalanceSheet,
  getBillsCover,
  fetchBill,
} from '../../../store/bills'

class VisibleBill extends Component {
  componentDidMount() {
    if (!!this.props.billId) {
      this.fetchData()
    }
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.billId && this.props.billId !== prevProps.billId) {
      this.fetchData()
    }
  }

  UNSAFE_componentWillReceiveProps({ billPutSuccess, billPutError }) {
    if (billPutSuccess !== this.props.billPutSuccess && billPutSuccess) {
      this.props.store.dispatch(
        Notifications.success({
          title: 'Bill has been saved successfully !',
          position: 'bl',
          autoDismiss: 4,
        })
      )
    }

    if (billPutError !== this.props.billPutError && billPutError) {
      this.props.store.dispatch(
        Notifications.error({
          title: 'Something went wrong !',
          position: 'bl',
          autoDismiss: 4,
        })
      )
    }
  }

  fetchData() {
    this.props.fetchBill(this.props.billId)
  }

  render() {
    const { billId, closeBill, openBill, filteredBillIds } = this.props

    if (!billId) return null

    const currIndex = filteredBillIds.indexOf(billId)
    const prev =
      filteredBillIds[currIndex - 1] ||
      filteredBillIds[filteredBillIds.length - 1]
    const next = filteredBillIds[currIndex + 1] || filteredBillIds[0]

    return (
      <div>
        <div className="bill-overlay" onClick={closeBill} />

        {filteredBillIds.length > 1 && (
          <div className="bill-nav">
            <Button onClick={() => openBill(prev)}>
              <i className="icon-smallarrow-left" />
            </Button>
            <Button onClick={() => openBill(next)}>
              <i className="icon-smallarrow-right" />
            </Button>
          </div>
        )}

        <div className="bill">
          <Bill {...this.props} closeBill={closeBill} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bill: getCurrentBillForm(state),
  bills: getBillsBalanceSheet(state),
  billsCover: getBillsCover(state),
  sortedBillsIds: getSortedBillsIds(state),
  billPutSuccess: getBillPutSuccess(state),
  billPutError: getBillPutError(state),
})

const mapDispatchToProps = {
  fetchBill,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <ReactReduxContext.Consumer>
    {({ store }) => <VisibleBill {...props} store={store} />}
  </ReactReduxContext.Consumer>
))
