import PropTypes from 'prop-types'
import React from 'react'

const KpiIndicator = ({ pourcentage }) => (
  <i
    className={[
      pourcentage < 0 ? 'icon-bigarrow-down' : '',
      pourcentage > 0 ? 'icon-bigarrow-up' : '',
      pourcentage === 0 ? 'icon-bigarrow-right' : '',
    ].join('')}
  />
)

KpiIndicator.propTypes = {
  pourcentage: PropTypes.number.isRequired,
}

export default KpiIndicator
