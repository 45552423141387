import React, { Component } from 'react'
import Dashboard from './Dashboard'
import { reactLocalStorage } from 'reactjs-localstorage'
import {
  getOrganisationIds,
  getBu,
  getCountries,
  getProviders,
  getRegions,
  fetchOrganisationIds,
} from '../../store/organisation'

import { connect } from 'react-redux'
class Home extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    this.props.fetchOrganisationIds()
  }

  render() {
    const { organisationIds, bu, countries, providers, regions } = this.props
    reactLocalStorage.setObject('organisationIds', organisationIds)
    reactLocalStorage.setObject('bu', bu)
    reactLocalStorage.setObject('countries', countries)
    reactLocalStorage.setObject('providers', providers)
    reactLocalStorage.setObject('regions', regions)
    return <div>{organisationIds.length > 0 ? <Dashboard /> : ''}</div>
  }
}

const mapStateToProps = (state) => ({
  organisationIds: getOrganisationIds(state),
  countries: getCountries(state),
  bu: getBu(state),
  providers: getProviders(state),
  regions: getRegions(state),
})

const mapDispatchToProps = {
  fetchOrganisationIds,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
