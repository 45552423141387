import React, { Component } from 'react'
import matchSorter from 'match-sorter'
import { CSVLink } from 'react-csv'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { compose } from 'recompose'
import pick from 'lodash/pick'
import classnames from 'classnames'
import { Button } from 'reactstrap'
import get from 'lodash/fp/get'

// Store
import {
  downloadInvoice,
  downloadInvoices,
  fetchBillsBalanceSheet,
  getBillsBalanceSheetFormatted,
  getBillsBalanceSheetError,
  getBillsBalanceSheetFetching,
} from 'store/bills'
import { getEndDate, getStartDate } from 'store/dateRange'
import { adaptPageSizeOptions } from 'helpers/reactTable'
import { fetchPdf } from 'store/pdf'

// Components
import Loading from '../common/Loading'
import Error from '../common/Error'
import FilterSelect from '../common/FilterSelect'

import { sortDates } from 'helpers/sort'
import { noDecimalFormat, decimalFormat } from 'helpers/formatters'

class BillsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      site: null,
      energie: null,
      fournisseur: null,
      cpt: null,
      numfac: null,
      datefac: null,
      debut: null,
      fin: null,
    }
  }

  createColumns() {
    this.setState({
      columns: [
        {
          Header: this.props.t('billstable.site'),
          id: 'site',
          accessor: (d) => d.site,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['site'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.props.filteredData.map(get(props.column.id))}
            />
          ),
          className: 'strong',
        },
        {
          Header: this.props.t('billstable.energie'),
          accessor: 'energie',
          Cell: (props) => (
            <span>
              {' '}
              <i
                className={props.value[1] === 2 ? 'icon-elec' : 'icon-gaz'}
                style={{ color: props.value[1] === 2 ? '#31acea' : '#c53ed1' }}
              />{' '}
              {props.value[0]}
            </span>
          ),
          id: 'energie',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['energie'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.props.filteredData.map(get('energie.0'))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.fournisseur'),
          accessor: 'fournisseur',
          id: 'fournisseur',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['fournisseur'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.props.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.counter'),
          accessor: 'cpt',
          id: 'cpt',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['cpt'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.props.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.numfac'),
          accessor: 'numfac',
          id: 'numfac',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['numfac'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.props.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.datefac'),
          accessor: 'datefac',
          id: 'datefac',
          sortMethod: sortDates,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['datefac'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          minWidth: 70,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.props.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.debut'),
          accessor: 'debut',
          id: 'debut',
          sortMethod: sortDates,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['debut'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          minWidth: 70,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.props.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.fin'),
          accessor: 'fin',
          id: 'fin',
          sortMethod: sortDates,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['fin'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          minWidth: 70,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.props.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.volume'),
          accessor: 'volume',
          Cell: (cell) => <span>{noDecimalFormat(cell.value)}</span>,
          id: 'volume',
          className: 'numberCell strong',
          filterable: false,
        },
        {
          Header: this.props.t('billstable.total'),
          accessor: 'total',
          Cell: (cell) => <span>{decimalFormat(cell.value)}</span>,
          id: 'total',
          className: 'numberCell strong',
          filterable: false,
        },
        {
          Header: <i className="icon-file-download" />,
          accessor: 'telechargement',
          id: 'telechargement',
          filterable: false,
          Cell: (props) => {
            return props.row.telechargement === true ? (
              <span className="icon-file-download"> </span>
            ) : null
          },
          style: {
            textAlign: 'center',
            cursor: 'pointer',
          },
          minWidth: 50,
        },
        {
          Header: <i className="icon-eye" />,
          accessor: 'visualisation',
          id: 'visualisation',
          filterable: false,
          resizable: false,
          Cell: (props) => <i className="icon-eye" />,
          style: {
            textAlign: 'center',
            cursor: 'pointer',
          },
          minWidth: 50,
        },
      ],
    })
  }

  componentDidMount() {
    this.createColumns()
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { formatted, lng } = this.props
    if (
      prevProps.formatted.map((bill) => bill.billId).join('') !==
        formatted.map((bill) => bill.billId).join('') ||
      prevProps.lng !== lng ||
      this.props.filteredData.length !== prevProps.filteredData.length
    ) {
      this.createColumns()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.props.filteredData.length !== nextProps.filteredData.length ||
      this.props.lng !== nextProps.lng ||
      JSON.stringify(this.state.columns) !== JSON.stringify(nextState.columns)
    )
  }

  handleFilter = (state) => {
    if (this.props.formatted.length === 0) return false
    const filteredIds = state.sortedData.reduce((acc, item) => {
      acc[item._original.billId] = true
      return acc
    }, {})
    const filteredData = this.props.formatted.filter(
      (element) => !!filteredIds[element.billId]
    )
    setTimeout(() => {
      this.props.setFilteredData(filteredData)
    })
    return false
  }

  download(e, id) {
    e.preventDefault()
    // this.props.downloadInvoice(DocUrl)
    fetchPdf(id)
  }

  fetchData() {
    this.props.fetchBillsBalanceSheet()
  }

  createCsvData = () => {
    const keys = [
      'site',
      'energie',
      'fournisseur',
      'cpt',
      'numfac',
      'datefac',
      'debut',
      'fin',
      'volume',
      'total',
    ]

    const csvHeader = this.state.columns
      .filter((col) => keys.includes(col.id))
      .map((col) => col.Header)
      .join(';')

    const csvValues = this.props.filteredData
      .map((data) => {
        const filtered = pick(
          {
            ...data,
            energie: data.energie[0],
            volume: noDecimalFormat(data.volume),
            total: decimalFormat(data.total),
          },
          keys
        )

        return Object.values(filtered)
          .map((s) => `"=""${s}"""`)
          .join(';')
      })
      .join('\n')

    return `${csvHeader}\n${csvValues}`
  }

  exportSelectionDocuments = (e) => {
    e.preventDefault()
    this.props.downloadInvoices(
      this.props.filteredData
        .filter((invoice) => invoice.telechargement === true)
        .map((invoice) => invoice.PdfId)
    )
  }

  render() {
    const { formatted, isLoading, t, openBill, error } = this.props

    if (error) return <Error />

    return (
      <div>
        <div className="buttonCsvDownload">
          <div>
            <CSVLink
              className={classnames('btn', 'csv-link', {
                disabled: this.props.formatted.length === 0,
              })}
              data={this.createCsvData()}
              filename={`E-Invoice - ${t('topNav.bills')}.csv`}
            >
              {this.props.t('button.exportcsv')}
            </CSVLink>
          </div>
          <div>
            <Button
              className={classnames('btn', 'csv-link', {
                disabled: this.props.formatted.length === 0,
              })}
              onClick={this.exportSelectionDocuments}
            >
              {t('button.exportdocs')}
            </Button>
          </div>
        </div>

        <ReactTable
          data={formatted}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={this.state.columns}
          defaultPageSize={20}
          pageSizeOptions={adaptPageSizeOptions(formatted)}
          rowsText={t('billstable.table.rowsText')}
          ofText={t('billstable.table.ofText')}
          previousText={t('billstable.table.previousText')}
          nextText={t('billstable.table.nextText')}
          loadingText={t('billstable.table.loadingText')}
          loading={isLoading}
          LoadingComponent={Loading}
          noDataText={t('billstable.table.noDataText')}
          style={{ fontWeight: '.875rem' }}
          getProps={this.handleFilter}
          getTdProps={(state, rowInfo, column, instance) => {
            if (rowInfo) {
              return {
                style: {
                  backgroundColor:
                    rowInfo.index % 2 === 0 ? '#f3f7f9' : 'white',
                },
                onClick: (e, handleOriginal) => {
                  if (column.id === 'visualisation') {
                    // this.props.history.push('/billsfacto/' + rowInfo.original.billId);
                    openBill(rowInfo.original.billId)
                    if (handleOriginal) {
                      handleOriginal()
                    }
                  } else if (
                    column.id === 'telechargement' &&
                    rowInfo.original.telechargement === true
                  ) {
                    this.download(e, rowInfo.original.PdfId)
                  }
                },
              }
            } else {
              return {}
            }
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  formatted: getBillsBalanceSheetFormatted(state),
  isLoading: getBillsBalanceSheetFetching(state),
  error: getBillsBalanceSheetError(state),
  dateDebut: getStartDate(state),
  dateFin: getEndDate(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadInvoice(DocUrl) {
    dispatch(downloadInvoice(DocUrl))
  },
  downloadInvoices(ids) {
    dispatch(downloadInvoices(ids))
  },
  fetchBillsBalanceSheet() {
    dispatch(fetchBillsBalanceSheet())
  },
})

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(BillsTable)
