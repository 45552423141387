import React, { Component } from 'react'
import { processSilentRenew } from 'redux-oidc'

class SilentRedirectPage extends Component {
  componentDidMount() {
    processSilentRenew()
  }

  render() {
    return <div></div>
  }
}

export default SilentRedirectPage
export { SilentRedirectPage }
