import React, { Component } from 'react'
import { Collapse, Table, Button } from 'reactstrap'
import classnames from 'classnames'

import { getReliedBills } from '../../../store/bills'

import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { decimalFormat } from '../../../helpers/formatters'
import { compose, withState, withHandlers } from 'recompose'

const icons = {
  'Volume contractuel': 'courbe-full',
  'Contract Volume': 'courbe-full',

  Energie: 'eclair',
  Energy: '',

  Transport: 'truck',
  'Grid Access': 'truck',

  Taxes: 'taxes',

  'Other(s)': 'vallet',
  'Autre(s)': 'vallet',
}

class ReliedBillsTable extends Component {
  eclair
  render() {
    const { recallBill, t, toggle, collapse } = this.props
    const liees = recallBill && recallBill.liees

    const bills = !liees
      ? []
      : liees.map((bill, i) => {
          const liee = recallBill.liees[i]
          return {
            ...bill,
            total: decimalFormat(liee.FacMontantHTVA),
            date: liee.FacDate,
          }
        })

    return (
      <div className="recall-bill_container">
        {bills.map((bill, index) => (
          <div className="recall-bill_collapse" key={index}>
            <Button
              onClick={() => toggle(index)}
              className={classnames('recall-bill_collapse-btn', {
                'recall-bill_collapse-btn--active': collapse === index,
              })}
            >
              <span>
                <i className="icon-ion-document" />
                <span className="recall-bill_num">{bill.FacClef}</span>
                <span>
                  {this.props.t('billstable.date')} : {bill.date}
                </span>
                <span>
                  {this.props.t('billstable.mtn')} : {bill.total} €
                </span>
              </span>

              <span>
                <i
                  className={
                    collapse === index
                      ? 'icon-collapse-moins'
                      : 'icon-collapse-plus'
                  }
                />
              </span>
            </Button>

            <Collapse isOpen={collapse === index}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>{t('bills.bill.poste')}</th>
                    <th>{t('bills.bill.detail')}</th>
                    <th>{t('bills.bill.periode')}</th>
                    <th>{t('bills.bill.montant')}</th>
                  </tr>
                </thead>
                {bill.FacGroupeLfa &&
                  bill.FacGroupeLfa.map((i, iIndex) => (
                    <tbody key={iIndex}>
                      {i.IatAgrLignesFacture.map((j, jIndex) => (
                        <tr key={j.id} className={'row-' + (iIndex % 2)}>
                          {!jIndex && (
                            <td rowSpan={i.IatAgrLignesFacture.length}>
                              <i className={'icon-' + icons[i.IatAgrLibelle]} />
                              <p>
                                <span>{i.IatAgrLibelle}</span>
                                <br />
                                <span className="price">
                                  {decimalFormat(
                                    i.IatAgrLignesFacture.reduce(
                                      (cnt, l) => cnt + l.LfaValeur,
                                      0
                                    )
                                  )}{' '}
                                  {i.IatAgrUniteOuDevise}
                                </span>
                              </p>
                            </td>
                          )}
                          <td>{j.LfaLibelle}</td>
                          <td>
                            {j.LfaDateDebut}
                            {' - '}
                            {j.LfaDateFin}
                          </td>
                          <td className="text-right">
                            {decimalFormat(j.LfaValeur)} {i.IatAgrUniteOuDevise}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
              </Table>
            </Collapse>
          </div>
        ))}

        <br />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  recallBill: getReliedBills(state),
})

export default compose(
  translate(),
  withRouter,
  connect(mapStateToProps),
  withState('collapse', 'toggle', -1),
  withHandlers({
    toggle: ({ toggle, collapse }) => (i) => toggle(collapse === i ? -1 : i),
  })
)(ReliedBillsTable)
