import React from 'react'
import { Spinner } from 'reactstrap'

const Loading = ({ loading = true } = {}) =>
  !loading ? null : (
    <div className="-loading -active">
      <div className="-loading-inner">
        <Spinner
          style={{
            width: '40px',
            height: '40px',
            borderWidth: '6px',
            color: '#a78742',
          }}
        />
      </div>
    </div>
  )

export default Loading
