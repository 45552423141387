import { apiConf as api } from 'commons'

export const downloadInvoice = (invoiceUrl) => {
  return api.superFetch({ url: invoiceUrl })
}

export const downloadInvoices = (invoiceIds) => {
  return api.superFetch({
    url: 'pdfs/download',
    method: 'POST',
    platform: 'INVOICE',
    body: invoiceIds,
  })
}

export const fetchBillsList = (uuid, dateDebut, dateFin, perimetre, lanId) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  return api.superFetch({
    url: `listefactures?date_debut=${dateDebut}&date_fin=${dateFin}&lanId=${lanId}`,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
  })
}

export const fetchBill = (uuid, billId, lanId) => {
  return api.superFetch({
    url: `facture?facid=${billId}&lanId=${lanId}`,
    uuid: uuid,
  })
}

export const putBill = (uuid, bill) => {
  const billUpdate = {
    id: bill.id,
    facClef: bill.FacClef,
    cpfCpfId: bill.CpfCpfId,
    datePaiement: bill.FacDatePaiement,
    commentaire: bill.FacNoteContenu,
    statut: bill.FacStatutEvaluationUtilisateur,
  }

  return api.superFetch({
    url: 'facture',
    method: 'PUT',
    body: billUpdate,
    uuid: uuid,
  })
}

export const postBillFile = (uuid, billId, file) => {
  let doc = new FormData()
  doc.append('file', file)

  return api.superFetch({
    url: `facture/document/${billId}`,
    method: 'POST',
    body: doc,
    stringify: false,
    contentType: null,
    uuid: uuid,
  })
}

export const deleteBillFile = (uuid, fileId) => {
  return api.superFetch({
    url: `facture/document/${fileId}`,
    method: 'DELETE',
    uuid: uuid,
  })
}

export const fetchBillsBalanceSheet = (dateDebut, dateFin, payload) => {
  if (!payload) {
    return Promise.resolve()
  }
  return api.superFetch({
    url: `facture/bilanFacture?date_debut=${dateDebut}&date_fin=${dateFin}`,
    method: 'POST',
    body: payload,
  })
}

export const fetchBillsCover = (dateDebut, dateFin, payload) => {
  if (!payload) {
    return Promise.resolve()
  }
  return api.superFetch({
    url: `facture/couverture?date_debut=${dateDebut}&date_fin=${dateFin}`,
    method: 'POST',
    body: payload,
  })
}

export const fetchBillsProviders = (dateDebut, dateFin, payload) => {
  if (!payload) {
    return Promise.resolve()
  }
  return api.superFetch({
    url: `facture/comptefournisseur?date_debut=${dateDebut}&date_fin=${dateFin}`,
    method: 'POST',
    body: payload,
  })
}

export const fetchBillsValidation = (dateDebut, dateFin, payload) => {
  if (!payload) {
    return Promise.resolve()
  }
  return api.superFetch({
    url: `facture/bilanregul?date_debut=${dateDebut}&date_fin=${dateFin}`,
    method: 'POST',
    body: payload,
  })
}

export const fetchReliedCoversBills = (billId) => {
  return api.superFetch({
    url: `facture/${billId}/bilanregul`,
  })
}

export const fetchReliedBill = (billId) => {
  return api.superFetch({
    url: `facture/${billId}/facturesliees`,
  })
}

export const changeStatusBills = (payload) => {
  if (!payload) {
    return Promise.resolve()
  }

  return api.superFetch({
    url: `facture/statut`,
    method: 'POST',
    body: payload,
  })
}
