import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Progress,
  Spinner,
} from 'reactstrap'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { compose } from 'redux'

import { getStartDate, getEndDate } from 'store/dateRange'
import { getKpiQuality, fetchKpiQuality } from 'store/kpi'

const getColor = (percent) =>
  percent < 50
    ? 'danger'
    : percent < 85
    ? 'warning'
    : percent <= 100
    ? 'success'
    : 'muted'

const ProgressCard = ({
  t,
  quality,
  loading,
  error,
  fetchKpiQuality,
  startDate,
  endDate,
}) => {
  const [isInit, setIsInit] = React.useState(false)

  React.useEffect(() => {
    fetchKpiQuality()
    setIsInit(true)
  }, [startDate, endDate, fetchKpiQuality])

  if (!isInit) return null

  const { pourcentage, integrees, theorique } = quality
  const color = getColor(pourcentage)

  return (
    <Card className="h-100">
      <CardHeader>{t('kpi.integratedInvoices')}</CardHeader>
      <CardBody
        style={{
          padding: 0,
          margin: '1.25rem',
          position: 'relative',
          minHeight: 125,
        }}
      >
        {loading ? (
          <span>{t('global.loading')}</span>
        ) : error ? (
          <span>{error}</span>
        ) : (
          <CardTitle
            style={{
              fontSize: '2rem',
            }}
          >
            <span className={`text-${color}`}>{integrees}</span>
            <span> / {theorique}</span>
          </CardTitle>
        )}

        <Progress
          striped
          color={color}
          value={pourcentage}
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            height: '2rem',
          }}
        >
          {loading ? (
            <Spinner
              style={{
                width: '1.2rem',
                height: '1.2rem',
                borderWidth: '4px',
                margin: '0 8px',
              }}
            />
          ) : (
            <span style={{ padding: '0 8px' }}>
              {Math.round(pourcentage)} %
            </span>
          )}
        </Progress>
      </CardBody>
    </Card>
  )
}

const mapState = (state) => ({
  ...getKpiQuality(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
})

const mapDispatch = {
  fetchKpiQuality,
}

export default compose(
  connect(mapState, mapDispatch),
  translate()
)(ProgressCard)
