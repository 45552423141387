import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import { deleteBillPdf, fetchPdf } from 'store/pdf'

class BillPdf extends Component {
  constructor(props) {
    super(props)

    this.deleteDocument = this.deleteDocument.bind(this)
  }

  download(e) {
    e.preventDefault()
    fetchPdf(this.props.id)
  }

  deleteDocument(e) {
    e.preventDefault()
    const { id, dispatch } = this.props
    dispatch(deleteBillPdf(id, this.props.bill))
  }

  render() {
    const { number } = this.props

    return (
      <li className="d-flex align-items-center">
        <i className="icon-ion-document" /> {number}
        <span className="ml-auto">
          <Link
            className="btn btn-link"
            onClick={(e) => this.download(e)}
            to={'void(0)'}
          >
            <i className="icon-file-download" />
          </Link>
          <Button color="link" onClick={(e) => this.deleteDocument(e)}>
            <i className="icon-cross" />
          </Button>
        </span>
      </li>
    )
  }
}

export default connect()(BillPdf)
