export const getBillsListUuid = (state) => state.bills.billsListUuid
export const getBillUuid = (state) => state.bills.billUuid
export const getPutBillUuid = (state) => state.bills.putBillUuid
export const getBillsIds = (state) => state.bills.list.billsIds || []
export const getBillsList = (state) => state.bills.list.bill || {}
export const getBillPutSuccess = (state) => state.bills.billPutSuccess
export const getBillPutError = (state) => state.bills.billPutError
export const getPostBillFileUuid = (state) => state.bills.postBillFileUuid
export const getDeleteBillFileUuid = (state) => state.bills.deleteBillFileUuid
export const getSortedBillsIds = (state) => state.bills.sortList
export const getSortState = (state) => state.bills.sortState
export const getMonthsInPeriod = (state) => state.bills.monthsInPeriod || []

export const getBillById = (state, id) => state.bills.bills[id]
export const getCurrentBillForm = (state) => state.bills.currentBillForm || {}
export const getCurrentBillFormId = (state) =>
  state.bills.currentBillForm.id || 0

export const getBillsBalanceSheet = (state) =>
  state.bills.billsBalanceSheet.data || []
export const getBillsBalanceSheetFormatted = (state) =>
  state.bills.billsBalanceSheet.formatted
export const getBillsBalanceSheetRequest = (state) =>
  state.bills.billsBalanceSheetRequest
export const getBillsBalanceSheetFetching = (state) =>
  state.bills.billsBalanceSheetFetching
export const getBillsBalanceSheetError = (state) =>
  state.bills.billsBalanceSheetError

export const getBillsCover = (state) => state.bills.billsCover.data || []
export const getBillsCoverFormatted = (state) =>
  state.bills.billsCover.formatted
export const getBillsCoverRequest = (state) => state.bills.billsCoverRequest
export const getBillsCoverFetching = (state) => state.bills.billsCoverFetching
export const getBillsCoverError = (state) => state.bills.billsCoverError

export const getBillsProviders = (state) =>
  state.bills.billsProviders.data || []
export const getBillsProvidersFormatted = (state) =>
  state.bills.billsProviders.formatted
export const getBillsProvidersRequest = (state) =>
  state.bills.billsProvidersRequest
export const getBillsProvidersFetching = (state) =>
  state.bills.billsProvidersFetching
export const getBillsProvidersError = (state) => state.bills.billsProvidersError

export const getBillsValidation = (state) =>
  state.bills.billsValidation.data || []
export const getBillsValidationFormatted = (state) =>
  state.bills.billsValidation.formatted || []
export const getBillsValidationRequest = (state) =>
  state.bills.billsValidationRequest
export const getBillsValidationFetching = (state) =>
  state.bills.billsValidationFetching
export const getBillsValidationError = (state) =>
  state.bills.billsValidationError

export const getReliedBills = (state) => state.bills.reliedBills.data || null
export const getReliedBillsFetching = (state) =>
  state.bills.reliedBills.isFetching
export const getReliedBillsError = (state) => state.bills.reliedBills.error

export const getReliedCoversBills = (state) =>
  state.bills.reliedCoversBills.data || []
export const getReliedCoversBillsFetching = (state) =>
  state.bills.reliedCoversBills.isFetching
export const getReliedCoversBillsError = (state) =>
  state.bills.reliedCoversBills.error

export const getReliedBillsStatus = (state) => state.bills.reliedBillsStatus

export const getDefineStatusCodeUsetoChange = (state) =>
  state.bills.defineStatusCodeUseToChange || ''
export const getSelectBillIds = (state) => state.bills.selectBillIds || []

export const getPopover = (state) => state.bills.popover
