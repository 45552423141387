import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { translate } from 'react-i18next'

import { getRegions, fetchRegions } from '../../../store/organisation'
import { getRegionsFilters, setRegionsFilters } from '../../../store/filters'

class Regions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: this.props.filters,
    }
  }

  componentDidMount() {
    const { regions, fetchRegions } = this.props
    if (!regions) fetchRegions()
  }

  handleChange = (options) => {
    const values = options.map((option) => option.value)
    this.setState({ options: values })
    this.props.setFilters(values)
    if (typeof this.props.onChange === 'function') this.props.onChange()
  }

  render() {
    const { regions, isDisabled, t } = this.props

    let options = []

    if (!!regions) {
      const { Id, label, children } = regions
      const flatRegions = !children ? [regions] : [{ Id, label }, ...children]
      options = flatRegions.map(({ Id, label }) => ({ value: Id, label }))
    }

    const value = this.state.options.map((id) =>
      options.find((x) => x.value === id)
    )

    return (
      <div>
        <label>{t('control.filter.region')}</label>
        <Select
          placeholder={t('control.filter.select')}
          isMulti={true}
          isClearable={false}
          isDisabled={isDisabled}
          value={value}
          onChange={this.handleChange}
          options={options}
          isLoading={!regions}
          className="react-select"
          classNamePrefix="react-select"
          styles={{
            container: (provided) => ({
              ...provided,
              width: '270px',
            }),
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  regions: getRegions(state),
  filters: getRegionsFilters(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchRegions: () => {
    dispatch(fetchRegions())
  },
  setFilters: (payload) => {
    dispatch(setRegionsFilters(payload))
  },
})

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(Regions)
)
