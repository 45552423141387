import { createStore, applyMiddleware, compose } from 'redux'
import { loadUser } from 'redux-oidc'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
import { userManager, accessTokenMiddleware } from 'commons'
import ReactGA from 'react-ga'
import get from 'lodash/get'

import rootReducer from './store'
import { loadState, saveState } from './helpers/localStorage'

const getEid = (state) => get(state, 'oidc.user.profile.eid', false)
const getName = (state) => get(state, 'oidc.user.profile.name', false)
const getUor = (state) => get(state, 'oidc.user.profile.uor', false)

const configureStore = () => {
  const middleware = [thunk, accessTokenMiddleware]

  const persistedState = loadState()
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(...middleware))
  )

  loadUser(store, userManager)

  // Reset store if the user has a different id
  let prevEid = localStorage.getItem('prevEid')
  let prevUserId = ''
  store.subscribe(() => {
    const state = store.getState()

    const eid = getEid(state)
    if (!!eid && prevEid !== eid) {
      localStorage.setItem('prevEid', eid)
      prevEid = eid
      store.dispatch({ type: 'RESET_ALL' })
    }

    const userId = `${getName(state)}_${getUor(state)}`
    if (!!userId && prevUserId !== userId) {
      prevUserId = userId
      ReactGA.set({ userId })
    }
  })

  store.subscribe(
    throttle(() => {
      saveState({
        oidc: store.getState().oidc,
        countries: store.getState().countries,
        providers: store.getState().providers,
        contracts: store.getState().contracts,
      })
    }, 1000)
  )

  return store
}

export default configureStore
