import { reactLocalStorage } from 'reactjs-localstorage'
import download from 'downloadjs'
import v4 from 'uuid/v4'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { isNullOrUndefined } from 'util'

import * as user from 'commons'
import { getStartDate, getEndDate } from '../dateRange'
import { getOrganisationIds } from '../organisation'

import * as bills from './api'
import {
  getPutBillUuid,
  getCurrentBillForm,
  getCurrentBillFormId,
  getPostBillFileUuid,
  getDeleteBillFileUuid,
  getDefineStatusCodeUsetoChange,
  getSelectBillIds,
  getBillsBalanceSheetRequest,
  getBillsCoverRequest,
  getBillsProvidersRequest,
  getBillsValidationRequest,
} from './selectors'

import {
  getBusFilters,
  getCountriesFilters,
  getProvidersFilters,
  getRegionsFilters,
} from '../filters'

import * as types from './types'

const isValidStatus = (status) => status >= 200 || status < 300

export const downloadInvoice = (invoiceUrl) => async (dispatch, getState) => {
  try {
    const resp = await bills.downloadInvoice(invoiceUrl)

    if (resp.status === 200) {
      const disposition = resp.headers.get('content-disposition')
      let filename = ''
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      const blob = await resp.blob()
      const contentType = resp.headers.get('Content-Type')
      download(blob, filename, contentType)
    } else {
      console.warn('Unable to download invoices documents')
    }
  } catch (error) {
    console.error('Error downloading invoice', error.message)
  }
}

export const downloadInvoices = (invoiceIds) => async (dispatch, getState) => {
  try {
    const resp = await bills.downloadInvoices(invoiceIds)

    if (resp.status === 200) {
      const disposition = resp.headers.get('content-disposition')
      let filename = ''
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      const blob = await resp.blob()
      const contentType = resp.headers.get('Content-Type')
      download(blob, filename, contentType)
    } else {
      console.warn('Unable to download invoices documents')
    }
  } catch (error) {
    console.error('Error downloading invoice', error.message)
  }
}

export const sortBillsList = (sort) => (dispatch, getState) => {
  dispatch({
    type: types.SORT_BILLS_LIST,
    sort,
    list: getState().bills.list,
  })
}

export const fetchBill = (billId) => async (dispatch, getState) => {
  const uuid = v4()
  const state = getState()

  // const bill = getBillById(state, billId)
  // if (!!bill) return

  dispatch({
    type: types.FETCH_BILL_REQUEST,
    uuid,
    billId,
  })

  const lanId = user.lanId(state)

  try {
    const resp = await bills.fetchBill(uuid, billId, lanId)
    if (!isValidStatus(resp.status)) throw new Error(`Error ${resp.status}`)

    const data = await resp.json()

    const newResponse = {
      ...data,
      FacDatePaiement:
        data.FacDatePaiement && data.FacDatePaiement !== '01/01/0001'
          ? data.FacDatePaiement
          : null,
    }

    dispatch({
      type: types.FETCH_BILL_SUCCESS,
      response: newResponse,
      billId,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_BILL_FAILURE,
      message: error.message || 'Something went wrong.',
      billId,
    })
  }
}

export const fetchReliedBill = (billId) => (dispatch, getState) => {
  dispatch({
    type: types.FETCH_RELIED_BILL_REQUEST,
  })
  return bills.fetchReliedBill(billId).then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: types.FETCH_RELIED_BILL_SUCCESS,
          response: response,
          billId,
        })
      })
    },
    (error) => {
      dispatch({
        type: types.FETCH_RELIED_BILL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchReliedCoversBills = (billId) => (dispatch, getState) => {
  dispatch({
    type: types.FETCH_RELIED_COVERS_BILLS_REQUEST,
  })
  return bills.fetchReliedCoversBills(billId).then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: types.FETCH_RELIED_COVERS_BILLS_SUCCESS,
          response: response,
          billId,
        })
      })
    },
    (error) => {
      dispatch({
        type: types.FETCH_RELIED_COVERS_BILLS_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const putBill = () => (dispatch, getState) => {
  const uuid = v4()

  const bill = getCurrentBillForm(getState())

  dispatch({
    type: types.PUT_BILL_REQUEST,
    uuid,
    billId: bill.id,
  })

  return bills.putBill(uuid, bill).then(
    (response) => {
      if (response.status === 200) {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getPutBillUuid(getState())
        ) {
          response.json().then((response) => {
            dispatch({
              type: types.PUT_BILL_SUCCESS,
              response,
              billId: bill.id,
            })
          })
        }
      } else {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getPutBillUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: types.PUT_BILL_FAILURE,
              message: response.Message,
              billId: bill.id,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: types.PUT_BILL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const updateBillForm = (update) => (dispatch) => {
  dispatch({
    type: types.BILL_FORM_UPDATE,
    update,
  })
}

export const updateBillStatus = (FacEtatUtilisateur) => (dispatch) => {
  dispatch({
    type: types.BILL_FORM_UPDATESTATUS,
    FacEtatUtilisateur,
  })
  dispatch(putBill())
}

export const postBillFile = (file) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: types.BILL_FILE_POST_REQUEST,
    uuid,
  })

  const billId = getCurrentBillFormId(getState())

  return bills.postBillFile(uuid, billId, file).then(
    (response) => {
      if (response.status === 200) {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') ===
            getPostBillFileUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: types.BILL_FILE_POST_SUCCESS,
              response,
              billId,
            })
          )
        }
      } else {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') ===
            getPostBillFileUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: types.BILL_FILE_POST_FAILURE,
              message: response.Message,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: types.BILL_FILE_POST_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const deleteBillFile = (fileId) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: types.BILL_FILE_DELETE_REQUEST,
    uuid,
  })

  return bills.deleteBillFile(uuid, fileId).then(
    (response) => {
      if (response.status === 200) {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') ===
            getDeleteBillFileUuid(getState())
        ) {
          dispatch({
            type: types.BILL_FILE_DELETE_SUCCESS,
            fileId,
          })
        }
      } else {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') ===
            getDeleteBillFileUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: types.BILL_FILE_DELETE_FAILURE,
              message: response.Message,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: types.BILL_FILE_DELETE_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchBillsBalanceSheet = () => async (dispatch, getState) => {
  const state = getState()

  let perimetre = getOrganisationIds(state)
  if (!perimetre || perimetre !== []) {
    perimetre = reactLocalStorage.getObject('organisationIds')
  }

  const dateDebut = getStartDate(state)
  const dateFin = getEndDate(state)

  const request = { dateDebut, dateFin }
  const prevRequest = getBillsBalanceSheetRequest(state)

  if (isEqual(request, prevRequest)) return

  dispatch({
    type: types.FETCH_BILLS_BALANCE_SHEET_REQUEST,
    request: { dateDebut, dateFin },
  })

  try {
    const resp = await bills.fetchBillsBalanceSheet(
      dateDebut,
      dateFin,
      perimetre
    )

    if (!isValidStatus(resp.status)) throw new Error(`Error ${resp.status}`)

    const data = await resp.json()

    const formatted = data.map((element) => {
      return {
        site: element.FacSite.SphNom,
        energie: [element.FacEnergie, element.FacNrjId],
        fournisseur: element.FacFournisseur,
        cpt: element.FacPerimetre.PeeClef,
        numfac: element.FacClef,
        datefac: element.FacDate,
        debut: element.FacDebutConso,
        fin: element.FacFinConso,
        volume: element.FacVolume,
        partene: element.FacMontantEnergie,
        parttrans: element.FacMontantTransport,
        parttaxes: element.FacMontantTaxe,
        total: element.FacMontantHTVA,
        telechargement: element.HasFacture,
        PdfId: element.PdfId,
        visualisation: '',
        billId: element.id,
      }
    })

    dispatch({
      type: types.FETCH_BILLS_BALANCE_SHEET_SUCCESS,
      data,
      formatted,
      request,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_BILLS_BALANCE_SHEET_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchBillsCover = () => async (dispatch, getState) => {
  const state = getState()

  let perimetre = getOrganisationIds(state)

  if (!perimetre || perimetre !== []) {
    perimetre = reactLocalStorage.getObject('organisationIds')
  }

  const filtres = {
    filtreUor: getBusFilters(state),
    filtreGeo: getRegionsFilters(state),
    filtrePays: getCountriesFilters(state),
    filtreFournisseur: [],
  }

  const payload = {
    perimetre,
    ...filtres,
  }

  const dateDebut = getStartDate(state)
  const dateFin = getEndDate(state)

  const request = { dateDebut, dateFin, ...filtres }
  const prevRequest = getBillsCoverRequest(state)

  if (isEqual(request, prevRequest)) return

  dispatch({ type: types.FETCH_BILLS_COVER_REQUEST })

  try {
    const resp = await bills.fetchBillsCover(dateDebut, dateFin, payload)
    if (!isValidStatus(resp.status)) throw new Error(`Error ${resp.status}`)

    const data = await resp.json()

    const formatted = data.map((element) => {
      const allMonths = element.ListeMensuelleFacture.map((x) => {
        const factures = !x.ListeFacture ? [] : x.ListeFacture
        return {
          dateHeader: moment(x.Mois, 'YYYY-MM-DD').format('MM/YYYY'),
          listeLengh: x.ListeFacture.length,
          month: new Date(moment(x.Mois, 'YYYY-MM-DD')).getMonth() + 1,
          year: new Date(moment(x.Mois, 'YYYY-MM-DD')).getFullYear(),
          factures,
        }
      })

      let billIds = []

      allMonths.forEach((month) => {
        month.factures.forEach((facture) => {
          billIds.push(facture.id)
        })
      })

      let tabItem = {
        site: element.Site,
        energy: [element.Energie, element.IdEnergie],
        fournisseur: element.Fournisseur,
        cpt: element.Compteur,
        billId: element.id,
      }

      allMonths.forEach((month) => {
        let propertyName = month.month.toString() + month.year.toString()

        tabItem[propertyName] = []
        tabItem[propertyName][0] = !isNullOrUndefined(
          allMonths.find(
            (x) => x.month === month.month && x.year === month.year
          )
        )
          ? allMonths.find(
              (x) => x.month === month.month && x.year === month.year
            ).listeLengh
          : 0
        tabItem[propertyName][1] = element.id
        tabItem[propertyName][2] = element.Compteur.toString().replace(
          /\s/g,
          ''
        )
        tabItem[propertyName][3] = !isNullOrUndefined(
          allMonths.find(
            (x) => x.month === month.month && x.year === month.year
          )
        )
          ? allMonths.find(
              (x) => x.month === month.month && x.year === month.year
            ).factures
          : []
      })

      return tabItem
    })

    dispatch({
      type: types.FETCH_BILLS_COVER_SUCCESS,
      data,
      formatted,
      request,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_BILLS_COVER_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchMonthsBetweenTwoDate = () => (dispatch, getState) => {
  // const dateDebut = getStartDate(getState());
  // const dateFin = getEndDate(getState());
  var allMonthsInPeriod = []
  dispatch({
    type: types.FETCH_ALL_MONTHS_IN_PERIOD,
    response: allMonthsInPeriod,
  })
}

export const fetchBillsProviders = () => async (dispatch, getState) => {
  const state = getState()

  let perimetre = getOrganisationIds(state)

  if (!perimetre || perimetre !== []) {
    perimetre = reactLocalStorage.getObject('organisationIds')
  }

  const filtres = {
    filtreUor: getBusFilters(state),
    filtreGeo: getRegionsFilters(state),
    filtrePays: getCountriesFilters(state),
    filtreFournisseur: getProvidersFilters(state),
  }

  const payload = {
    perimetre,
    ...filtres,
  }

  const dateDebut = getStartDate(state)
  const dateFin = getEndDate(state)

  const request = { dateDebut, dateFin, ...filtres }
  const prevRequest = getBillsProvidersRequest(state)

  if (isEqual(request, prevRequest)) return

  dispatch({
    type: types.FETCH_BILLS_PROVIDERS_REQUEST,
  })

  try {
    const resp = await bills.fetchBillsProviders(dateDebut, dateFin, payload)
    if (!isValidStatus(resp.status)) throw new Error(`Error ${resp.status}`)

    const data = await resp.json()

    const formatted = data.map((bill) => {
      let billedCal = !isNullOrUndefined(
          bill.Depenses.find((x) => x.PhaseCode === 7)
        )
          ? bill.Depenses.find((x) => x.PhaseCode === 7).Depense
          : 0,
        forecastCal = !isNullOrUndefined(
          bill.Depenses.find((x) => x.PhaseCode === 6)
        )
          ? bill.Depenses.find((x) => x.PhaseCode === 6).Depense
          : 0
      billedCal = !isNullOrUndefined(billedCal) ? billedCal : 0
      forecastCal = !isNullOrUndefined(forecastCal) ? forecastCal : 0

      return {
        provider: bill.Fournisseur,
        source: moment(bill.Mois).format('MM/YYYY'),
        site: bill.SiteNom,
        pdl: bill.PeeClef,
        billed: parseFloat(Number.parseFloat(billedCal).toFixed(2)),
        forecast: parseFloat(Number.parseFloat(forecastCal).toFixed(2)),
        solde: parseFloat(
          Number.parseFloat(billedCal - forecastCal).toFixed(2)
        ),
      }
    })

    dispatch({
      type: types.FETCH_BILLS_PROVIDERS_SUCCESS,
      data,
      formatted,
      request,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_BILLS_PROVIDERS_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchBillsValidation = ({ force = false } = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()

  let perimetre = getOrganisationIds(state)

  if (!perimetre || perimetre !== []) {
    perimetre = reactLocalStorage.getObject('organisationIds')
  }

  const filtres = {
    filtreUor: getBusFilters(state),
    filtreGeo: getRegionsFilters(state),
    filtrePays: getCountriesFilters(state),
    filtreFournisseur: [],
  }

  const payload = {
    perimetre,
    ...filtres,
  }

  const dateDebut = getStartDate(state)
  const dateFin = getEndDate(state)

  const request = { dateDebut, dateFin, ...filtres }
  const prevRequest = getBillsValidationRequest(state)

  if (!force && isEqual(request, prevRequest)) return

  dispatch({
    type: types.FETCH_BILLS_VALIDATION_REQUEST,
  })

  try {
    const resp = await bills.fetchBillsValidation(dateDebut, dateFin, payload)
    if (!isValidStatus(resp.status)) throw new Error(`Error ${resp.status}`)

    const data = await resp.json()

    const formatted = data.map((bill) => ({
      numfac: bill.Facture.FacClef,
      datefac: bill.Facture.FacDate,
      provider: bill.Facture.FacFournisseur,
      energy: [bill.Facture.FacEnergie, bill.Facture.FacNrjId],
      site: bill.Facture.FacSite.SphNom,
      mntfac: bill.Facture.FacMontantHTVA,
      status: bill.LibelleStatutEvaluation,
      statusId: bill.StatutEvaluation,
      mnteval: bill.MontantEvalHTVA,
      mntregul: bill.MontantRegulHTVA,
      counter: bill.Facture.FacPerimetre.PeeClef,
      ecarteuro: bill.MontantRegulHTVA - bill.MontantEvalHTVA,
      pourcent: bill.MontantEvalHTVA
        ? 100 * (bill.MontantRegulHTVA / bill.MontantEvalHTVA - 1)
        : 0,
      ecart: 10,
      ecartCode: null,
      validation: '',
      id: bill.Facture.id,
      statusCode: bill.Facture.FacEtatUtilisateur,
    }))

    dispatch({
      type: types.FETCH_BILLS_VALIDATION_SUCCESS,
      data,
      formatted,
      request,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_BILLS_VALIDATION_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const defineStatusCodeUsetoChange = (update) => (dispatch) => {
  dispatch({
    type: types.CHANGE_STATUS_DEFINED,
    update,
  })
}

export const changeStatusBills = ({ id, status }) => (dispatch, getState) => {
  const state = getState()

  const payload = {
    statut: status || getDefineStatusCodeUsetoChange(state),
    listeIds: id ? [id] : getSelectBillIds(state),
  }

  dispatch({
    type: types.FETCH_BILLS_CHANGED_STATUS_REQUEST,
  })

  return bills.changeStatusBills(payload).then(
    (response) => {
      if (response.status === 200) {
        dispatch({
          type: types.FETCH_BILLS_CHANGED_STATUS_SUCCESS,
          response,
        })
      } else {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getPutBillUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: types.FETCH_BILLS_CHANGED_STATUS_FAILURE,
              message: response.Message,
            })
          )
        }
      }
    },

    (error) => {
      dispatch({
        type: types.FETCH_BILLS_CHANGED_STATUS_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const selectBillIds = (payload) => (dispatch) => {
  dispatch({
    type: types.SELECT_BILL_IDS,
    payload,
  })
}

export const setPopover = (id) => ({ type: types.SET_POPOVER, id })
