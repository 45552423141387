import { combineReducers } from 'redux'
import merge from 'lodash/merge'
import moment from 'moment'

import * as types from './types'

const billsListUuid = (state = '', action) => {
  switch (action.type) {
    case types.FETCH_BILLS_LIST_REQUEST:
      return action.uuid
    default:
      return state
  }
}

const billUuid = (state = '', action) => {
  switch (action.type) {
    case types.FETCH_BILL_REQUEST:
      return action.uuid
    default:
      return state
  }
}

const putBillUuid = (state = '', action) => {
  switch (action.type) {
    case types.PUT_BILL_REQUEST:
      return action.uuid
    default:
      return state
  }
}

const postBillFileUuid = (state = '', action) => {
  switch (action.type) {
    case types.BILL_FILE_POST_REQUEST:
      return action.uuid
    default:
      return state
  }
}

const deleteBillFileUuid = (state = '', action) => {
  switch (action.type) {
    case types.BILL_FILE_DELETE_REQUEST:
      return action.uuid
    default:
      return state
  }
}

const list = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_LIST_SUCCESS:
      return {
        ...state,
        ...action.response.entities,
        billsIds: action.response.result,
      }
    case types.PUT_BILL_SUCCESS:
      let newState = { ...state }
      newState.bill = { ...state.bill }
      newState.bill[action.billId] = {
        ...newState.bill[action.billId],
        FacNoteContenu: action.response.FacNoteContenu,
        FacNoteExiste: action.response.FacNoteExiste,
        FacDate: action.response.FacDate,
        FacDatePaiement: action.response.FacDatePaiement,
      }
      return newState
    default:
      return state
  }
}

const sortList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_BILLS_LIST_SUCCESS:
      return action.response.result || []
    case types.SORT_BILLS_LIST:
      switch (action.sort.col) {
        case 'SphNom':
          return Object.keys(action.list.bill)
            .map((i) => action.list.bill[i])
            .sort((a, b) =>
              a.FacSite.SphNom > b.FacSite.SphNom
                ? action.sort.dir
                : -action.sort.dir
            )
            .map((i) => i.id)
        case 'FacClef':
        case 'FacFournisseur':
        case 'FacMontantHTVA':
        case 'FacStatutEvaluation':
        case 'FacNoteExiste':
          return Object.keys(action.list.bill)
            .map((i) => action.list.bill[i])
            .sort((a, b) =>
              a[action.sort.col] > b[action.sort.col]
                ? action.sort.dir
                : -action.sort.dir
            )
            .map((i) => i.id)
        case 'FacNrjId':
          return Object.keys(action.list.bill)
            .map((i) => action.list.bill[i])
            .sort((a, b) =>
              a[action.sort.col] > b[action.sort.col]
                ? -action.sort.dir
                : action.sort.dir
            )
            .map((i) => i.id)
        case 'FacDate':
          return Object.keys(action.list.bill)
            .map((i) => action.list.bill[i])
            .sort(
              (a, b) =>
                moment(a.FacDate, 'DD/MM/YYYY').diff(
                  moment(b.FacDate, 'DD/MM/YYYY')
                ) * action.sort.dir
            )
            .map((i) => i.id)
        case 'FacPee':
          return Object.keys(action.list.bill)
            .map((i) => action.list.bill[i])
            .sort((a, b) =>
              a.FacPerimetre.PeeClef < b.FacPerimetre.PeeClef
                ? -action.sort.dir
                : action.sort.dir
            )
            .map((i) => i.id)
        default:
          return action.list.billsIds
      }
    default:
      return state
  }
}

const sortState = (state = { col: '', dir: '' }, action) => {
  switch (action.type) {
    case 'SORT_BILLS_LIST':
      return action.sort
    default:
      return state
  }
}

const bills = (state = {}, action) => {
  switch (action.type) {
    case types.PUT_BILL_REQUEST:
    case types.FETCH_BILL_REQUEST:
      return {
        ...state,
        [action.billId]: {
          isFetching: true,
          error: false,
        },
      }
    case types.PUT_BILL_SUCCESS:
    case types.FETCH_BILL_SUCCESS:
      return {
        ...state,
        [action.billId]: {
          ...action.response,
          isFetching: false,
          error: false,
        },
      }
    case types.PUT_BILL_FAILURE:
    case types.FETCH_BILL_FAILURE:
      return {
        ...state,
        [action.billId]: {
          ...state[action.billId],
          isFetching: false,
          error: true,
        },
      }
    default:
      return state
  }
}

const reliedBills = (
  state = { data: {}, isFetching: false, error: false },
  action
) => {
  switch (action.type) {
    case types.FETCH_RELIED_BILL_REQUEST:
      return {
        data: {},
        isFetching: true,
        error: false,
      }
    case types.FETCH_RELIED_BILL_SUCCESS:
      return {
        data: action.response,
        isFetching: false,
        error: false,
      }
    case types.FETCH_RELIED_BILL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      }
    default:
      return state
  }
}

const reliedCoversBills = (
  state = { data: {}, isFetching: false, error: false },
  action
) => {
  switch (action.type) {
    case types.FETCH_RELIED_COVERS_BILLS_REQUEST:
      return {
        data: {},
        isFetching: true,
        error: false,
      }
    case types.FETCH_RELIED_COVERS_BILLS_SUCCESS:
      return {
        data: action.response,
        isFetching: false,
        error: false,
      }
    case types.FETCH_RELIED_COVERS_BILLS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      }
    default:
      return state
  }
}

const billsBalanceSheet = (state = { data: [], formatted: [] }, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_BALANCE_SHEET_SUCCESS:
      return {
        data: action.data,
        formatted: action.formatted,
      }
    default:
      return state
  }
}

const billsBalanceSheetFetching = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_BALANCE_SHEET_REQUEST:
      return true
    case types.FETCH_BILLS_BALANCE_SHEET_SUCCESS:
      return false
    case types.FETCH_BILLS_BALANCE_SHEET_FAILURE:
      return false
    default:
      return state
  }
}

const billsBalanceSheetError = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_BALANCE_SHEET_REQUEST:
      return false
    case types.FETCH_BILLS_BALANCE_SHEET_SUCCESS:
      return false
    case types.FETCH_BILLS_BALANCE_SHEET_FAILURE:
      return true
    default:
      return state
  }
}

const billsBalanceSheetRequest = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_BALANCE_SHEET_REQUEST:
      return action.request
    default:
      return state
  }
}

const billsCover = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_BILLS_COVER_SUCCESS:
      return {
        data: action.data,
        formatted: action.formatted,
      }
    default:
      return state
  }
}

const billsCoverFetching = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_COVER_REQUEST:
      return true
    case types.FETCH_BILLS_COVER_SUCCESS:
      return false
    case types.FETCH_BILLS_COVER_FAILURE:
      return false
    default:
      return state
  }
}

const billsCoverError = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_COVER_REQUEST:
      return false
    case types.FETCH_BILLS_COVER_SUCCESS:
      return false
    case types.FETCH_BILLS_COVER_FAILURE:
      return true
    default:
      return state
  }
}

const billsCoverRequest = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_COVER_SUCCESS:
      return action.request
    default:
      return state
  }
}

const billsProviders = (state = { data: [], formatted: [] }, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_PROVIDERS_SUCCESS:
      return {
        data: action.data,
        formatted: action.formatted,
      }
    default:
      return state
  }
}

const billsProvidersFetching = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_PROVIDERS_REQUEST:
      return true
    case types.FETCH_BILLS_PROVIDERS_SUCCESS:
      return false
    case types.FETCH_BILLS_PROVIDERS_FAILURE:
      return false
    default:
      return state
  }
}

const billsProvidersError = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_PROVIDERS_REQUEST:
      return false
    case types.FETCH_BILLS_PROVIDERS_SUCCESS:
      return false
    case types.FETCH_BILLS_PROVIDERS_FAILURE:
      return true
    default:
      return state
  }
}

const billsProvidersRequest = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_PROVIDERS_SUCCESS:
      return action.request
    default:
      return state
  }
}

const billsValidation = (state = { data: [], formatted: [] }, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_VALIDATION_SUCCESS:
      return {
        data: action.data,
        formatted: action.formatted,
      }
    default:
      return state
  }
}

const billsValidationFetching = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_VALIDATION_REQUEST:
      return true
    case types.FETCH_BILLS_VALIDATION_SUCCESS:
      return false
    case types.FETCH_BILLS_VALIDATION_FAILURE:
      return false
    default:
      return state
  }
}

const billsValidationError = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_VALIDATION_REQUEST:
      return false
    case types.FETCH_BILLS_VALIDATION_SUCCESS:
      return false
    case types.FETCH_BILLS_VALIDATION_FAILURE:
      return true
    default:
      return state
  }
}

const billsValidationRequest = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_BILLS_VALIDATION_SUCCESS:
      return action.request
    default:
      return state
  }
}

const currentBillForm = (state = {}, action) => {
  switch (action.type) {
    case types.BILL_FORM_UPDATE:
      let newState = { ...state }
      return merge(newState, action.update)
    case types.BILL_FORM_UPDATESTATUS:
      return {
        ...state,
        FacStatutEvaluationUtilisateur: parseInt(
          action.FacStatutEvaluationUtilisateur,
          10
        ),
      }
    case types.PUT_BILL_SUCCESS:
    case types.FETCH_BILL_SUCCESS:
      return action.response
    case types.BILL_FILE_POST_SUCCESS:
      const docAdd = [...(state.FacDocsJoints || []), ...action.response]
      return { ...state, FacDocsJoints: docAdd }
    case types.BILL_FILE_DELETE_SUCCESS:
      const docDel = state.FacDocsJoints.filter((i) => i.id !== action.fileId)
      return { ...state, FacDocsJoints: docDel }
    default:
      return state
  }
}

const monthsInPeriod = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_ALL_MONTHS_IN_PERIOD:
      return action.response
    default:
      return state
  }
}

const billPutSuccess = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_BILL_SUCCESS:
    case types.BILL_FORM_UPDATE:
      return false
    case types.PUT_BILL_SUCCESS:
      return true
    default:
      return state
  }
}

const billPutError = (state = '', action) => {
  switch (action.type) {
    case types.PUT_BILL_FAILURE:
      return action.message || 'Something went wrong'
    case types.PUT_BILL_SUCCESS:
    case types.FETCH_BILL_SUCCESS:
    case types.BILL_FORM_UPDATE:
    case types.PUT_BILL_REQUEST:
    case types.FETCH_BILLS_LIST_REQUEST:
    case types.FETCH_BILL_REQUEST:
      return ''
    default:
      return state
  }
}

const defineStatusCodeUseToChange = (state = '', action) => {
  switch (action.type) {
    case types.CHANGE_STATUS_DEFINED:
      return action.update
    default:
      return state
  }
}

const selectBillIds = (state = '', action) => {
  switch (action.type) {
    case types.SELECT_BILL_IDS:
      return action.payload
    default:
      return state
  }
}

const reliedBillsStatus = (
  state = { response: null, isFetching: false, error: false },
  action
) => {
  switch (action.type) {
    case types.FETCH_BILLS_CHANGED_STATUS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case types.FETCH_BILLS_CHANGED_STATUS_SUCCESS:
      return {
        ...state,
        response: action.response,
        isFetching: false,
      }
    case types.FETCH_BILLS_CHANGED_STATUS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      }
    default:
      return state
  }
}

const popover = (state = null, action) => {
  switch (action.type) {
    case types.SET_POPOVER:
      return action.id
    default:
      return state
  }
}

export default combineReducers({
  billsListUuid,
  billUuid,
  putBillUuid,
  postBillFileUuid,
  deleteBillFileUuid,
  list,
  sortList,
  sortState,
  bills,
  currentBillForm,
  billPutSuccess,
  billPutError,
  monthsInPeriod,

  billsBalanceSheet,
  billsBalanceSheetRequest,
  billsBalanceSheetFetching,
  billsBalanceSheetError,

  billsCover,
  billsCoverRequest,
  billsCoverFetching,
  billsCoverError,

  billsProviders,
  billsProvidersRequest,
  billsProvidersFetching,
  billsProvidersError,

  billsValidation,
  billsValidationRequest,
  billsValidationFetching,
  billsValidationError,

  reliedBills,
  reliedCoversBills,
  reliedBillsStatus,

  defineStatusCodeUseToChange,
  selectBillIds,

  popover,
})
