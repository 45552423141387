import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardHeader,
  CardBody,
  Alert,
} from 'reactstrap'
import { compose } from 'redux'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

import {
  getKpiAll,
  getKpiExpTotal,
  getDonutsGraph,
  fetchKpiAll,
  fetchKpiExpTotal,
  fetchKpiDonutsGraph,
} from '../../store/kpi'
import Kpi from './Kpi'
import { getOrganisationIds } from '../../store/organisation'
import { initDonutChart } from '../../helpers/initCharts'
import DateRange from '../common/DateRange'

import { getBillStateManualMgmt } from '../../store/admin'

import ProgressCard from './ProgressCard'

class Dashboard extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    this.props.fetchKpiDonutsGraph()
    this.props.fetchKpiAll()
    this.props.fetchKpiExpTotal()
  }

  render() {
    const { kpiExpTotal, kpiAll, t, kpiDonuts, organisationIds } = this.props
    let graph1 = {},
      graph2 = {},
      graph3 = {},
      graph4 = {}
    if (kpiDonuts) {
      graph1 = {
        series: [kpiDonuts.EtatUtilisateurNombre],
      }
      graph2 = {
        series: [kpiDonuts.EtatUtilisateurMontant],
      }
      graph3 = {
        series: [kpiDonuts.StatutEvaluationNombre],
      }
      graph4 = {
        series: [kpiDonuts.StatutEvaluationMontant],
      }
    }

    return (
      <>
        <DateRange />
        <Container fluid>
          {organisationIds.length > 0 ? (
            <>
              <Row className="my-4">
                <Col xs="8" className="all-amenities-kpis ">
                  <CardGroup>
                    <Kpi
                      decimal={false}
                      {...kpiAll}
                      title={t('kpi.volumeTitle')}
                    />
                    <Kpi
                      decimal={false}
                      {...kpiExpTotal}
                      title={t('kpi.spendTitle')}
                    />
                  </CardGroup>
                </Col>
                <Col xs="4">
                  <ProgressCard />
                </Col>
              </Row>
              <Row className="my-3 justify-content-center">
                {this.props.billStateManualMgmt && (
                  <Col xs="12" md="6">
                    <Card>
                      <CardHeader>{t('donut.title1')}</CardHeader>
                      <CardBody>
                        <Row className="my-3 mx-3">
                          <Col xs="6">
                            <Row>
                              <Col>
                                <h6 className="text-center">
                                  {t('donut.numberBills')}
                                </h6>
                              </Col>
                            </Row>
                            <Row noGutters>
                              <Col>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={initDonutChart(graph1, 'facture(s)')}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="6">
                            <Row>
                              <Col>
                                <h6 className="text-center">
                                  {t('donut.valueBills')}
                                </h6>
                              </Col>
                            </Row>
                            <Row noGutters>
                              <Col>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={initDonutChart(graph2, '€')}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                <Col xs="12" md="6">
                  <Card>
                    <CardHeader className="section-header">
                      {t('donut.title2')}
                    </CardHeader>
                    <CardBody>
                      <Row className="my-3">
                        <Col xs="6">
                          <Row>
                            <Col>
                              <h6 className="text-center">
                                {t('donut.numberBills')}
                              </h6>
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={initDonutChart(
                                  graph3,
                                  'facture',
                                  'factures'
                                )}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="6">
                          <Row>
                            <Col>
                              <h6 className="text-center">
                                {t('donut.valueBills')}
                              </h6>
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={initDonutChart(graph4, '€', '€')}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Alert color="warning" className="mb-0">
              {t('errors.noSelection')}
            </Alert>
          )}
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  kpiAll: getKpiAll(state),
  kpiDonuts: getDonutsGraph(state),
  organisationIds: getOrganisationIds(state),
  kpiExpTotal: getKpiExpTotal(state),
  billStateManualMgmt: getBillStateManualMgmt(state),
})

const mapDispatchToProps = {
  fetchKpiAll,
  fetchKpiExpTotal,
  fetchKpiDonutsGraph,
}

Dashboard.propTypes = {
  organisationIds: PropTypes.array.isRequired,
  kpiAll: PropTypes.object.isRequired,
  fetchKpiAll: PropTypes.func.isRequired,
  kpiExpTotal: PropTypes.object.isRequired,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate()
)(Dashboard)
