import { combineReducers } from 'redux'
import { reducer as notifications } from 'react-notification-system-redux'
import dateRange from './dateRange'
import organisation from './organisation'
import bills from './bills'
import kpi from './kpi'
import filters from './filters'
import admin from './admin'
// import billStatus from './billStatus'
import pdf from './pdf'
import { reducer as oidc } from 'redux-oidc'
import { apiStatusReducer, userReducer } from 'commons'
import get from 'lodash/get'

const app = combineReducers({
  oidc,
  apiStatus: apiStatusReducer,
  user: userReducer,
  notifications,
  dateRange,
  kpi,
  organisation,
  bills,
  filters,
  admin,
  pdf,
  // billStatus,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT_SUCCESS') {
    return app(undefined, action)
  }

  if (action.type === 'RESET_ALL') {
    state = {
      oidc: state.oidc,
    }
  }

  return app(state, action)
}

export default rootReducer

export const getUor = (state) =>
  get(state, ['oidc', 'user', 'profile', 'uor'], false)
