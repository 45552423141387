import React, { Component } from 'react'

import { getReliedCoversBills } from '../../../store/bills'

import ReactTable from 'react-table'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { decimalFormat, noDecimalFormat } from '../../../helpers/formatters'
import Loading from '../../common/Loading'
import styles from './ReliedBillsCovers.module.scss'
import classnames from 'classnames'
import moment from 'moment'

const icons = {
  'Volume contractuel': 'courbe-full',
  'Contract Volume': 'courbe-full',

  Energie: 'eclair',
  Energy: 'eclair',

  Acheminement: 'truck',
  Transport: 'truck',
  'Grid Access': 'truck',

  Taxes: 'taxes',

  Volume: 'vallet',
  Volum: 'vallet',
  'Other(s)': 'vallet',
  'Autre(s)': 'vallet',
}

const CoversTable = translate()(({ t, data, isVolume }) => (
  <div className={styles.table}>
    <ReactTable
      data={data}
      LoadingComponent={Loading}
      columns={[
        {
          Header: t('control.nature'),
          accessor: 'nature',
        },
        {
          Header: t('control.period'),
          accessor: 'period',
          width: 200,
        },
        {
          Header: t(
            isVolume
              ? 'control.volMntFactureWithUnitShort'
              : 'control.mntFactureWithUnitShort'
          ),
          accessor: 'montantFacture',
          className: 'numberCell',
          width: 140,
        },
        {
          Header: t(
            isVolume
              ? 'control.volMntValoriseWithUnitShort'
              : 'control.mntValoriseWithUnitShort'
          ),
          accessor: 'montantValorise',
          className: 'numberCell',
          width: 140,
        },
        {
          Header: t('control.ecart'),
          accessor: 'ecart',
          className: 'numberCell',
          width: 140,
        },
      ]}
      pageSize={data.length}
      showPagination={false}
      className="-striped -highlight"
      minRows={5}
    />
  </div>
))

const CoversHeader = translate()(({ t, data, isVolume = false }) => {
  const {
    nature,
    invoicedAmount,
    estimatedAmount,
    invoicedPrice,
    estimatedPrice,
    gap,
  } = data
  const parsedGap = parseFloat(gap.replace(',', '.'))
  const prettyGap = isNaN(parsedGap)
    ? gap
    : `${parsedGap > 0 ? '+' : ''}${parsedGap}%`

  return (
    <div className={styles.header}>
      <div className={styles.nature}>
        <i className={'icon-' + icons[nature]} />
        <div>
          <div>{nature}</div>
          <div className={styles.gap}>{prettyGap}</div>
        </div>
      </div>
      {isVolume ? (
        <>
          <div>
            {t('control.volMntFacture')} : <strong>{invoicedAmount}</strong> KWh
          </div>
          {!isNaN(Number(estimatedAmount.replace(/\s/g, ''))) && (
            <div>
              {t('control.volMntValorise')} : <strong>{estimatedAmount}</strong>{' '}
              KWh
            </div>
          )}
        </>
      ) : (
        <>
          <div>
            {t('control.mntFacture')} : <strong>{invoicedAmount}</strong> €{' '}
            {invoicedPrice && `(${invoicedPrice} €/MWh)`}
          </div>
          {!isNaN(Number(estimatedAmount.replace(/\s/g, ''))) && (
            <div>
              {t('control.mntValorise')} : <strong>{estimatedAmount}</strong> €{' '}
              {estimatedPrice && `(${estimatedPrice} €/MWh)`}
            </div>
          )}
        </>
      )}
    </div>
  )
})

const CoversSection = ({ headerData, tableData, isVolume }) => (
  <div className={classnames(styles.section, 'recall-bill_covers')}>
    <CoversHeader data={headerData} isVolume={isVolume} />
    <CoversTable data={tableData} isVolume={isVolume} />
  </div>
)

class ReliedBillsCoversTable extends Component {
  render() {
    const { reliedBillsCovers, t } = this.props
    let energyData = {}
    let transportData = {}
    let taxesData = {}
    let volumesData = {}

    let energyDetails = []
    let transportDetails = []
    let taxesDetails = []
    let volumesDetails = []

    if (reliedBillsCovers.GroupesLigneFactureRegul) {
      const energyIat = reliedBillsCovers.GroupesLigneFactureRegul.find(
        (x) => x.ChrTypeAgrege === 11
      )
      const transportIat = reliedBillsCovers.GroupesLigneFactureRegul.find(
        (x) => x.ChrTypeAgrege === 12
      )
      const taxesIat = reliedBillsCovers.GroupesLigneFactureRegul.find(
        (x) => x.ChrTypeAgrege === 13
      )
      const volumesIat = reliedBillsCovers.GroupesLigneFactureRegul.find(
        (x) => x.ChrTypeAgrege === 10004
      )

      if (!!energyIat) {
        energyDetails = energyIat.ListeLigneFactureRegul.map(
          ({ LibelleChrType, ValeurRegul, ValeurEval, Debut, Fin }) => ({
            nature: LibelleChrType,
            period: `${moment(Debut).format('DD/MM/YYYY')} - ${moment(
              Fin
            ).format('DD/MM/YYYY')}`,
            montantFacture: noDecimalFormat(ValeurRegul),
            prixFacture: '',
            montantValorise:
              ValeurEval === null
                ? t('control.attenteEvalShort')
                : noDecimalFormat(ValeurEval),
            prixValorise: '',
            ecart:
              ValeurEval === null
                ? t('control.attenteEvalShort')
                : decimalFormat(
                    ((ValeurRegul - ValeurEval) * 100) / ValeurRegul,
                    { digitCount: 1 }
                  ),
          })
        )

        energyData = {
          nature: t('control.energy'),
          invoicedAmount: noDecimalFormat(energyIat.ValeurRegul),
          estimatedAmount:
            energyIat.ValeurEval !== 0
              ? noDecimalFormat(energyIat.ValeurEval)
              : t('control.attenteEval'),
          invoicedPrice:
            reliedBillsCovers.VolumeRegul === 0
              ? ''
              : decimalFormat(
                  (energyIat.ValeurRegul / reliedBillsCovers.VolumeRegul) * 1000
                ),
          estimatedPrice:
            reliedBillsCovers.VolumeRegul === 0
              ? ''
              : reliedBillsCovers.VolumeEval !== 0
              ? decimalFormat(
                  (energyIat.ValeurEval / reliedBillsCovers.VolumeEval) * 1000
                )
              : t('control.attenteEval'),
          gap:
            energyIat.ValeurEval !== 0
              ? reliedBillsCovers.VolumeRegul === 0
                ? ''
                : decimalFormat(
                    ((energyIat.ValeurRegul - energyIat.ValeurEval) * 100) /
                      energyIat.ValeurRegul,
                    { digitCount: 1 }
                  )
              : t('control.attenteEval'),
        }
      }

      if (!!transportIat) {
        transportDetails = transportIat.ListeLigneFactureRegul.map(
          ({ LibelleChrType, ValeurRegul, ValeurEval, Debut, Fin }) => ({
            nature: LibelleChrType,
            period: `${moment(Debut).format('DD/MM/YYYY')} - ${moment(
              Fin
            ).format('DD/MM/YYYY')}`,
            montantFacture: noDecimalFormat(ValeurRegul),
            prixFacture: '',
            montantValorise:
              ValeurEval === null
                ? t('control.attenteEvalShort')
                : noDecimalFormat(ValeurEval),
            prixValorise: '',
            ecart:
              ValeurEval === null
                ? t('control.attenteEvalShort')
                : decimalFormat(
                    ((ValeurRegul - ValeurEval) * 100) / ValeurRegul,
                    { digitCount: 1 }
                  ),
          })
        )

        transportData = {
          nature: t('control.transport'),
          invoicedAmount: noDecimalFormat(transportIat.ValeurRegul),
          invoicedPrice:
            reliedBillsCovers.VolumeRegul !== 0
              ? decimalFormat(
                  (transportIat.ValeurRegul / reliedBillsCovers.VolumeRegul) *
                    1000
                )
              : '',
          estimatedAmount:
            transportIat.ValeurEval !== 0
              ? noDecimalFormat(transportIat.ValeurEval)
              : t('control.attenteEval'),
          estimatedPrice:
            reliedBillsCovers.VolumeRegul !== 0
              ? reliedBillsCovers.VolumeEval !== 0
                ? decimalFormat(
                    (transportIat.ValeurEval / reliedBillsCovers.VolumeEval) *
                      1000
                  )
                : t('control.attenteEval')
              : '',
          gap:
            transportIat.ValeurEval !== 0
              ? reliedBillsCovers.VolumeRegul !== 0
                ? decimalFormat(
                    ((transportIat.ValeurRegul - transportIat.ValeurEval) *
                      100) /
                      transportIat.ValeurRegul,
                    { digitCount: 1 }
                  )
                : ''
              : t('control.attenteEval'),
        }
      }

      if (!!taxesIat) {
        taxesDetails = taxesIat.ListeLigneFactureRegul.map(
          ({ LibelleChrType, ValeurRegul, ValeurEval, Debut, Fin }) => ({
            nature: LibelleChrType,
            period: `${moment(Debut).format('DD/MM/YYYY')} - ${moment(
              Fin
            ).format('DD/MM/YYYY')}`,
            montantFacture: noDecimalFormat(ValeurRegul),
            prixFacture: '',
            montantValorise:
              ValeurEval === null
                ? t('control.attenteEvalShort')
                : noDecimalFormat(ValeurEval),
            prixValorise: '',
            ecart:
              ValeurEval === null
                ? t('control.attenteEvalShort')
                : decimalFormat(
                    ((ValeurRegul - ValeurEval) * 100) / ValeurRegul,
                    { digitCount: 1 }
                  ),
          })
        )

        taxesData = {
          nature: t('control.taxes'),
          invoicedAmount: noDecimalFormat(taxesIat.ValeurRegul),
          invoicedPrice:
            reliedBillsCovers.VolumeRegul === 0
              ? ''
              : decimalFormat(
                  (taxesIat.ValeurRegul / reliedBillsCovers.VolumeRegul) * 1000
                ),
          estimatedAmount:
            taxesIat.ValeurEval !== 0
              ? noDecimalFormat(taxesIat.ValeurEval)
              : t('control.attenteEval'),
          estimatedPrice:
            reliedBillsCovers.VolumeRegul === 0
              ? ''
              : reliedBillsCovers.VolumeEval !== 0
              ? decimalFormat(
                  (taxesIat.ValeurEval / reliedBillsCovers.VolumeEval) * 1000
                )
              : t('control.attenteEval'),
          gap:
            taxesIat.ValeurEval !== 0
              ? reliedBillsCovers.VolumeRegul === 0
                ? ''
                : decimalFormat(
                    ((taxesIat.ValeurRegul - taxesIat.ValeurEval) * 100) /
                      taxesIat.ValeurRegul,
                    { digitCount: 1 }
                  )
              : t('control.attenteEval'),
        }
      }

      if (!!volumesIat) {
        volumesDetails = volumesIat.ListeLigneFactureRegul.map(
          ({ LibelleChrType, ValeurRegul, ValeurEval, Debut, Fin }) => ({
            nature: LibelleChrType,
            period: `${moment(Debut).format('DD/MM/YYYY')} - ${moment(
              Fin
            ).format('DD/MM/YYYY')}`,
            montantFacture: noDecimalFormat(ValeurRegul),
            montantValorise:
              ValeurEval === null
                ? t('control.attenteEvalShort')
                : noDecimalFormat(ValeurEval),
            ecart:
              ValeurEval === null
                ? t('control.attenteEvalShort')
                : decimalFormat(
                    ((ValeurRegul - ValeurEval) * 100) / ValeurRegul,
                    { digitCount: 1 }
                  ),
          })
        )

        volumesData = {
          nature: t('control.volum'),
          invoicedAmount: noDecimalFormat(volumesIat.ValeurRegul),
          estimatedAmount:
            volumesIat.ValeurEval !== 0
              ? noDecimalFormat(volumesIat.ValeurEval)
              : t('control.attenteEval'),
          gap:
            volumesIat.ValeurEval !== 0
              ? decimalFormat(
                  ((volumesIat.ValeurRegul - volumesIat.ValeurEval) * 100) /
                    volumesIat.ValeurRegul,
                  { digitCount: 1 }
                )
              : t('control.attenteEval'),
        }
      }
    }

    const total = `${decimalFormat(reliedBillsCovers.MontantRegulHTVA)} €`
    const totalValo =
      reliedBillsCovers.MontantEvalHTVA === 0
        ? t('control.attenteEval')
        : `${decimalFormat(reliedBillsCovers.MontantEvalHTVA)} €`

    const te =
      reliedBillsCovers.MontantEvalHTVA !== 0 &&
      ((reliedBillsCovers.MontantRegulHTVA -
        reliedBillsCovers.MontantEvalHTVA) *
        100) /
        reliedBillsCovers.MontantEvalHTVA

    const totalEcart =
      reliedBillsCovers.MontantEvalHTVA !== 0 &&
      `${te > 0 ? '+' : ''}${decimalFormat(te, { digitCount: 2 })}%`

    return (
      <div>
        <div className={styles.total}>
          <div className={styles.totalTitle}>
            {t('control.total')}
            {'  '}
            {totalEcart && <span className={styles.gap}>{totalEcart}</span>}
          </div>
          <div>
            {t('control.mntFacture')} : <strong>{total}</strong>
          </div>
          <div>
            {t('control.mntValorise')} : <strong>{totalValo}</strong>
          </div>
        </div>
        {reliedBillsCovers.GroupesLigneFactureRegul && (
          <div>
            {energyDetails.length > 0 && (
              <CoversSection
                headerData={energyData}
                tableData={energyDetails}
              />
            )}
            {transportDetails.length > 0 && (
              <CoversSection
                headerData={transportData}
                tableData={transportDetails}
              />
            )}
            {taxesDetails.length > 0 && (
              <CoversSection headerData={taxesData} tableData={taxesDetails} />
            )}
            {volumesDetails.length > 0 && (
              <CoversSection
                headerData={volumesData}
                tableData={volumesDetails}
                isVolume={true}
              />
            )}
          </div>
        )}
        <br />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  reliedBillsCovers: getReliedCoversBills(state),
})

export default translate()(connect(mapStateToProps)(ReliedBillsCoversTable))
