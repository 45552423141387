import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import { ErrorBoundary } from 'commons'
import UserPanel from './UserPanel'
import TopNav from 'components/topnav/TopNav'
import Views from 'components/route/Views'
import Notif from './Notif'

const Template = () => {
  const [userPanelOpen, setUserPanelOpen] = React.useState(false)

  return (
    <Container fluid>
      <Row>
        <Notif />
        {
          <UserPanel
            userPanelOpen={userPanelOpen}
            setUserPanelOpen={setUserPanelOpen}
          />
        }
        <Col className="main-col">
          <Row style={{ position: 'sticky', top: 0, zIndex: 9000 }}>
            {
              <TopNav
                userPanelOpen={userPanelOpen}
                setUserPanelOpen={setUserPanelOpen}
              />
            }
          </Row>
          <ErrorBoundary>
            <Views />
          </ErrorBoundary>
        </Col>
      </Row>
    </Container>
  )
}

export default Template
