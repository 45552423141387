import React, { Component } from 'react'
import ReactTable from 'react-table'
import sum from 'lodash/sum'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { Col, Row } from 'reactstrap'
import { compose } from 'recompose'
import classnames from 'classnames'

import { noDecimalFormat } from '../../helpers/formatters'
import Filters from '../common/Filters/Filters'
import {
  getBillsProvidersFormatted,
  fetchBillsProviders,
  getBillsProvidersError,
  getBillsProvidersFetching,
} from '../../store/bills'
import { getStartDate, getEndDate } from '../../store/dateRange'
import { adaptPageSizeOptions } from '../../helpers/reactTable'
import Loading from '../common/Loading'
import Error from '../common/Error'

class ControlProviderTable extends Component {
  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.fetchData()
    }
  }

  fetchData = () => {
    this.props.fetchBillsProviders()
  }

  createCsvData = (columns) => {
    const csvHeader = columns.map((col) => col.Header).join(';')
    const csvValues = this.props.formatted
      .map((data) =>
        [
          data.provider,
          data.source,
          data.site,
          data.pdl,
          data.billed,
          data.forecast,
          data.solde,
        ]
          .map((s) => `"=""${s}"""`)
          .join(';')
      )
      .join('\n')

    return `${csvHeader}\n${csvValues}`
  }

  render() {
    const { formatted, t, isLoading, error } = this.props

    if (error) return <Error />

    const columns = [
      {
        Header: this.props.t('billstable.fournisseur'),
        accessor: 'provider',
        className: 'strong',
        sortable: false,
        aggregate: (vals) => '',
      },
      {
        Header: this.props.t('billstable.consumtionperod'),
        accessor: 'source',
        className: 'strong',
        sortable: false,
        aggregate: (vals) => '',
      },
      {
        Header: this.props.t('billstable.site'),
        accessor: 'site',
        aggregate: (vals) => '',
      },
      {
        Header: this.props.t('billstable.pdl'),
        accessor: 'pdl',
        aggregate: (vals) => '',
      },
      {
        Header: this.props.t('billstable.billed'),
        accessor: 'billed',
        aggregate: (vals) => sum(vals),
        className: 'numberCell',
        Cell: (row) => {
          return noDecimalFormat(row.value)
        },
      },
      {
        Header: this.props.t('billstable.forecast'),
        accessor: 'forecast',
        aggregate: (vals) => sum(vals),
        className: 'numberCell',
        Cell: (row) => {
          return noDecimalFormat(row.value)
        },
      },
      {
        Header: this.props.t('billstable.solde'),
        accessor: 'solde',
        aggregate: (vals) => sum(vals),
        filterable: false,
        resizable: false,
        className: 'numberCell',
        Cell: (row) => {
          return noDecimalFormat(row.value)
        },
      },
    ]

    const formattedCSV = this.createCsvData(columns)

    return (
      <div>
        <Row>
          <Col xs="10">
            <Filters showProviders={true} onChange={this.fetchData} />
          </Col>

          <Col xs="2" className="text-right">
            <CSVLink
              className={classnames('btn', 'csv-link', {
                disabled: formatted && formatted.length === 0,
              })}
              data={formattedCSV}
              filename={`E-Invoice - ${t('control.provider')}.csv`}
            >
              {this.props.t('button.exportcsv')}
            </CSVLink>
          </Col>
        </Row>
        <ReactTable
          data={formatted}
          columns={columns}
          pivotBy={['provider', 'source']}
          // filterable
          defaultPageSize={10}
          pageSizeOptions={adaptPageSizeOptions(formatted)}
          rowsText={t('billstable.table.rowsText')}
          ofText={t('billstable.table.ofText')}
          previousText={t('billstable.table.previousText')}
          nextText={t('billstable.table.nextText')}
          loadingText={t('billstable.table.loadingText')}
          loading={isLoading}
          LoadingComponent={Loading}
          noDataText={t('billstable.table.noDataText')}
          getTdProps={(state, rowInfo, column, instance) => {
            if (rowInfo) {
              return {
                style: {
                  backgroundColor:
                    rowInfo.viewIndex % 2 === 0 ? 'white' : '#f3f7f9',
                },
              }
            } else {
              return {}
            }
          }}
        />
        <br />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  formatted: getBillsProvidersFormatted(state),
  isLoading: getBillsProvidersFetching(state),
  error: getBillsProvidersError(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
})

const mapDispatchToProps = {
  fetchBillsProviders,
}

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(ControlProviderTable)
