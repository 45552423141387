import React, { Component } from 'react'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row, FormGroup, Button } from 'reactstrap'
import { reactLocalStorage } from 'reactjs-localstorage'
import matchSorter from 'match-sorter'
import { compose } from 'recompose'
import classnames from 'classnames'
import get from 'lodash/fp/get'

import { adaptPageSizeOptions } from '../../helpers/reactTable'
import downloadFile from '../../helpers/downloadFile'
import { noDecimalFormat } from 'helpers/formatters'

// Store
import {
  getBillsValidationFormatted,
  fetchBillsValidation,
  getBillsValidationError,
  getBillsValidationFetching,
  selectBillIds,
} from '../../store/bills'
import { getStartDate, getEndDate } from '../../store/dateRange'
import { getBillStateManualMgmt } from '../../store/admin'

// Components
import Filters from '../common/Filters'
import SelectStatustoChange from '../common/SelectStatus'
import Loading from '../common/Loading'
import Error from '../common/Error'
import FilterSelect from '../common/FilterSelect'

import { sortDates } from 'helpers/sort'

const CheckboxTable = checkboxHOC(ReactTable)

class ControlValidationBillsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: [],
      billsIdSelected: [],
      selection: [],
      selectAll: false,
      billsFromServer: [],
      numfac: null,
      datefac: null,
      provider: null,
      energy: null,
      site: null,
      counter: null,
      mntfac: null,
      status: null,
    }

    this.filteredData = []
  }

  createColumns() {
    this.setState({
      columns: [
        {
          Header: this.props.t('billstable.numfac'),
          accessor: 'numfac',
          className: 'strong',
          id: 'numfac',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['numfac'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.datefac'),
          accessor: 'datefac',
          id: 'datefac',
          sortMethod: sortDates,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['datefac'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.fournisseur'),
          accessor: 'provider',
          id: 'provider',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['provider'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.energie'),
          accessor: 'energy',
          Cell: (props) => (
            <span>
              {' '}
              <i
                className={props.value[1] === '2' ? 'icon-elec' : 'icon-gaz'}
                style={{ color: props.value[1] === 2 ? '#31acea' : '#c53ed1' }}
              />{' '}
              {props.value[0]}
            </span>
          ),
          id: 'energy',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['energy'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.filteredData.map(get('energy.0'))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.site'),
          accessor: 'site',
          id: 'site',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['site'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.counter'),
          accessor: 'counter',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: ['counter'],
              threshold: matchSorter.rankings.CONTAINS,
            }),
          filterAll: true,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('control.validationFacture'),
          accessor: 'mntfac',
          className: 'numberCell',
          Cell: (cell) => <span>{noDecimalFormat(cell.value)}</span>,
          filterable: false,
          Filter: (props) => (
            <FilterSelect
              {...props}
              value={this.state[props.column.id]}
              setValue={(value) => this.setState({ [props.column.id]: value })}
              values={this.filteredData.map(get(props.column.id))}
            />
          ),
        },
        {
          Header: this.props.t('billstable.ecart'),
          accessor: 'status',
          id: 'status',
          minWidth: 80,

          filterMethod: ({ value }, rows) => {
            return value === 'all'
              ? rows
              : rows.filter((row) => row.status === value)
          },
          sortMethod: (a, b) => {
            // force null and undefined to the bottom
            a = a === null || a === undefined ? -Infinity : a.status
            b = b === null || b === undefined ? -Infinity : b.status

            if (a > b) return 1
            if (a < b) return -1
            return 0
          },
          filterAll: true,
          Filter: (props) => {
            const filterStatusDefault = reactLocalStorage.get(
              'billsStatutDefaultFilter'
            )
            const initialValue = filterStatusDefault
              ? Number(filterStatusDefault)
              : ''
            return (
              <FilterSelect
                {...props}
                value={this.state[props.column.id]}
                setValue={(value) =>
                  this.setState({ [props.column.id]: value })
                }
                onSelect={() => {
                  reactLocalStorage.set('billsStatutDefaultFilter', '')
                }}
                values={this.filteredData.map((b) => ({
                  label: b.status,
                  id: b.statusId,
                }))}
                initialValue={initialValue}
              />
            )
          },
        },
        {
          Header: (
            <div title={this.props.t('control.tooltip.validationRegul')}>
              {this.props.t('control.validationRegul')}
            </div>
          ),
          accessor: 'mntregul',
          Cell: (cell) => <span>{noDecimalFormat(cell.value)}</span>,
          className: 'numberCell',
          filterable: false,
        },
        {
          Header: (
            <div title={this.props.t('control.tooltip.validationEstimation')}>
              {this.props.t('control.validationEstimation')}
            </div>
          ),
          accessor: 'mnteval',
          Cell: (cell) => <span>{noDecimalFormat(cell.value)}</span>,
          className: 'numberCell',
          filterable: false,
        },
        {
          Header: (
            <div title={this.props.t('control.tooltip.ecartEuro')}>
              {this.props.t('control.ecartEuro')}
            </div>
          ),
          accessor: 'ecarteuro',
          Cell: (cell) => <span>{noDecimalFormat(cell.value)}</span>,
          className: 'numberCell',
          filterable: false,
          minWidth: 50,
        },
        {
          Header: (
            <div title={this.props.t('control.tooltip.ecartPoucentage')}>
              {this.props.t('control.ecartPoucentage')}
            </div>
          ),
          accessor: 'pourcent',
          Cell: (cell) => <span>{noDecimalFormat(cell.value)}</span>,
          className: 'numberCell',
          filterable: false,
          minWidth: 50,
        },

        ...(this.props.billStateManualMgmt
          ? [
              {
                Header: this.props.t('billstable.status'),
                accessor: 'statusCode',
                id: 'statusCode',
                className: 'numberCell',
                Cell: ({ value }) => {
                  if (typeof value === 'undefined') return ''
                  return this.props.t('billstable.statusType.' + value)
                },

                filterMethod: (filter, row) => {
                  if (filter.value === 'true') {
                    return true
                  }

                  const filterId = row[filter.id]
                  return (
                    typeof filterId !== 'undefined' &&
                    filterId.toString() === filter.value
                  )
                },
                minWidth: 80,
                Filter: ({ filter, onChange }) => (
                  <select
                    className={'form-control'}
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : true}
                  >
                    <option value="true">
                      {this.props.t('billstable.filter')}
                    </option>
                    <option value="0">
                      {this.props.t('billstable.statusType.0')}
                    </option>
                    <option value="1">
                      {this.props.t('billstable.statusType.1')}
                    </option>
                    <option value="2">
                      {this.props.t('billstable.statusType.2')}
                    </option>
                  </select>
                ),
              },
            ]
          : []),
        {
          Header: <i className="icon-eye" />,
          accessor: 'visualisation',
          minWidth: 30,
          resizable: false,
          Cell: (props) => <i className="icon-eye" />,
          style: {
            textAlign: 'center',
            cursor: 'pointer',
          },
          filterable: false,
        },
      ],
    })
  }

  createCsvData = () => {
    const { columns } = this.state

    const csvHeader = columns
      .map(({ Header }) =>
        typeof Header === 'string' ? Header : Header.props.children || ''
      )
      .slice(0, -1)
      .join(';')

    const csvValues = this.filteredData
      .map((bill) =>
        [
          bill.numfac,
          bill.datefac,
          bill.provider,
          bill.energy[0],
          bill.site,
          bill.counter,
          noDecimalFormat(bill.mntfac),
          bill.status,
          noDecimalFormat(bill.mntregul),
          noDecimalFormat(bill.mnteval),
          noDecimalFormat(bill.ecarteuro),
          noDecimalFormat(bill.pourcent),
          !bill.ecartCode
            ? ''
            : this.props.t(`billstable.ecartType.${bill.ecartCode.status}`),
          ...(this.props.billStateManualMgmt ? [bill.status] : []),
        ]
          .map((s) => `"=""${s}"""`)
          .join(';')
      )
      .join('\n')

    return `${csvHeader}\n${csvValues}`
  }

  downloadCSV = () => {
    const { bills, t } = this.props
    if (!bills || bills.length === 0) return

    const content = this.createCsvData()
    const filename = `E-Invoice - ${t('control.validation')}.csv`

    downloadFile(filename, content, { type: 'text/csv' })
  }

  toggleSelection = (key, shift, row) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    // start off with the existing state
    let selection = [...this.state.selection]

    let billsIdSelected = [...this.state.billsIdSelected]

    const keyIndex = selection.indexOf(key)
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      // it does not exist so add it
      selection.push(key)
      billsIdSelected.push(row.numfac)
    }

    // update the state
    this.setState({ selection })

    this.props.selectBillIds(selection)
  }

  componentDidMount() {
    this.createColumns()
    this.fetchData()
  }

  componentWillUnmount() {
    reactLocalStorage.set('billsStatutDefaultFilter', 'true')
    reactLocalStorage.set('billsEtatDefaultFilter', 'true')
  }

  componentDidUpdate(prevProps) {
    const { bills, lng } = this.props
    if (
      prevProps.bills.map((bill) => bill.id).join('') !==
        bills.map((bill) => bill.id).join('') ||
      prevProps.lng !== lng ||
      this.props.filteredData.length !== prevProps.filteredData.length
    ) {
      this.createColumns()
    }

    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.fetchData()
    }
  }

  fetchData = () => {
    this.props.fetchBillsValidation()
  }

  toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?
      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).
      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).
      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAll = this.state.selectAll ? false : true
    const selection = []
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance()
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(item._original.id)
      })
    }
    this.setState({ selectAll, selection })
    this.props.selectBillIds(selection)
  }

  isSelected = (key) => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selection.includes(key)
  }

  logSelection = () => {
    console.log('selection:', this.state.selection)
  }

  handleFilter = (state) => {
    if (this.props.bills.length === 0) return false

    const filteredIds = state.sortedData.reduce((acc, item) => {
      acc[item._original.id] = true
      return acc
    }, {})

    const filteredData = this.props.bills.filter(
      (element) => !!filteredIds[element.id]
    )

    if (this.filteredData.length !== filteredData.length) {
      setTimeout(() => {
        this.filteredData = filteredData
        this.createColumns()
      })
    }
    return false
  }

  render() {
    const { toggleSelection, toggleAll, isSelected, error } = this

    if (error) return <Error />

    let { columns, selectAll } = this.state
    const { bills, t, isLoading } = this.props

    console.log(bills)

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r, c) => {
        if (r !== undefined) {
          return {
            onClick: (e, handleOriginal) => {
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        } else {
          return {
            style: {},
          }
        }
      },
      getTdProps: (state, rowInfo, column, instance) => {
        if (rowInfo) {
          return {
            onClick: (e, handleOriginal) => {
              if (column.id === 'visualisation') {
                this.props.openBill(rowInfo.original.id)
                if (handleOriginal) {
                  handleOriginal()
                }
              }
            },
          }
        } else {
          return {}
        }
      },
    }

    return (
      <div>
        <Row>
          <Col xs="10">
            <Filters onChange={this.fetchData} />
          </Col>

          <Col xs="2" className="text-right">
            <Button
              onClick={this.downloadCSV}
              outline
              className={classnames('btn', 'csv-link', {
                disabled: bills && bills.length === 0,
              })}
            >
              <span>{this.props.t('button.exportcsv')}</span>
            </Button>
          </Col>
        </Row>
        {this.props.billStateManualMgmt && (
          <Row>
            <Col className={'text-right'}>
              <FormGroup>
                <SelectStatustoChange />
              </FormGroup>
            </Col>
          </Row>
        )}

        <CheckboxTable
          keyField="id"
          data={bills}
          ref={(r) => (this.checkboxTable = r)}
          columns={columns}
          defaultPageSize={10}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          filterable
          pageSizeOptions={adaptPageSizeOptions(bills)}
          rowsText={t('billstable.table.rowsText')}
          ofText={t('billstable.table.ofText')}
          previousText={t('billstable.table.previousText')}
          nextText={t('billstable.table.nextText')}
          loadingText={t('billstable.table.loadingText')}
          loading={isLoading}
          LoadingComponent={Loading}
          noDataText={t('billstable.table.noDataText')}
          className="-striped -highlight"
          getProps={this.handleFilter}
          {...checkboxProps}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  bills: getBillsValidationFormatted(state),
  isLoading: getBillsValidationFetching(state),
  error: getBillsValidationError(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  billStateManualMgmt: getBillStateManualMgmt(state),
})

const mapDispatchToProps = {
  fetchBillsValidation,
  selectBillIds,
}

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(ControlValidationBillsTable)
