import moment from 'moment'

import { fetchBillsBalanceSheet } from './bills'
import { fetchKpiAll, fetchKpiExpTotal, fetchKpiDonutsGraph } from './kpi'

// Actions
const LAST_MONTH = 'einvoice/daterange/LAST_MONTH'
const LAST_3_MONTHS = 'einvoice/daterange/LAST_3_MONTHS'
const LAST_6_MONTHS = 'einvoice/daterange/LAST_6_MONTHS'
const LAST_12_MONTHS = 'einvoice/daterange/LAST_12_MONTHS'
const LAST_YEAR = 'einvoice/daterange/LAST_YEAR'
const CUSTOM = 'einvoice/daterange/CUSTOM'
const UPDATE_START_DATE = 'einvoice/daterange/UPDATE_START_DATE'
const UPDATE_END_DATE = 'einvoice/daterange/UPDATE_END_DATE'

// Reducer
const initialState = {
  period: LAST_12_MONTHS,
  startDate: moment()
    .subtract(12, 'months')
    .startOf('month')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .subtract(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD'),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LAST_MONTH:
      return {
        period: LAST_MONTH,
        startDate: moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }
    case LAST_3_MONTHS:
      return {
        period: LAST_3_MONTHS,
        startDate: moment()
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }
    case LAST_6_MONTHS:
      return {
        period: LAST_6_MONTHS,
        startDate: moment()
          .subtract(6, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }
    case LAST_12_MONTHS:
      return initialState
    case 'THIS_YEAR':
      return {
        period: 'THIS_YEAR',
        startDate: moment()
          .startOf('year')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }
    case LAST_YEAR:
      return {
        period: LAST_YEAR,
        startDate: moment()
          .subtract(1, 'year')
          .startOf('year')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYY-MM-DD'),
      }
    case CUSTOM:
      return { ...state, period: CUSTOM }
    case UPDATE_START_DATE:
      return action.date.isSameOrAfter(state.endDate)
        ? state
        : {
            ...state,
            period: CUSTOM,
            startDate: action.date.format('YYYY-MM-DD'),
          }
    case UPDATE_END_DATE:
      return action.date.isSameOrBefore(state.startDate)
        ? state
        : {
            ...state,
            period: CUSTOM,
            endDate: action.date.format('YYYY-MM-DD'),
          }
    default:
      return state
  }
}

// Selectors
export const getDateRange = (state) => state.dateRange
export const getStartDate = (state) =>
  (state.dateRange && state.dateRange.startDate) || '2017-1-1'
export const getEndDate = (state) =>
  (state.dateRange && state.dateRange.endDate) || '2017-1-1'

// Action Creators
export const updateView = () => (dispatch) => {
  const p = window.location.pathname
  switch (p) {
    case '/kpi':
      dispatch(fetchKpiAll())
      dispatch(fetchKpiExpTotal())
      dispatch(fetchKpiDonutsGraph())
      break
    case '/billstable':
      dispatch(fetchBillsBalanceSheet())
      break
    case '/control':
      //dispatch(fetchBillsCover());
      break
    default:
      break
  }
}

export const updatePeriod = (action) => (dispatch) => {
  dispatch({
    type: action,
  })
  dispatch(updateView())
}

export const updateStartDate = (date) => (dispatch) => {
  dispatch({
    type: UPDATE_START_DATE,
    date,
  })
  dispatch(updateView())
}

export const updateEndDate = (date) => (dispatch) => {
  dispatch({
    type: UPDATE_END_DATE,
    date,
  })
  dispatch(updateView())
}
