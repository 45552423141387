import PropTypes from 'prop-types'
import React from 'react'
import { Card, CardHeader, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { decimalFormat, noDecimalFormat } from '../../helpers/formatters'
import { getOrganisationIds } from '../../store/organisation'
import KpiSecondary from './KpiSecondary'

function Kpi({ Principal, Secondaires, decimal, title }) {
  return (
    <Card
      className={['kpi', 'h-100', Principal.Libelle === '' ? 'empty' : ''].join(
        ' '
      )}
      style={{ minHeight: 213 }}
    >
      <CardHeader>{title}</CardHeader>
      <CardBody className="card-block" style={{ backgroundColor: '#fff' }}>
        <Row>
          <Col md="6">
            <CardSubtitle>{Principal.Libelle}</CardSubtitle>
            <CardTitle>
              {Principal.Libelle &&
                (decimal
                  ? decimalFormat(Principal.Valeur)
                  : noDecimalFormat(Principal.Valeur, 10))}{' '}
              <small>{Principal.Unite}</small>
            </CardTitle>
          </Col>
          <Col md="6">
            <div className="title-wrapper">
              {Principal.ValeurFacture ? (
                <div className="principal-additional">
                  <div className="principal-additional-line">
                    {Principal.LibelleFacture}:
                    <span className="principal-additional-fac">
                      <span className="principal-additional-fac-value">
                        {decimal
                          ? decimalFormat(Principal.ValeurFacture)
                          : noDecimalFormat(Principal.ValeurFacture, 10)}
                      </span>
                      {Principal.Unite}
                    </span>
                  </div>
                  <div className="principal-additional-line">
                    {Principal.LibelleAFacturer}:
                    <span className="principal-additional-fac">
                      <span className="principal-additional-fac-value">
                        {decimal
                          ? decimalFormat(Principal.ValeurAFacturer)
                          : noDecimalFormat(Principal.ValeurAFacturer, 10)}
                      </span>
                      {Principal.Unite}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>

        <div className="secondary">
          {Secondaires &&
            Secondaires.map((secondaire, index) => (
              <KpiSecondary key={index} {...secondaire} decimal={decimal} />
            ))}
        </div>
      </CardBody>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  organisationIds: getOrganisationIds(state),
})

Kpi.propTypes = {
  Principal: PropTypes.shape({
    Libelle: PropTypes.string.isRequired,
    //Valeur: PropTypes.number.isRequired,
    Unite: PropTypes.string.isRequired,
    LibelleFacture: PropTypes.string,
    ValeurFacture: PropTypes.number,
    LibelleAFacturer: PropTypes.string,
    ValeurAFacturer: PropTypes.number,
  }).isRequired,
  Secondaires: PropTypes.arrayOf(
    PropTypes.shape({
      Libelle: PropTypes.string.isRequired,
      PourcentageEcart: PropTypes.number.isRequired,
      Valeur: PropTypes.number.isRequired,
      Unite: PropTypes.string.isRequired,
    }).isRequired
  ),
}

export default translate()(connect(mapStateToProps)(Kpi))
