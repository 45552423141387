import React, { Component } from 'react'
import ReactTable from 'react-table'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import moment from 'moment'
import matchSorter from 'match-sorter'
import { withRouter } from 'react-router-dom'
import get from 'lodash/fp/get'

import { adaptPageSizeOptions } from 'helpers/reactTable'
import MonthCell from './MonthCell'
import ControlBillsHeader from './ControlBillsHeader'

import Error from 'components/common/Error'
import Loading from 'components/common/Loading'
import VisibleBill from 'components/bill/VisibleBill'
import FilterSelect from 'components/common/FilterSelect'

import {
  getBillsCover,
  getBillsCoverFormatted,
  fetchBillsCover,
  getBillsCoverError,
  getBillsCoverFetching,
} from 'store/bills'

import { getStartDate, getEndDate } from 'store/dateRange'

const billUrlRx = /^\/billscover\/\d+\/?$/

class ControlBillsTable extends Component {
  constructor() {
    super()
    this.state = {
      billId: null,
      visibleBillIds: [],
      filteredData: [],
      site: null,
      energy: null,
      cpt: null,
    }
  }

  setVisibleBillIds = (visibleBillIds) => this.setState({ visibleBillIds })
  setFilteredData = (filteredData) => this.setState({ filteredData })

  openBill = (billId) => {
    this.setState({ billId })
    this.props.history.push(`/billscover/${billId}`)
  }

  closeBill = () => {
    this.setState({ billId: null })
    this.props.history.push(`/control/1`)
  }

  componentDidMount() {
    this.fetchData()
    const { url, params } = this.props.match
    if (billUrlRx.test(url)) {
      this.setState({ billId: params.id })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dateDebut !== this.props.dateDebut ||
      prevProps.dateFin !== this.props.dateFin
    ) {
      this.fetchData()
    }
  }

  fetchData = () => {
    this.props.fetchBillsCover()
  }

  createCsvData = (columns, columnsMonths) => {
    const csvHeader = columns.map((col) => col.Header).join(';')

    const csvValues = this.state.filteredData
      .map((element) => {
        const { site, energy, cpt, billId, fournisseur, ...months } = element

        const billsByMonth = columnsMonths.map(({ id }) =>
          !months[id] ? '' : months[id][3].map((fac) => fac.FacClef).join('|')
        )

        return [site, energy[0], cpt, ...billsByMonth]
          .map((s) => `"=""${s}"""`)
          .join(';')
      })
      .join('\n')

    return `${csvHeader}\n${csvValues}`
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.state.filteredData.length !== nextState.filteredData.length ||
      this.state.billId !== nextState.billId ||
      this.props.lng !== nextProps.lng ||
      this.props.dateDebut !== nextProps.dateDebut ||
      this.props.dateFin !== nextProps.dateFin
    )
  }

  handleFilter = (state) => {
    if (this.props.bills.length === 0) return false
    const filteredIds = state.sortedData.reduce((acc, item) => {
      acc[item._original.billId] = true
      return acc
    }, {})
    const filteredData = this.props.formatted.filter(
      (element) => !!filteredIds[element.billId]
    )
    setTimeout(() => {
      this.setFilteredData(filteredData)
    })
    return false
  }

  render() {
    const {
      formatted = [],
      dateDebut,
      dateFin,
      t,
      isLoading,
      error,
    } = this.props

    if (error) return <Error />

    let startDateString = dateDebut
    let endDateString = dateFin
    let startDate = moment(startDateString, 'YYYY-MM-DD')
    let endDate = moment(endDateString, 'YYYY-MM-DD').endOf('month')

    let allMonthsInPeriod = []
    let item = {}

    while (startDate.isBefore(endDate)) {
      item = {
        month: new Date(startDate).getMonth() + 1,
        year: new Date(startDate).getFullYear(),
        date: startDate.format('YYYY-MM'),
      }
      allMonthsInPeriod.push(item)
      startDate = startDate.add(1, 'month')
    }

    let allMonths = allMonthsInPeriod.map((item) => {
      return {
        month: this.props.t('months.' + item.month),
        accessor: item.month.toString() + item.year.toString(),
      }
    })

    let columnsMonths = allMonths.map((month) => {
      return {
        Header: month.month,
        accessor: month.accessor,
        id: month.accessor,
        className: 'td-bills',
        filterable: false,
        resizable: false,
        Cell: (props) => {
          return (
            <MonthCell
              {...{
                ...props,
                month,
                openBill: this.openBill.bind(this),
                setVisibleBillIds: this.setVisibleBillIds.bind(this),
              }}
            />
          )
        },
      }
    })

    let columns = [
      {
        Header: this.props.t('billstable.site'),
        accessor: 'site',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ['site'],
            threshold: matchSorter.rankings.CONTAINS,
          }),
        filterAll: true,
        className: 'strong',
        Filter: (props) => (
          <FilterSelect
            {...props}
            value={this.state[props.column.id]}
            setValue={(value) => this.setState({ [props.column.id]: value })}
            values={this.state.filteredData.map(get(props.column.id))}
          />
        ),
      },
      {
        Header: this.props.t('billstable.energie'),
        accessor: 'energy',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ['energy'],
            threshold: matchSorter.rankings.CONTAINS,
          }),
        filterAll: true,
        Cell: (props) => (
          <span>
            {' '}
            <i
              className={props.value[1] === 2 ? 'icon-elec' : 'icon-gaz'}
              style={{ color: props.value[1] === 2 ? '#31acea' : '#c53ed1' }}
            />{' '}
            {props.value[0]}
          </span>
        ),
        id: 'energy',
        Filter: (props) => (
          <FilterSelect
            {...props}
            value={this.state[props.column.id]}
            setValue={(value) => this.setState({ [props.column.id]: value })}
            values={this.state.filteredData.map(get('energy.0'))}
          />
        ),
      },
      {
        Header: this.props.t('billstable.counter'),
        accessor: 'cpt',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ['cpt'],
            threshold: matchSorter.rankings.CONTAINS,
          }),
        filterAll: true,
        Filter: (props) => (
          <FilterSelect
            {...props}
            value={this.state[props.column.id]}
            setValue={(value) => this.setState({ [props.column.id]: value })}
            values={this.state.filteredData.map(get(props.column.id))}
          />
        ),
      },
    ]

    columns = columns.concat(columnsMonths)

    const formattedCSV = this.createCsvData(columns, columnsMonths)

    return (
      <div className="control-bills-cover">
        <ControlBillsHeader data={formattedCSV} fetchData={this.fetchData} />

        <ReactTable
          data={formatted}
          columns={columns}
          filterable
          defaultPageSize={10}
          pageSizeOptions={adaptPageSizeOptions(formatted)}
          rowsText={t('billstable.table.rowsText')}
          ofText={t('billstable.table.ofText')}
          previousText={t('billstable.table.previousText')}
          nextText={t('billstable.table.nextText')}
          loadingText={t('billstable.table.loadingText')}
          LoadingComponent={Loading}
          loading={isLoading}
          noDataText={t('billstable.table.noDataText')}
          className="-striped -highlight"
          getProps={this.handleFilter}
        />

        <VisibleBill
          billId={this.state.billId}
          closeBill={this.closeBill}
          openBill={this.openBill}
          filteredBillIds={this.state.visibleBillIds}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bills: getBillsCover(state),
  formatted: getBillsCoverFormatted(state),
  dateDebut: getStartDate(state),
  dateFin: getEndDate(state),
  isLoading: getBillsCoverFetching(state),
  error: getBillsCoverError(state),
})

const mapDispatchToProps = {
  fetchBillsCover,
}

export default compose(
  withRouter,
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(ControlBillsTable)
