import { combineReducers } from 'redux'
import v4 from 'uuid/v4'
import { reactLocalStorage } from 'reactjs-localstorage'
import { createStructuredSelector } from 'reselect'

import * as commons from 'commons'
import { getStartDate, getEndDate } from './dateRange'
import { getOrganisationIds } from './organisation'

const { superFetch } = commons.apiConf

const api = {
  fetchKpi: (
    dateDebut,
    dateFin,
    perimetre,
    mesures,
    type,
    unite,
    devise,
    commodite,
    decomposition,
    axe_decomposition,
    uorIdMst,
    lanId
  ) => {
    if (!perimetre) {
      return Promise.resolve()
    }
    let url = 'kpi?'

    url += 'date_debut=' + dateDebut
    url += '&date_fin=' + dateFin
    url += '&mesure=' + mesures
    url += '&type_donnee=' + type
    url += unite ? '&unite=' + unite : ''
    url += devise ? '&devise=' + devise : ''
    url += commodite ? '&commodite=' + commodite : ''
    url += decomposition ? '&decomposition=' + decomposition : ''
    url += axe_decomposition ? '&axe_decomposition=' + axe_decomposition : ''
    url += '&uorIdMst=' + uorIdMst
    url += '&lanId=' + lanId

    return superFetch({
      url: url,
      method: 'POST',
      body: perimetre,
    })
  },
  fetchKpiDonutsGraph: (factureKpiParam) =>
    superFetch({
      url: 'facture/kpi/graphique',
      method: 'POST',
      body: factureKpiParam,
    }),
  fetchKpiQuality: (factureKpiParam) =>
    superFetch({
      url: 'facture/kpi/qualite',
      method: 'POST',
      body: factureKpiParam,
    }),
}

// Actions
const FETCH_ALL_AMENITIES_KPI_ALL_NONE =
  'einvoice/kpi/FETCH_ALL_AMENITIES_KPI_ALL_NONE'
const FETCH_ALL_AMENITIES_KPI_ALL_REQUEST =
  'einvoice/kpi/FETCH_ALL_AMENITIES_KPI_ALL_REQUEST'
const FETCH_ALL_AMENITIES_KPI_ALL_SUCCESS =
  'einvoice/kpi/FETCH_ALL_AMENITIES_KPI_ALL_SUCCESS'
const FETCH_ALL_AMENITIES_KPI_ALL_FAILURE =
  'einvoice/kpi/FETCH_ALL_AMENITIES_KPI_ALL_FAILURE'
const FETCH_EXPENSES_ALL_EXP_TOTAL_REQUEST =
  'einvoice/kpi/FETCH_EXPENSES_ALL_EXP_TOTAL_REQUEST'
const FETCH_EXPENSES_ALL_EXP_TOTAL_SUCCESS =
  'einvoice/kpi/FETCH_EXPENSES_ALL_EXP_TOTAL_SUCCESS'
const FETCH_EXPENSES_ALL_EXP_TOTAL_FAILURE =
  'einvoice/kpi/FETCH_EXPENSES_ALL_EXP_TOTAL_FAILURE'
const FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_REQUEST =
  'einvoice/kpi/FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_REQUEST'
const FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_SUCCESS =
  'einvoice/kpi/FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_SUCCESS'
const FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_FAILURE =
  'einvoice/kpi/FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_FAILURE'
const FETCH_KPI_QUALITY_REQUEST = 'einvoice/kpi/FETCH_KPI_QUALITY_REQUEST'
const FETCH_KPI_QUALITY_SUCCESS = 'einvoice/kpi/FETCH_KPI_QUALITY_SUCCESS'
const FETCH_KPI_QUALITY_FAILURE = 'einvoice/kpi/FETCH_KPI_QUALITY_FAILURE'

// Reducer
const kpiAll = (
  state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_AMENITIES_KPI_ALL_NONE:
      return { Principal: { Libelle: '', Valeur: 0, Unite: '' } }
    case FETCH_ALL_AMENITIES_KPI_ALL_SUCCESS:
      return { ...state, ...action.response }
    default:
      return state
  }
}

const kpiExpTotal = (
  state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
  action
) => {
  switch (action.type) {
    case FETCH_EXPENSES_ALL_EXP_TOTAL_SUCCESS:
      return { ...state, ...action.response }
    default:
      return state
  }
}

const kpiAllDonutsGraph = (state = '', action) => {
  switch (action.type) {
    case FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_FAILURE:
      return ''
    case FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_SUCCESS:
      return action.response
    default:
      return state
  }
}

const defaultData = {
  integrees: null,
  theorique: null,
  pourcentage: null,
}

const quality = (
  state = {
    data: defaultData,
    loading: false,
    error: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_KPI_QUALITY_REQUEST:
      return {
        data: defaultData,
        loading: true,
      }
    case FETCH_KPI_QUALITY_SUCCESS:
      return {
        data: action.data,
        loading: false,
        error: false,
      }
    case FETCH_KPI_QUALITY_FAILURE:
      return {
        data: defaultData,
        loading: false,
        error: action.msg,
      }
    default:
      return state
  }
}

export default combineReducers({
  kpiAll,
  kpiExpTotal,
  kpiAllDonutsGraph,
  quality,
})

// Selectors
export const getKpiAll = (state) => state.kpi.kpiAll
export const getKpiExpTotal = (state) => state.kpi.kpiExpTotal
export const getDonutsGraph = (state) => state.kpi.kpiAllDonutsGraph

export const getKpiQuality = createStructuredSelector({
  quality: (state) => state.kpi.quality.data,
  loading: (state) => state.kpi.quality.loading,
  error: (state) => state.kpi.quality.error,
})

// Action Creators

// Thunks
export const fetchKpiAll = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_ALL_AMENITIES_KPI_ALL_REQUEST,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  let perimetre = getOrganisationIds(getState())

  if (!perimetre || perimetre !== []) {
    perimetre = reactLocalStorage.getObject('organisationIds')
  }

  perimetre = perimetre.join(';')
  const uorIdMst = commons.uorIdMst(getState())
  const lanId = commons.lanId(getState())
  if (perimetre) {
    return api
      .fetchKpi(
        dateDebut,
        dateFin,
        perimetre,
        'Volume',
        'Facture;FactureN1;Budget',
        12,
        21,
        '',
        'Total',
        'Commodite',
        uorIdMst,
        lanId
      )
      .then(
        (response) => {
          response.json().then((response) =>
            dispatch({
              type: FETCH_ALL_AMENITIES_KPI_ALL_SUCCESS,
              response: response,
            })
          )
        },
        (error) => {
          dispatch({
            type: FETCH_ALL_AMENITIES_KPI_ALL_FAILURE,
            message: error.message || 'Something went wrong.',
          })
        }
      )
  } else {
    dispatch({
      type: FETCH_ALL_AMENITIES_KPI_ALL_NONE,
      response: 'empty',
    })
  }
}

export const fetchKpiExpTotal = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_EXPENSES_ALL_EXP_TOTAL_REQUEST,
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const uorIdMst = commons.uorIdMst(getState())
  const lanId = commons.lanId(getState())

  let perimetre = getOrganisationIds(getState())

  if (!perimetre || perimetre !== []) {
    perimetre = reactLocalStorage.getObject('organisationIds')
  }

  perimetre = perimetre.join(';')

  return api
    .fetchKpi(
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      'Facture;FactureN1;Budget',
      12,
      21,
      '',
      'Total',
      'Commodite',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        response.json().then((response) =>
          dispatch({
            type: FETCH_EXPENSES_ALL_EXP_TOTAL_SUCCESS,
            response,
          })
        )
      },
      (error) => {
        dispatch({
          type: FETCH_EXPENSES_ALL_EXP_TOTAL_FAILURE,
          message: error.Message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiDonutsGraph = () => (dispatch, getState) => {
  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetreUser = getOrganisationIds(getState())
  const FactureKpiParametre = {
    PeeIds: perimetreUser, //List<long>
    Debut: dateDebut,
    Fin: dateFin, //DateTime
    Fournisseur: 'EDF', //string
    NrjId: 1, //int
    PaysId: commons.lanId(getState()), // long
    UorId: commons.uorIdMst(getState()), //long
    GeoId: 0, //long
  }

  dispatch({
    type: FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_REQUEST,
  })

  return api.fetchKpiDonutsGraph(FactureKpiParametre).then((response) => {
    if (response) {
      response.json().then(
        (response) =>
          dispatch({
            type: FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_SUCCESS,
            response: response,
          }),
        (error) => {
          dispatch({
            type: FETCH_ALL_AMENITIES_KPI_ALL_DONUTS_GRAPH_FAILURE,
            message: error.message || 'Something went wrong.',
          })
        }
      )
    }
  })
}

export const fetchKpiQuality = () => (dispatch, getState) => {
  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetreUser = getOrganisationIds(getState())
  const FactureKpiParametre = {
    PeeIds: perimetreUser, //List<long>
    Debut: dateDebut,
    Fin: dateFin, //DateTime
    Fournisseur: 'EDF', //string
    NrjId: 1, //int
    PaysId: commons.lanId(getState()), // long
    UorId: commons.uorIdMst(getState()), //long
    GeoId: 0, //long
  }

  dispatch({
    type: FETCH_KPI_QUALITY_REQUEST,
  })

  return api.fetchKpiQuality(FactureKpiParametre).then((response) => {
    if (response) {
      response.json().then(
        (resp) => {
          dispatch({
            type: FETCH_KPI_QUALITY_SUCCESS,
            data: {
              integrees: resp.NbFacIntegrees,
              theorique: resp.NbFacTheorique,
              pourcentage: resp.PourcentageQualite,
            },
          })
        },
        (error) => {
          dispatch({
            type: FETCH_KPI_QUALITY_FAILURE,
            message: error.message || 'Something went wrong.',
          })
        }
      )
    }
  })
}
