import React, { Component } from 'react'
import memoize from 'fast-memoize'
import ControlValidationBillsTableContainer from './ControlValidationBillsTableContainer'
import ControlProviderTable from './ControlProviderTable'
import ControlBillsTable from './ControlBillsTable'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'

import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

const billscoverUrlRx = /^\/billscover\/\d+\/?$/
const recallbillUrlRx = /^\/recallbill\/\d+\/?$/

class ControlTables extends Component {
  constructor(props) {
    super(props)

    const { url, params } = this.props.match

    const tab = billscoverUrlRx.test(url)
      ? '1'
      : recallbillUrlRx.test(url)
      ? '3'
      : params.tab

    this.state = {
      activeTab: tab || '1',
    }
  }

  toggle = memoize((tab) => () => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }

    this.props.history.push(`/control/${tab}`)

    //reset
  })

  render() {
    return (
      <div>
        <Nav tabs className="navbar--underline control_navbar">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={this.toggle('1')}
            >
              {this.props.t('control.cover')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={this.toggle('2')}
            >
              {this.props.t('control.provider')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={this.toggle('3')}
            >
              {this.props.t('control.validation')}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {this.state.activeTab === '1' && <ControlBillsTable />}
          </TabPane>
          <TabPane tabId="2">
            {this.state.activeTab === '2' && <ControlProviderTable />}
          </TabPane>
          <TabPane tabId="3">
            {this.state.activeTab === '3' && (
              <ControlValidationBillsTableContainer />
            )}
          </TabPane>
        </TabContent>
      </div>
    )
  }
}

const mapState = (state) => ({})

const mapDispatch = {}

export default compose(
  translate(),
  withRouter,
  connect(mapState, mapDispatch)
)(ControlTables)
