import React from 'react'
import { Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { fetchPdf, api } from 'store/pdf'
import { getUor } from 'store'

const ControlPopupOver = (props) => {
  const { bills, openBill, close } = props
  const uorId = useSelector(getUor)

  return (
    <Popover placement="bottom" isOpen={props.isOpen} target={props.id}>
      <PopoverHeader>
        {bills.length} {bills.length > 1 ? 'factures' : 'facture'}
        <Button close onClick={close} />
      </PopoverHeader>

      <PopoverBody>
        {bills.map((bill) => (
          <Bill
            bill={bill}
            openBill={openBill}
            key={bill.id.toString()}
            uorId={uorId}
          />
        ))}
      </PopoverBody>
    </Popover>
  )
}

const Bill = ({ bill, openBill, uorId }) => {
  const pdl = bill.FacPerimetre.PeeClef
  const invoiceNumber = bill.FacClef

  const { data } = useQuery(['pdfs', { pdl, invoiceNumber }], async () => {
    const resp = await api.fetchPdfs({ pdl, invoiceNumber, uorId })
    if (resp.status < 200 || resp.status >= 300)
      throw new Error(`${resp.status}`)
    return resp.json()
  })

  const pdfId = data && data.length > 0 ? data[0].id : null

  return (
    <div>
      <span>{bill.FacClef}</span>

      <span
        onClick={() => openBill(bill.id)}
        style={{ padding: 10, cursor: 'pointer' }}
        className="spanPopupOver"
      >
        <i className="icon-eye" />
      </span>

      <span
        onClick={() => pdfId !== null && fetchPdf(pdfId)}
        style={{
          cursor: pdfId !== null ? 'pointer' : 'default',
          opacity: pdfId !== null ? 1 : 0.3,
        }}
      >
        <i className="icon-file-download" />
      </span>
    </div>
  )
}

export default ControlPopupOver
