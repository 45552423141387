import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Form, FormGroup, Label } from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import DateRangeStartDateGlobal from './DateRangeStartDateGlobal'
import DateRangeEndDateGlobal from './DateRangeEndDateGlobal'

import {
  getBillsCoverFetching,
  getBillsProvidersFetching,
  getBillsValidationFetching,
  getBillsBalanceSheetFetching,
} from '../../../store/bills'

const disabledStyle = { background: 'hsl(0,0%,95%)' }

const DateRange = ({ location, t, isFetching }) => (
  <div className="date-range-container">
    <Form tag="div" inline className="date-range">
      <div className={['date-range-wrapper', ''].join(' ')}>
        <div className="date-range-global">
          {/* <FormGroup style={isFetching ? disabledStyle : null}>
            <Label htmlFor="period">
              {t('header.daterange.period')}
            </Label>
          </FormGroup> */}
          <FormGroup style={isFetching ? disabledStyle : null}>
            <Label htmlFor="startDate">
              {location.pathname === '/billstable'
                ? t('header.daterange.from')
                : t('header.daterange.fromAlt')}
              <i className="icon-event" />
            </Label>
            <DateRangeStartDateGlobal isDisabled={isFetching} />
          </FormGroup>
          <FormGroup style={isFetching ? disabledStyle : null}>
            <Label htmlFor="endDate">
              {location.pathname === '/billstable'
                ? t('header.daterange.to')
                : t('header.daterange.toAlt')}
              <i className="icon-event" />
            </Label>
            <DateRangeEndDateGlobal isDisabled={isFetching} />
          </FormGroup>
        </div>
      </div>
    </Form>
  </div>
)

const mapStateToProps = (state) => ({
  isFetching:
    getBillsCoverFetching(state) ||
    getBillsProvidersFetching(state) ||
    getBillsValidationFetching(state) ||
    getBillsBalanceSheetFetching(state),
})

DateRange.propTypes = {
  location: PropTypes.object.isRequired,
}

export default compose(
  translate(),
  withRouter,
  connect(mapStateToProps)
)(DateRange)
