import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
} from 'reactstrap'
import Datetime from 'react-datetime'
import moment from 'moment'
import memoize from 'fast-memoize'
import * as user from 'commons'

import { dateFormat } from '../../../helpers/formatters'
import {
  putBill,
  updateBillForm,
  getBillById,
  getCurrentBillForm,
} from 'store/bills'

// Components
import BillTable from './BillTable'
import BillHeader from './BillHeader'
import BillFileUpload from '../BillFileUpload'
import BillDocuments from '../BillDocuments'
import Loading from '../../common/Loading'
import Error from '../../common/Error'
import BillPdfList from '../BillPdfList'

class Bill extends Component {
  putBill = (e) => {
    e.preventDefault()
    this.props.putBill()
  }

  updateBill = (k, v) => {
    const keys = k.split('.')
    keys.reverse().map((i) => (v = { [i]: v }))
    this.props.updateBillForm(v)
  }

  updateInput = memoize((k) => (e) => {
    this.updateBill(k, e.target.value)
  })

  updateDateInput = (date) => {
    this.updateBill(
      'FacDatePaiement',
      date && date._isValid ? date.format('DD/MM/YYYY') : null
    )
  }

  render() {
    const { bill, t, lng, closeBill, error, isLoading } = this.props

    if (error)
      return (
        <div className="bill-error">
          <Error />
        </div>
      )

    if (isLoading)
      return (
        <div className="bill-loading">
          <Loading />
        </div>
      )

    return (
      <div>
        <Button
          className="btn btn-close"
          style={{ border: 'none' }}
          onClick={closeBill}
        >
          <i className="icon-cross" />
        </Button>
        <Container>
          <Form>
            <BillHeader {...bill} />

            <Row className="addresses">
              <Col xs="9">
                <Row>
                  <Col>
                    <h4>{t('bills.bill.address.site')}</h4>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SphAdresse &&
                            bill.FacSite.SphAdresse.AdrAdresse) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.address')}
                        onChange={this.updateInput(
                          'FacSite.SphAdresse.AdrAdresse'
                        )}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SphAdresse &&
                            bill.FacSite.SphAdresse.AdrCompl1) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.comp1')}
                        onChange={this.updateInput(
                          'FacSite.SphAdresse.AdrCompl1'
                        )}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SphAdresse &&
                            bill.FacSite.SphAdresse.AdrCompl2) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.comp2')}
                        onChange={this.updateInput(
                          'FacSite.SphAdresse.AdrCompl2'
                        )}
                      />
                    </FormGroup>
                    <div className="form-inline">
                      <FormGroup>
                        <Input
                          className="postal-code"
                          value={
                            (bill.FacSite &&
                              bill.FacSite.SphAdresse &&
                              bill.FacSite.SphAdresse.AdrCodePostal) ||
                            ''
                          }
                          placeholder={t('bills.bill.address.pc')}
                          onChange={this.updateInput(
                            'FacSite.SphAdresse.AdrCodePostal'
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={
                            (bill.FacSite &&
                              bill.FacSite.SphAdresse &&
                              bill.FacSite.SphAdresse.AdrVille) ||
                            ''
                          }
                          placeholder={t('bills.bill.address.city')}
                          onChange={this.updateInput(
                            'FacSite.SphAdresse.AdrVille'
                          )}
                        />
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SphAdresse &&
                            bill.FacSite.SphAdresse.AdrPays) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.country')}
                        onChange={this.updateInput(
                          'FacSite.SphAdresse.AdrPays'
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <h4>{t('bills.bill.address.billing')}</h4>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SitFacAdresse &&
                            bill.FacSite.SitFacAdresse.AdrAdresse) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.address')}
                        onChange={this.updateInput(
                          'FacSite.SitFacAdresse.AdrAdresse'
                        )}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SitFacAdresse &&
                            bill.FacSite.SitFacAdresse.AdrCompl1) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.comp1')}
                        onChange={this.updateInput(
                          'FacSite.SitFacAdresse.AdrCompl1'
                        )}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SitFacAdresse &&
                            bill.FacSite.SitFacAdresse.AdrCompl2) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.comp2')}
                        onChange={this.updateInput(
                          'FacSite.SitFacAdresse.AdrCompl2'
                        )}
                      />
                    </FormGroup>
                    <div className="form-inline">
                      <FormGroup>
                        <Input
                          className="postal-code"
                          value={
                            (bill.FacSite &&
                              bill.FacSite.SitFacAdresse &&
                              bill.FacSite.SitFacAdresse.AdrCodePostal) ||
                            ''
                          }
                          placeholder={t('bills.bill.address.pc')}
                          onChange={this.updateInput(
                            'FacSite.SitFacAdresse.AdrCodePostal'
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={
                            (bill.FacSite &&
                              bill.FacSite.SitFacAdresse &&
                              bill.FacSite.SitFacAdresse.AdrVille) ||
                            ''
                          }
                          placeholder={t('bills.bill.address.city')}
                          onChange={this.updateInput(
                            'FacSite.SitFacAdresse.AdrVille'
                          )}
                        />
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SitFacAdresse &&
                            bill.FacSite.SitFacAdresse.AdrPays) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.country')}
                        onChange={this.updateInput(
                          'FacSite.SitFacAdresse.AdrPays'
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            {bill.FacGroupeLfa && (
              <BillTable
                FacGroupeLfa={bill.FacGroupeLfa}
                FacDevise={bill.FacDevise}
              />
            )}

            <Row className="bill-more">
              <Col xs="7">
                <Row>
                  <Col xs="5">
                    <Label>{t('bills.bill.paidOn')}:</Label>
                    <InputGroup>
                      <Datetime
                        id="paid-on"
                        ref={(input) => {
                          this.dateInput = input
                        }}
                        locale={lng}
                        closeOnSelect
                        dateFormat={dateFormat()}
                        timeFormat={false}
                        className="form-control"
                        value={
                          (bill.FacDatePaiement &&
                            moment(
                              bill.FacDatePaiement,
                              'DD/MM/YYYY'
                            ).toDate()) ||
                          ''
                        }
                        onChange={this.updateDateInput}
                        inputProps={{ className: '' }}
                      />
                      <InputGroupAddon
                        className="calendar-ico input-group-addon calendar-input-bill"
                        onClick={() => {
                          this.dateInput.openCalendar()
                        }}
                        addonType="append"
                      >
                        <i className="icon-event icon-calendar-bill" />
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="textarea"
                      rows="3"
                      value={bill.FacNoteContenu || ''}
                      placeholder={t('bills.bill.note')}
                      onChange={this.updateInput('FacNoteContenu')}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="5" className="bill-docs">
                <h4>{t('bills.bill.pdfAttachments')}</h4>

                <BillPdfList bill={this.props.currentBillForm} />

                <h4>{t('bills.bill.attachments')}</h4>

                {bill.FacDocsJoints && (
                  <ul>
                    {bill.FacDocsJoints.map((doc) => (
                      <BillDocuments key={doc.Id} billId={bill.id} {...doc} />
                    ))}
                  </ul>
                )}

                <BillFileUpload />
              </Col>
            </Row>
            <Row className="bill-bottom">
              <Col className="text-right">
                <Button className="btn btn-link" onClick={closeBill}>
                  {t('bills.bill.cancel')}
                </Button>
                <Button color="link" onClick={this.putBill}>
                  {t('bills.bill.save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const bill = getBillById(state, ownProps.billId) || {}
  return {
    currentBillForm: getCurrentBillForm(state),
    lng: user.lngIso(state),
    isLoading: bill.isFetching,
    error: bill.error,
  }
}

const mapDispatchToProps = {
  putBill,
  updateBillForm,
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Bill))
