import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { Label, Input } from 'reactstrap'

import { postBillPdf } from 'store/pdf'

class BillPdfUpload extends Component {
  constructor(props) {
    super(props)

    this.uploadPdf = this.uploadPdf.bind(this)
  }

  uploadPdf(e) {
    const { dispatch } = this.props
    dispatch(postBillPdf(e.target.files[0], this.props.bill))
  }

  render() {
    const { t } = this.props
    return (
      <Label className="custom-file">
        <Input
          type="file"
          className="custom-file-input"
          onChange={(e) => this.uploadPdf(e)}
        />
        + {t('bills.bill.addInvoice')}
      </Label>
    )
  }
}

export default translate()(connect()(BillPdfUpload))
