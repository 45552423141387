import React from 'react'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { I18nextProvider } from 'react-i18next'
import { Route, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import get from 'lodash/get'

// Config
import { i18n, init, userManager, lngIsoStr } from 'commons'
import * as env from './helpers/env'

import configureStore from './configureStore'
import Root from './components/route/Root'
import startAnalytics from './analytics'

// Bundle de traduction
import fr from './locales/fr.json'
import en from './locales/en.json'
i18n.addResourceBundle('fr', 'translation', fr)
i18n.addResourceBundle('en', 'translation', en)

// Init before configure store
init(env)

// Configure store
export const store = configureStore()

export const history = createBrowserHistory()
startAnalytics(history)

let lanId = false
let unsubscribe = () => {}
unsubscribe = store.subscribe(() => {
  const state = store.getState()
  lanId = get(state, 'user.userInfo.LanId') || false
  if (lanId) {
    unsubscribe()
    i18n.changeLanguage(lngIsoStr(lanId))
  }
})

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <Router history={history}>
            <Route path="/" component={Root} />
          </Router>
        </OidcProvider>
      </Provider>
    </I18nextProvider>
  )
}

export default App
