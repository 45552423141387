import React from 'react'
import { connect } from 'react-redux'

import { fetchPdfs, getPdfs } from 'store/pdf'
import BillPdf from './BillPdf'
import BillPdfUpload from './BillPdfUpload'

const BillPdfList = ({ fetchPdfs, pdfs, bill }) => {
  React.useEffect(() => {
    if (bill.id === 0) return
    fetchPdfs(bill)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill.id])

  const list = pdfs ? Object.values(pdfs) : []

  if (list.length === 0) return <BillPdfUpload bill={bill} />

  return (
    <ul>
      {list.map((pdf) => (
        <BillPdf
          key={pdf.id}
          id={pdf.id}
          number={pdf.invoiceNumber}
          bill={bill}
        />
      ))}
    </ul>
  )
}

const mapState = (state) => ({
  pdfs: getPdfs(state),
})

const mapDispatch = {
  fetchPdfs,
}

export default connect(mapState, mapDispatch)(BillPdfList)
