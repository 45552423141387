import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { LogoEleneo } from 'components/LogoEleneo'
import { decimalFormat } from '../../../helpers/formatters'

const BillHeader = ({
  dispatch,
  FacLogoFournisseur,
  FacFournisseur,
  FacClef,
  FacDate,
  FacPerimetre,
  FacEnergie,
  FacStatutEvaluationUtilisateur,
  FacStatutEvaluation,
  FacStatutEvaluationLibelle,
  FacMontantHTVA,
  FacDevise,
  t,
}) => (
  <div className="bill-header">
    <Row>
      <Col>
        <h1>{FacFournisseur}</h1>
      </Col>
      <Col className="text-right">
        <LogoEleneo colorful style={{ fontSize: '3rem' }} />
      </Col>
    </Row>
    <Row>
      <Col>
        <h2>{t('bills.bill.billId') + ' ' + FacClef}</h2>
      </Col>
    </Row>

    <Row className="bill-top">
      <Col xs="9" className="bill-infos">
        <Row>
          <Col>{t('bills.bill.date')}</Col>
          <Col className="text-right">{FacDate}</Col>
        </Row>
        <Row>
          <Col>{t('bills.bill.pee')}</Col>
          <Col className="text-right">
            {FacPerimetre && FacPerimetre.PeeClef}
          </Col>
        </Row>
        <Row>
          <Col>{t('bills.bill.energy')}</Col>
          <Col className="text-right">
            {FacPerimetre && FacPerimetre.PeeNrj}
          </Col>
        </Row>
        {/*
				<Row>
					<Col>{t("bills.bill.status")}</Col>
					<Col className="text-right">
						<Input type="select" value={FacStatutEvaluationUtilisateur} onChange={e => dispatch(updateBillStatus(e.target.value))}>
							<option	value="0">{t("bills.bill.statusType.0")}</option>
							<option	value="1">{t("bills.bill.statusType.1")}</option>
							<option	value="2">{t("bills.bill.statusType.2")}</option>
						</Input>
					</Col>
				</Row>
				*/}
        {/*<Row>*/}
        {/*<Col>{t("bills.bill.state")}</Col>*/}
        {/*<Col className="text-right">*/}
        {/*{FacStatutEvaluationLibelle}*/}
        {/*</Col>*/}
        {/*</Row>*/}
      </Col>
      <Col xs="3" className="d-flex align-items-center bill-total">
        <p>
          <span>{t('bills.bill.price')}</span>
          <br />
          <span className="price">
            {decimalFormat(FacMontantHTVA)} {FacDevise}
            <small>{t('bills.bill.htva')}</small>
          </span>
        </p>
      </Col>
    </Row>
  </div>
)

BillHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  FacFournisseur: PropTypes.string,
  FacClef: PropTypes.string,
  FacDate: PropTypes.string,
  FacPerimetre: PropTypes.object,
  FacStatutEvaluationUtilisateur: PropTypes.number,
  FacStatutEvaluation: PropTypes.number,
  FacMontantHTVA: PropTypes.number,
  FacDevise: PropTypes.string,
}

export default translate()(connect()(BillHeader))
