import React, { Component } from 'react'

import { getReliedBills } from '../../../store/bills'

import ReactTable from 'react-table'
import { isNullOrUndefined } from 'util'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Loading from '../../common/Loading'

import { changeStatusBills, getReliedBillsStatus } from 'store/bills'

class ReliedBillsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.recallBill && !!this.props.recallBill) {
      this.setState({
        status: this.props.recallBill.principale.FacStatutEvaluationUtilisateur,
      })
    }
  }

  handleChange = (e) => {
    const value = e.target.value

    this.setState((state) => ({
      ...state,
      status: value,
    }))
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.changeStatusBills({
      id: this.props.recallBill.principale.id,
      status: this.state.status,
    })

    this.props.updateBillsOnClose()
  }

  render() {
    const { recallBill, t, isLoading } = this.props
    const columns = [
      {
        Header: this.props.t('billstable.numfac'),
        accessor: 'numfac',
      },
      {
        Header: this.props.t('billstable.status'),
        accessor: 'status',
        Cell: (prop) => (
          <div className="text-right">
            <select
              className={'form-control'}
              value={this.state.status}
              onChange={this.handleChange}
              name={prop.value[0]}
              disabled={isLoading}
            >
              <option value="0">
                {this.props.t('billstable.statusType.0')}
              </option>
              <option value="1">
                {this.props.t('billstable.statusType.1')}
              </option>
              <option value="2">
                {this.props.t('billstable.statusType.2')}
              </option>
            </select>
          </div>
        ),
      },
    ]

    const billInfos =
      isNullOrUndefined(recallBill) || !recallBill.principale
        ? []
        : [
            {
              numfac: recallBill.principale.FacClef,
              status: [
                recallBill.principale.id,
                recallBill.principale.FacStatutEvaluationUtilisateur,
              ],
            },
          ]

    return (
      <div>
        <ReactTable
          data={billInfos}
          columns={columns}
          showPagination={false}
          pageSize={billInfos.length}
          rowsText={t('billstable.table.rowsText')}
          ofText={t('billstable.table.rowsText')}
          previousText={t('billstable.table.previousText')}
          nextText={t('billstable.table.nextText')}
          loadingText={t('billstable.table.loadingText')}
          LoadingComponent={Loading}
          noDataText={t('billstable.table.noDataText')}
          getTdProps={(state, rowInfo, column, instance) => {
            if (rowInfo) {
              return {
                style: {
                  backgroundColor:
                    rowInfo.viewIndex % 2 === 0 ? '#f3f7f9' : 'white',
                },
              }
            } else {
              return {}
            }
          }}
        />
        <br />
        <div className={'text-right'}>
          <button className={'btn btn-primary'} onClick={this.handleSubmit}>
            Valider
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  recallBill: getReliedBills(state),
  isLoading: getReliedBillsStatus(state).isFetching,
})

const mapDispatchToProps = {
  changeStatusBills,
}

export default translate()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ReliedBillsTable))
)
