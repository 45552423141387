import React from 'react'
import PropTypes from 'prop-types'
import { Navbar, Nav, NavItem, Button, Badge } from 'reactstrap'
import { withRouter, NavLink } from 'react-router-dom'
import { translate } from 'react-i18next'
import { mainLogo } from '../../helpers/logo'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

import { fetchAdminOptions } from '../../store/admin'
import { isAdmin } from '../../store/selectors'
import { LogoEleneo } from 'components/LogoEleneo'

const TopNav = ({
  isAdmin,
  location,
  setUserPanelOpen,
  userPanelOpen,
  t,
  logo,
}) => {
  return (
    <Navbar color="primary" expand className="topNav">
      <Nav navbar className="leftNav">
        <NavItem key="topNav.home">
          <div
            className="logo-container"
            style={{
              padding: logo ? '12px 75px 18px 30px' : '14px 75px 14px 30px',
            }}
          >
            {logo ? (
              <NavLink to="/" className="logo">
                <img
                  src={logo}
                  alt={t('sidebar.logoAlt')}
                  className="img-logo"
                />
              </NavLink>
            ) : (
              <>
                <NavLink to="/" className="logo">
                  <span>eInvoice</span>
                </NavLink>

                <span className="logo-by">by</span>

                <a
                  href="https://www.eleneo.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LogoEleneo
                    color="#f8f9fa"
                    fill="#adb5bd"
                    style={{ fontSize: '1.2rem' }}
                  />
                </a>
              </>
            )}
          </div>
        </NavItem>
        {paths.map((i, index) => (
          <NavItem key={index}>
            <NavLink
              to={i.dir + i.link}
              activeClassName="active"
              className="nav-link align-bottom"
              isActive={() =>
                (i.dir && location.pathname.match('^' + i.dir)) ||
                location.pathname.match('^' + i.link)
              }
            >
              <span>
                {t(i.text)}
                {i.nb && <Badge>{i.nb}</Badge>}
              </span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Nav className="ml-auto rightNav" navbar>
        {isAdmin &&
          pathsAdmin.map((i, index) => (
            <NavItem key={index}>
              <NavLink
                to={i.dir + i.link}
                activeClassName="active"
                className="nav-link align-bottom"
                isActive={() =>
                  (i.dir && location.pathname.match('^' + i.dir)) ||
                  location.pathname.match('^' + i.link)
                }
              >
                <span>
                  <i className={i.icon} /> {t(i.text)}{' '}
                  {i.nb && <Badge>{i.nb}</Badge>}
                </span>
              </NavLink>
            </NavItem>
          ))}
        <NavItem className="userNavItem">
          <Button
            color="link"
            title=""
            onClick={() => setUserPanelOpen(!userPanelOpen)}
          >
            <i className="icon-user" />
            <i className="icon-smallarrow-down" />
          </Button>
        </NavItem>
      </Nav>
    </Navbar>
  )
}

TopNav.propTypes = {
  location: PropTypes.object.isRequired,
  setUserPanelOpen: PropTypes.func.isRequired,
  userPanelOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isAdmin: isAdmin(state),
  logo: mainLogo(state),
})

const mapDispatchToProps = {
  fetchAdminOptions,
}

export default compose(
  translate(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchAdminOptions()
    },
  })
)(TopNav)

export const paths = [
  {
    text: 'topNav.dashboard',
    dir: '',
    link: '/kpi',
  },
  {
    text: 'topNav.bills',
    dir: '',
    link: '/billstable',
  },
  {
    text: 'topNav.controle',
    dir: '',
    link: '/control',
  },
]

export const pathsAdmin = [
  {
    text: 'topNav.administration',
    icon: 'icon-ico-menu-parameters',
    dir: '',
    link: '/admin',
  },
]
