import React from 'react'
import { Alert } from 'reactstrap'
import { translate } from 'react-i18next'

const Error = ({ t }) => (
  <div>
    <Alert color="danger" style={{ display: 'inline-block' }}>
      {t('global.error')}
    </Alert>
  </div>
)

export default translate()(Error)
