import { combineReducers } from 'redux'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchOrganisationIds: () =>
    superFetch({
      url: `organisation/pdlid`,
    }),
  fetchBu: () =>
    superFetch({
      url: `organisation/uortree`,
    }),
  fetchCountries: () =>
    superFetch({
      url: `pays`,
    }),
  fetchRegions: () =>
    superFetch({
      url: `organisation/geotree`,
    }),
  fetchProviders: () =>
    superFetch({
      url: `fournisseur`,
    }),
}

// Actions
const FETCH_ORGANISATION_IDS_REQUEST =
  'einvoice/organisation/FETCH_ORGANISATION_IDS_REQUEST'
const FETCH_ORGANISATION_IDS_SUCCESS =
  'einvoice/organisation/FETCH_ORGANISATION_IDS_SUCCESS'
const FETCH_ORGANISATION_IDS_FAILURE =
  'einvoice/organisation/FETCH_ORGANISATION_IDS_FAILURE'
const FETCH_BU_REQUEST = 'einvoice/organisation/FETCH_BU_REQUEST'
const FETCH_BU_SUCCESS = 'einvoice/organisation/FETCH_BU_SUCCESS'
const FETCH_BU_FAILURE = 'einvoice/organisation/FETCH_BU_FAILURE'
const FETCH_COUNTRIES_REQUEST = 'einvoice/organisation/FETCH_COUNTRIES_REQUEST'
const FETCH_COUNTRIES_SUCCESS = 'einvoice/organisation/FETCH_COUNTRIES_SUCCESS'
const FETCH_COUNTRIES_FAILURE = 'einvoice/organisation/FETCH_COUNTRIES_FAILURE'
const FETCH_PROVIDERS_REQUEST = 'einvoice/organisation/FETCH_PROVIDERS_REQUEST'
const FETCH_PROVIDERS_SUCCESS = 'einvoice/organisation/FETCH_PROVIDERS_SUCCESS'
const FETCH_PROVIDERS_FAILURE = 'einvoice/organisation/FETCH_PROVIDERS_FAILURE'
const FETCH_REGIONS_REQUEST = 'einvoice/organisation/FETCH_REGIONS_REQUEST'
const FETCH_REGIONS_SUCCESS = 'einvoice/organisation/FETCH_REGIONS_SUCCESS'
const FETCH_REGIONS_FAILURE = 'einvoice/organisation/FETCH_REGIONS_FAILURE'

// Reducer
const organisationIds = (state = null, action) => {
  switch (action.type) {
    case FETCH_ORGANISATION_IDS_SUCCESS:
      return action.response
    default:
      return state
  }
}

const bu = (state = null, action) => {
  switch (action.type) {
    case FETCH_BU_SUCCESS:
      return action.response
    default:
      return state
  }
}

const countries = (state = null, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      return action.response
    default:
      return state
  }
}

const providers = (state = null, action) => {
  switch (action.type) {
    case FETCH_PROVIDERS_SUCCESS:
      return action.response
    default:
      return state
  }
}

const regions = (state = null, action) => {
  switch (action.type) {
    case FETCH_REGIONS_SUCCESS:
      return action.response
    default:
      return state
  }
}

export default combineReducers({
  organisationIds,
  bu,
  countries,
  providers,
  regions,
})

// Selectors
export const getOrganisationIds = (state) =>
  state.organisation.organisationIds || []
export const getBu = (state) => state.organisation.bu || null
export const getCountries = (state) => state.organisation.countries || null
export const getProviders = (state) => state.organisation.providers || null
export const getRegions = (state) => state.organisation.regions || null

// Action Creators

// Thunks
export const fetchOrganisationIds = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_ORGANISATION_IDS_REQUEST,
  })

  return api.fetchOrganisationIds().then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: FETCH_ORGANISATION_IDS_SUCCESS,
          response: response,
        })
      })
    },
    (error) => {
      dispatch({
        type: FETCH_ORGANISATION_IDS_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchCountries = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_COUNTRIES_REQUEST,
  })

  return api.fetchCountries().then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: FETCH_COUNTRIES_SUCCESS,
          response: response,
        })
      })
    },
    (error) => {
      dispatch({
        type: FETCH_COUNTRIES_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchBu = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_BU_REQUEST,
  })

  return api.fetchBu().then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: FETCH_BU_SUCCESS,
          response: response,
        })
      })
    },
    (error) => {
      dispatch({
        type: FETCH_BU_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchRegions = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_REGIONS_REQUEST,
  })

  return api.fetchRegions().then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: FETCH_REGIONS_SUCCESS,
          response: response,
        })
      })
    },
    (error) => {
      dispatch({
        type: FETCH_REGIONS_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchProviders = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_PROVIDERS_REQUEST,
  })

  return api.fetchProviders().then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: FETCH_PROVIDERS_SUCCESS,
          response: response,
        })
      })
    },
    (error) => {
      dispatch({
        type: FETCH_PROVIDERS_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}
