import React from 'react'
import classnames from 'classnames'

import './Loader.scss'

const Loader = ({ center = true }) => (
  <svg
    className={classnames('commons-loader', {
      'commons-loader-centered': center,
    })}
    viewBox="0 0 500 500"
  >
    <circle
      className="commons-loader-path"
      cx="250"
      cy="250"
      r="200"
      fill="none"
      strokeWidth="48"
    ></circle>
  </svg>
)

export default Loader
