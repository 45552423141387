import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Datetime from 'react-datetime'
import moment from 'moment'
import * as user from 'commons'

import { dateFormat, dateFormatMonths } from '../../../helpers/formatters'
import { getDateRange, updateEndDate } from '../../../store/dateRange'

const DateRangeEndDateGlobal = ({
  dispatch,
  dateRange,
  location,
  lng,
  isDisabled,
}) => (
  <Datetime
    id="endDate"
    locale={lng}
    closeOnSelect
    timeFormat={false}
    dateFormat={
      location.pathname === '/market-informations'
        ? dateFormat()
        : dateFormatMonths()
    }
    viewMode={location.pathname === '/market-informations' ? 'days' : 'months'}
    value={moment(dateRange.endDate)
      .startOf('month')
      .toDate()}
    onChange={(date) => {
      const d = date.clone().endOf('month')
      dispatch(updateEndDate(d))
    }}
    inputProps={{
      disabled: isDisabled,
    }}
  />
)

const mapStateToProps = (state) => ({
  dateRange: getDateRange(state),
  lng: user.lngIso(state),
})

DateRangeEndDateGlobal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  lng: PropTypes.string.isRequired,
}

export default withRouter(connect(mapStateToProps)(DateRangeEndDateGlobal))
