import React from 'react'
import { CSVLink } from 'react-csv'
import { Col, Row } from 'reactstrap'
import { translate } from 'react-i18next'

import Filters from 'components/common/Filters'

const ControlBillsHeader = ({ t, data, fetchData }) => {
  return (
    <Row>
      <Col xs="10">
        <Filters onChange={fetchData} />
      </Col>

      <Col xs="2" className="text-right">
        <CSVLink
          className="btn csv-link"
          data={data}
          filename={`E-Invoice - ${t('control.cover')}.csv`}
        >
          {t('button.exportcsv')}
        </CSVLink>
      </Col>
    </Row>
  )
}

export default translate()(ControlBillsHeader)
