import React, { Component } from 'react'
import { connect, ReactReduxContext } from 'react-redux'
import Notifications from 'react-notification-system-redux'
import { Button } from 'reactstrap'

import RecallBill from './RecallBill'

import {
  getSortedBillsIds,
  getReliedBillsStatus,
  getBillsBalanceSheet,
  getBillsCover,
  fetchReliedBill,
  fetchReliedCoversBills,
  fetchBillsValidation,
} from '../../../store/bills'

class VisibleRecallBill extends Component {
  shouldUpdateBills = false

  componentDidMount() {
    if (!!this.props.billId) {
      this.fetchData()
    }
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.billId && this.props.billId !== prevProps.billId) {
      this.fetchData()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isPutDone =
      !this.props.billStatus.isFetching && nextProps.billStatus.isFetching
    const isSuccess = isPutDone && !nextProps.billStatus.error
    const isFailure = isPutDone && nextProps.billStatus.error

    if (isSuccess) {
      this.props.store.dispatch(
        Notifications.success({
          title: 'Bill has been saved successfully !',
          position: 'bl',
          autoDismiss: 4,
        })
      )
    }

    if (isFailure) {
      this.props.store.dispatch(
        Notifications.error({
          title: 'Something went wrong !',
          position: 'bl',
          autoDismiss: 4,
        })
      )
    }
  }

  updateBillsOnClose = () => {
    this.shouldUpdateBills = true
  }

  fetchData() {
    this.props.fetchReliedBill(this.props.billId)
    this.props.fetchReliedCoversBills(this.props.billId)
  }

  close = () => {
    this.props.closeBill()
    if (this.shouldUpdateBills) this.props.fetchBillsValidation({ force: true })
  }

  render() {
    let { billId, openBill, filteredBillIds } = this.props

    if (!billId) return null

    const currIndex = filteredBillIds.indexOf(billId)
    const prev =
      filteredBillIds[currIndex - 1] ||
      filteredBillIds[filteredBillIds.length - 1]
    const next = filteredBillIds[currIndex + 1] || filteredBillIds[0]

    return (
      <div>
        <div className="bill-overlay" onClick={this.close} />

        {filteredBillIds.length > 1 && (
          <div className="bill-nav">
            <Button onClick={() => openBill(prev)}>
              <i className="icon-smallarrow-left" />
            </Button>
            <Button onClick={() => openBill(next)}>
              <i className="icon-smallarrow-right" />
            </Button>
          </div>
        )}

        <div className="bill">
          <RecallBill
            {...this.props}
            closeBill={this.close}
            updateBillsOnClose={this.updateBillsOnClose}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bills: getBillsBalanceSheet(state),
  billsCover: getBillsCover(state),
  sortedBillsIds: getSortedBillsIds(state),
  billStatus: getReliedBillsStatus(state),
})

const mapDispatchToProps = {
  fetchReliedBill,
  fetchReliedCoversBills,
  fetchBillsValidation,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <ReactReduxContext.Consumer>
    {({ store }) => <VisibleRecallBill {...props} store={store} />}
  </ReactReduxContext.Consumer>
))
