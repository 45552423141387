import React from 'react'
import { connect } from 'react-redux'

import Butree from './Butree'
import Countries from './Countries'
import Regionstree from './Regions'
import Providers from './Providers'

import {
  getBillsCoverFetching,
  getBillsProvidersFetching,
  getBillsValidationFetching,
} from '../../../store/bills'

import styles from './Filters.module.scss'

const Filters = ({ onChange, showProviders = false, isFetching } = {}) => (
  <div className={styles.filters}>
    <Butree isDisabled={isFetching} onChange={onChange} />
    <Countries isDisabled={isFetching} onChange={onChange} />
    <Regionstree isDisabled={isFetching} onChange={onChange} />
    {showProviders && <Providers isDisabled={isFetching} onChange={onChange} />}
  </div>
)

const mapStateToProps = (state) => ({
  isFetching:
    getBillsCoverFetching(state) ||
    getBillsProvidersFetching(state) ||
    getBillsValidationFetching(state),
})

export default connect(mapStateToProps)(Filters)
