import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import { deleteBillFile, downloadInvoice, fetchBill } from '../../store/bills'

class BillDocuments extends Component {
  constructor(props) {
    super(props)

    this.deleteDocument = this.deleteDocument.bind(this)
  }

  download(e, DocUrl, DocLibelle) {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch(downloadInvoice(DocUrl, DocLibelle))
  }

  deleteDocument(e) {
    e.preventDefault()
    const { Id, dispatch, billId } = this.props
    dispatch(deleteBillFile(Id)).then(() => {
      dispatch(fetchBill(billId))
    })
  }

  render() {
    const { DocLibelle, DocUrl } = this.props

    return (
      <li className="d-flex align-items-center">
        <i className="icon-ion-document" /> {DocLibelle}
        <span className="ml-auto">
          <Link
            className="btn btn-link"
            onClick={(e) => this.download(e, DocUrl, DocLibelle)}
            to={'void(0)'}
          >
            <i className="icon-file-download" />
          </Link>
          <Button color="link" onClick={this.deleteDocument}>
            <i className="icon-cross" />
          </Button>
        </span>
      </li>
    )
  }
}

export default connect()(BillDocuments)
