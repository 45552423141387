import React from 'react'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { withRouter } from 'react-router-dom'

// Components
import BillsTable from './BillsTable'
import VisibleBill from '../bill/VisibleBill'

// Store
import DateRange from '../common/DateRange'

const billUrlRx = /^\/billsfacto\/\d+\/?$/

const BillsTableView = ({
  billId,
  openBill,
  closeBill,
  filteredData,
  setFilteredData,
}) => (
  <div>
    <DateRange />

    <div className="billtable">
      <BillsTable
        openBill={openBill}
        setFilteredData={setFilteredData}
        filteredData={filteredData}
      />
    </div>

    {billId && (
      <VisibleBill
        billId={billId}
        closeBill={closeBill}
        openBill={openBill}
        filteredBillIds={filteredData.map((x) => x.billId)}
      />
    )}
  </div>
)

export default compose(
  withRouter,
  withState('billId', 'setBillId', null),
  withState('filteredData', 'setFilteredData', []),
  withHandlers({
    closeBill: (props) => () => {
      props.setBillId(null)
      props.history.push(`/billstable`)
    },
    openBill: (props) => (id) => {
      props.setBillId(id)
      props.history.push(`/billsfacto/${id}`)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { url, params } = this.props.match
      if (billUrlRx.test(url)) {
        this.props.setBillId(params.id)
      }
    },
  })
)(BillsTableView)
