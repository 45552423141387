const initialState = {
  bus: [],
  countries: [],
  regions: [],
  providers: [],
}

// Actions
const SET_BUS = 'einvoice/filters/SET_BUS'
const SET_COUNTRIES = 'einvoice/filters/SET_COUNTRIES'
const SET_REGIONS = 'einvoice/filters/SET_REGIONS'
const SET_PROVIDERS = 'einvoice/filters/SET_PROVIDERS'

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUS:
      return {
        ...state,
        bus: action.payload,
      }
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      }
    case SET_REGIONS:
      return {
        ...state,
        regions: action.payload,
      }
    case SET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
      }
    default:
      return state
  }
}

// Selectors
export const getBusFilters = (state) => state.filters.bus
export const getCountriesFilters = (state) => state.filters.countries
export const getRegionsFilters = (state) => state.filters.regions
export const getProvidersFilters = (state) => state.filters.providers

// Action Creators
export const setBusFilters = (payload) => ({ type: SET_BUS, payload })
export const setCountriesFilters = (payload) => ({
  type: SET_COUNTRIES,
  payload,
})
export const setRegionsFilters = (payload) => ({ type: SET_REGIONS, payload })
export const setProvidersFilters = (payload) => ({
  type: SET_PROVIDERS,
  payload,
})
