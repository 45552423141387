import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'

import Home from '../dashboard/Home'

import BillsTableView from '../bills/BillsTableView'
import ControlPanel from '../controls/ControlPanel'
import Admin from '../admin/Admin'

import { isAdmin } from '../../store/selectors'

const Views = () => (
  <Switch>
    <Route path="/kpi" component={Home} />
    <Route path="/billstable" component={BillsTableView} />
    <Route path="/control/:tab?" component={ControlPanel} />

    <Route path="/billsfacto/:id" component={BillsTableView} />
    <Route path="/billscover/:id" component={ControlPanel} />
    <Route path="/recallbill/:id" component={ControlPanel} />

    {isAdmin && <Route path="/admin/" component={Admin} />}
  </Switch>
)

export default withRouter(Views)
