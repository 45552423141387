export function adaptPageSizeOptions(formattedData = []) {
  /**
   * adapt sizeoptions with data length
   */
  if (formattedData.length > 50 && formattedData.length <= 100) {
    return [5, 10, 20, 25, 50, formattedData.length]
  } else if (formattedData.length > 25 && formattedData.length <= 50) {
    return [5, 10, 20, 25, formattedData.length]
  } else if (formattedData.length > 20 && formattedData.length <= 25) {
    return [5, 10, 20, formattedData.length]
  } else if (formattedData.length > 10 && formattedData.length <= 20) {
    return [5, 10, formattedData.length]
  } else if (formattedData.length > 5 && formattedData.length <= 10) {
    return [5, formattedData.length]
  } else if (formattedData.length < 5) {
    return [1, formattedData.length]
  } else {
    return [5, 10, 20, 25, 50, 100]
  }
}
