import keyBy from 'lodash/keyBy'
import * as user from 'commons'
import download from 'downloadjs'

import { getUor } from 'store'

const { superFetch } = user.apiConf

const checkStatus = (resp) => {
  const { status, statusText } = resp
  if (status < 200 || status >= 300)
    throw new Error(`Erreur ${status}: ${statusText}`)
}

export const api = {
  fetchPdfs: async ({ pdl, uorId, invoiceNumber }) => {
    const resp = await superFetch({
      url: `pdfs/pdls/${pdl}/${uorId}/invoices/${invoiceNumber}`,
      api: 'invoice',
    })

    checkStatus(resp)
    return resp
  },

  deletePdf: async (id) => {
    const resp = await superFetch({
      url: `pdfs/${id}`,
      method: 'DELETE',
      api: 'invoice',
    })

    checkStatus(resp)
    return resp
  },

  postBillPdf: async ({
    file,
    customerId,
    supplierCode,
    meterName,
    invoiceNumber,
    invoiceDate,
  }) => {
    const body = new FormData()
    body.append('file', file)
    body.append('customerId', customerId)
    body.append('supplierCode', supplierCode)
    body.append('meterName', meterName)
    body.append('invoiceNumber', invoiceNumber)
    body.append('invoiceDate', invoiceDate)

    const resp = await superFetch({
      url: 'pdfs/upload',
      method: 'POST',
      body,
      stringify: false,
      contentType: null,
      api: 'invoice',
    })

    checkStatus(resp)
    return resp
  },
}

export const fetchPdfs = (bill) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'PDF_FETCH_REQUEST' })

    const state = getState()

    const resp = await api.fetchPdfs({
      pdl: bill.FacPerimetre.PeeClef,
      uorId: getUor(state),
      invoiceNumber: bill.FacClef,
    })
    const data = await resp.json()

    dispatch({
      type: 'PDF_FETCH_SUCCESS',
      payload: keyBy(data, 'id'),
    })
  } catch (error) {
    dispatch({
      type: 'PDF_FETCH_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const postBillPdf = (file, bill) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'PDF_POST_REQUEST' })

    const state = getState()

    const payload = {
      file,
      customerId: user.uorIdMst(state),
      supplierCode: bill.FacFournisseur,
      meterName: bill.FacPerimetre.PeeClef,
      invoiceNumber: bill.FacClef,
      invoiceDate: bill.FacDate,
    }

    const resp = await api.postBillPdf(payload)
    const body = await resp.json()
    const billId = bill.id

    dispatch({
      type: 'PDF_POST_SUCCESS',
      payload: { body, billId },
    })
    dispatch(fetchPdfs(bill))
  } catch (error) {
    dispatch({
      type: 'PDF_POST_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const deleteBillPdf = (id, bill) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'PDF_DELETE_REQUEST' })
    await api.deletePdf(id)

    dispatch({
      type: 'PDF_DELETE_SUCCESS',
    })
    dispatch(fetchPdfs(bill))
  } catch (error) {
    dispatch({
      type: 'PDF_DELETE_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchPdf = async (id) => {
  try {
    const resp = await superFetch({
      url: `pdfs/${id}`,
      platform: 'INVOICE',
    })
    const { status, type, statusText } = resp
    if (status < 200 || status >= 300)
      throw new Error(
        `${status}${type && ` (${type})`}${statusText && ` - ${statusText}`}`
      )

    const disposition = resp.headers.get('content-disposition')

    let filename
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRx = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRx.exec(disposition)
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }

    const blob = await resp.blob()
    const contentType = resp.headers.get('Content-Type')
    download(blob, filename, contentType)
  } catch (error) {
    console.log(error)
  }
}

const initialState = {
  items: null,
  importResult: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PDF_FETCH_REQUEST':
    case 'PDF_POST_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'PDF_FETCH_SUCCESS':
      return {
        ...state,
        items: action.payload,
        loading: false,
        error: null,
      }
    case 'PDF_POST_SUCCESS':
      return {
        ...state,
        importResult: action.payload,
        loading: false,
        error: null,
      }
    case 'PDF_FETCH_FAILURE':
    case 'PDF_POST_FAILURE':
      return {
        ...state,
        items: null,
        importResult: null,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}

// Selectors
export const getPdfs = (state) => state.pdf.items
