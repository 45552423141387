import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { translate } from 'react-i18next'

import { getBu, fetchBu } from '../../../store/organisation'
import { getBusFilters, setBusFilters } from '../../../store/filters'

class Butree extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: this.props.filters,
    }
  }

  componentDidMount() {
    const { bu, fetchBu } = this.props
    if (!bu) fetchBu()
  }

  handleChange = (options) => {
    const values = options.map((option) => option.value)
    this.setState({ options: values })
    this.props.setFilters(values)
    if (typeof this.props.onChange === 'function') this.props.onChange()
  }

  render() {
    const { bu, isDisabled, t } = this.props

    let options = []

    if (!!bu) {
      const { Id, label, children } = bu
      const flatBu = !children ? [bu] : [{ Id, label }, ...children]
      options = flatBu.map(({ Id, label }) => ({ value: Id, label }))
    }

    const value = this.state.options.map((id) =>
      options.find((x) => x.value === id)
    )

    return (
      <div>
        <label>{t('control.filter.bu')}</label>
        <Select
          placeholder={t('control.filter.select')}
          isMulti={true}
          isClearable={false}
          isDisabled={isDisabled}
          value={value}
          onChange={this.handleChange}
          options={options}
          isLoading={!bu}
          className="react-select"
          classNamePrefix="react-select"
          styles={{
            container: (provided) => ({
              ...provided,
              width: '270px',
            }),
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bu: getBu(state),
  filters: getBusFilters(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchBu: () => {
    dispatch(fetchBu())
  },
  setFilters: (payload) => {
    dispatch(setBusFilters(payload))
  },
})

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Butree))
