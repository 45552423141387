import React, { Component } from 'react'
import { Collapse, Table, Button } from 'reactstrap'
import { getReliedBills } from '../../../store/bills'
import { decimalFormat } from '../../../helpers/formatters'
import classnames from 'classnames'

import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, withState, withHandlers } from 'recompose'

const icons = {
  'Volume contractuel': 'courbe-full',
  'Contract Volume': 'courbe-full',

  Energie: 'eclair',
  Energy: 'eclair',

  Transport: 'truck',
  'Grid Access': 'truck',

  Taxes: 'taxes',

  'Other(s)': 'vallet',
  'Autre(s)': 'vallet',
}

class PrincipalBillTable extends Component {
  render() {
    const { recallBill, t, toggle, collapse } = this.props

    let numfac = ''
    let date = ''
    let total = 0

    const bill = recallBill && recallBill.principale

    if (!!bill) {
      date = bill.FacDate
      numfac = bill.FacClef
      total = decimalFormat(bill.FacMontantHTVA)
    }

    return (
      <div className="recall-bill_container">
        {bill && (
          <div>
            <div>
              <Button
                onClick={toggle}
                className={classnames('recall-bill_collapse-btn', {
                  'recall-bill_collapse-btn--active': collapse,
                })}
              >
                <span>
                  <i className="icon-ion-document" />
                  <span className="recall-bill_num">{numfac}</span>
                  <span>
                    {this.props.t('billstable.date')} : {date}
                  </span>
                  <span>
                    {this.props.t('billstable.mtn')} : {total} €
                  </span>
                </span>

                <span>
                  <i
                    className={
                      collapse ? 'icon-collapse-moins' : 'icon-collapse-plus'
                    }
                  />
                </span>
              </Button>

              <Collapse isOpen={collapse}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{t('bills.bill.poste')}</th>
                      <th>{t('bills.bill.detail')}</th>
                      <th>{t('bills.bill.periode')}</th>
                      <th>{t('bills.bill.montant')}</th>
                    </tr>
                  </thead>

                  {bill.FacGroupeLfa &&
                    bill.FacGroupeLfa.map((i, iIndex) => (
                      <tbody key={iIndex}>
                        {i.IatAgrLignesFacture.map((j, jIndex) => (
                          <tr key={j.id} className={'row-' + (iIndex % 2)}>
                            {!jIndex && (
                              <td rowSpan={i.IatAgrLignesFacture.length}>
                                <i
                                  className={'icon-' + icons[i.IatAgrLibelle]}
                                />
                                <p>
                                  <span>{i.IatAgrLibelle}</span>
                                  <br />
                                  <span className="price">
                                    {decimalFormat(
                                      i.IatAgrLignesFacture.reduce(
                                        (cnt, l) => cnt + l.LfaValeur,
                                        0
                                      )
                                    )}{' '}
                                    {i.IatAgrUniteOuDevise}
                                  </span>
                                </p>
                              </td>
                            )}
                            <td>{j.LfaLibelle}</td>
                            <td>
                              {j.LfaDateDebut}
                              {' - '}
                              {j.LfaDateFin}
                            </td>
                            <td className="text-right">
                              {decimalFormat(j.LfaValeur)}{' '}
                              {i.IatAgrUniteOuDevise}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ))}
                </Table>
              </Collapse>
            </div>
          </div>
        )}
        <br />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  recallBill: getReliedBills(state),
})

const mapDispatchToProps = {}

export default compose(
  translate(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('collapse', 'toggle', false),
  withHandlers({
    toggle: ({ toggle }) => () => toggle((current) => !current),
  })
)(PrincipalBillTable)
