import React from 'react'
import { compose, withState, withHandlers } from 'recompose'
import { Container, Row, Col, Button, Form, Label, Input } from 'reactstrap'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'

import Loading from '../common/Loading'

import styles from './Admin.module.scss'

import {
  updateAdminOptions,
  getNegativeGap,
  getPositiveGap,
  getBillStateManualMgmt,
  getIsInit,
  getIsFetching,
  getError,
} from '../../store/admin'

const Admin = (props) => {
  const { t } = props

  const isLoading = !props.isInit || props.isFetching || props.error

  if (isLoading)
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    )

  return (
    <Container fluid className={styles.container}>
      <Form onSubmit={props.onSubmit} className={styles.form}>
        <Row className={styles.row}>
          <Col xs="7" className={styles.colRight}>
            <Label for="gapInput1" className={styles.label}>
              {t('admin.negativeGap')}
            </Label>
          </Col>

          <Col xs="5">
            <Input
              id="gapInput1"
              className={styles.input}
              type="number"
              min="-100"
              max="0"
              step="0.1"
              value={props.negativeGap}
              onChange={(e) => props.setNegativeGap(e.target.value)}
            />
          </Col>
        </Row>

        <Row className={styles.row}>
          <Col xs="7" className={styles.colRight}>
            <Label for="gapInput2" className={styles.label}>
              {t('admin.positiveGap')}
            </Label>
          </Col>

          <Col xs="5">
            <Input
              id="gapInput2"
              className={styles.input}
              type="number"
              min="0"
              max="100"
              step="0.1"
              value={props.positiveGap}
              onChange={(e) => props.setPositiveGap(e.target.value)}
            />
          </Col>
        </Row>

        <Row className={styles.row}>
          <Col xs="7" className={styles.colRight}>
            <Label for="billStateManualMgmt" className={styles.label}>
              {t('admin.billStateManualMgmt')}
            </Label>
          </Col>
          <Col xs="5">
            <Label check className={props.billStateManualMgmt ? 'checked' : ''}>
              <Input
                id="billStateManualMgmt"
                type="checkbox"
                checked={props.billStateManualMgmt}
                onChange={(e) => props.setBillStateManualMgmt(e.target.checked)}
              />
            </Label>
          </Col>
        </Row>

        <Row className={styles.row}>
          <Col xs="7" />
          <Col xs="5">
            <Button color="primary" size="xs" className={`${styles.submit}`}>
              {t('admin.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  negativeGap: getNegativeGap(state),
  positiveGap: getPositiveGap(state),
  billStateManualMgmt: getBillStateManualMgmt(state),
  isInit: getIsInit(state),
  isFetching: getIsFetching(state),
  error: getError(state),
})

const mapDispatchToProps = {
  updateAdminOptions,
}

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withState('negativeGap', 'setNegativeGap', (props) => props.negativeGap),
  withState('positiveGap', 'setPositiveGap', (props) => props.positiveGap),
  withState(
    'billStateManualMgmt',
    'setBillStateManualMgmt',
    (props) => props.billStateManualMgmt
  ),
  withHandlers({
    onSubmit: (props) => (event) => {
      event.preventDefault()
      const { negativeGap, positiveGap, billStateManualMgmt } = props
      const dNegativeGap = parseFloat(negativeGap)
      const dPositiveGap = parseFloat(positiveGap)
      const payload = {
        negativeGap: dNegativeGap,
        positiveGap: dPositiveGap,
        billStateManualMgmt,
      }
      props.updateAdminOptions(payload)
    },
  })
)(Admin)
