import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { Container, Row, Col, Form, Button } from 'reactstrap'

import * as user from 'commons'

import BillFileUpload from '../BillFileUpload'
import BillDocuments from '../BillDocuments'
import RecallBillHeader from './RecallBillHeader'
import PrincipalBillTable from './PrincipalBill'
import ReliedBillsTable from './ReliedBills'
import ReliedBillsStatus from './ReliedBillsStatus'
import ReliedBillsCoversTable from './ReliedBillsCovers'
import Loading from '../../common/Loading'
import Error from '../../common/Error'
import BillPdfList from '../BillPdfList'

import {
  getReliedBills,
  putBill,
  updateBillForm,
  getReliedBillsFetching,
  getReliedCoversBillsFetching,
  getReliedBillsError,
  getReliedCoversBillsError,
} from 'store/bills'
import { getBillStateManualMgmt } from 'store/admin'

class RecallBill extends Component {
  updateInput(k, v) {
    const keys = k.split('.')
    let o = v

    keys.reverse().map((i) => (o = { [i]: o }))

    this.props.updateBillForm(o)
  }

  handleClick = (e) => {
    e.preventDefault()
    this.props.history.goBack()
  }

  render() {
    const { t, recallBill, closeBill, isLoading, error } = this.props

    if (error)
      return (
        <div className="bill-error">
          <Error />
        </div>
      )

    if (isLoading)
      return (
        <div className="bill-loading">
          <Loading />
        </div>
      )

    if (!recallBill.principale) return null

    return (
      <div>
        <Button
          className="btn btn-close"
          style={{ border: 'none' }}
          onClick={closeBill}
        >
          <i className="icon-cross" />
        </Button>
        <Container fluid>
          <Form>
            <RecallBillHeader provider={recallBill.principale.FacFournisseur} />

            <div className="bill--wrapper">
              <h4 className="recall-bill-subtitle">
                {this.props.t('control.table.recallbill')}
              </h4>
              <PrincipalBillTable />

              <h4 className="recall-bill-subtitle">
                {this.props.t('control.table.othersbills')}
              </h4>

              <ReliedBillsTable />

              <h4 className="recall-bill-subtitle">
                {this.props.t('control.table.consolidedbill')}
              </h4>

              <ReliedBillsCoversTable />

              <Row className="recall-bill-more, recall-bill-bottom">
                {this.props.billStateManualMgmt && (
                  <Col xs="7">
                    <Row>
                      <Col>
                        <ReliedBillsStatus
                          updateBillsOnClose={this.props.updateBillsOnClose}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs="5" className="bill-docs">
                  <h4>{t('bills.bill.pdfAttachments')}</h4>

                  <BillPdfList bill={recallBill.principale} />

                  <h4>{t('bills.bill.attachments')}</h4>

                  {recallBill &&
                    recallBill.principale &&
                    recallBill.principale.FacDocsJoints && (
                      <ul>
                        {recallBill.principale.FacDocsJoints.map((doc) => (
                          <BillDocuments key={doc.id} {...doc} />
                        ))}
                      </ul>
                    )}

                  <BillFileUpload />
                </Col>
              </Row>
            </div>
          </Form>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: user.lngIso(state),
  recallBill: getReliedBills(state),
  isLoading:
    getReliedBillsFetching(state) || getReliedCoversBillsFetching(state),
  error: getReliedBillsError(state) || getReliedCoversBillsError(state),
  billStateManualMgmt: getBillStateManualMgmt(state),
})

const mapDispatchToProps = {
  putBill,
  updateBillForm,
}

RecallBill.propTypes = {
  putBill: PropTypes.func.isRequired,
  updateBillForm: PropTypes.func.isRequired,
  lng: PropTypes.string.isRequired,
}

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(RecallBill)
)
