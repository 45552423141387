import * as env from '../helpers/env'
import { superFetch } from '../api/apiConf'

export const logout = (accessToken) => {
  const redirectLogoutUrl = encodeURIComponent(env.autorityUrl)
  const logoutUrl = `${env.autorityUrl}/connect/endsession?id_token_hint=${accessToken}&post_logout_redirect_uri=${redirectLogoutUrl}`
  // On vide le local storage
  console.log('Clear Local Storage')
  localStorage.clear()
  // et le session storage pour ne plus avoir d'information sur le user connecté
  console.log('Clear Session Storage')
  sessionStorage.clear()
  document.location.href = logoutUrl
}

export const userInfos = (platform) => {
  return superFetch({
    url: `utilisateur`,
    platform: platform,
  })
}

export const postUser = (user, platform) => {
  return superFetch({
    url: `saveutilisateur?id=${user.id}`,
    method: 'PUT',
    body: user,
    platform: platform,
  })
}

export const changePassword = (uuid, passwordChange, platform) => {
  return superFetch({
    url: 'changepassword',
    uuid: uuid,
    method: 'POST',
    body: passwordChange,
    platform: platform,
  })
}
