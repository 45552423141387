import PropTypes from 'prop-types'
import React from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'

import { LogoEleneo } from 'components/LogoEleneo'

import { getReliedBills } from 'store/bills'

const RecallBillHeader = ({ provider, recallBill }) => {
  const site = recallBill.principale.FacSite.SphNom
  const pdl = recallBill.principale.FacPerimetre.PeeClef

  return (
    <div className="bill-header" style={{ height: '100px' }}>
      <Row>
        <Col>
          <h1>{provider}</h1>
          <span style={{ fontSize: '1.2rem' }}>
            {site} - {pdl}
          </span>
        </Col>
        <Col className="text-right">
          <LogoEleneo colorful style={{ fontSize: '3rem' }} />
        </Col>
      </Row>
    </div>
  )
}

RecallBillHeader.propTypes = {
  FacFournisseur: PropTypes.string,
}

const mapStateToProps = (state) => ({
  recallBill: getReliedBills(state),
})

export default connect(mapStateToProps)(RecallBillHeader)
