import React from 'react'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { withRouter } from 'react-router-dom'

import ControlValidationBillsTable from './ControlValidationBillsTable'
import VisibleRecallBill from '../bill/VisibleRecallBill'

const billUrlRx = /^\/recallbill\/\d+\/?$/

const ControlValidationBillsTableContainer = (props) => {
  return (
    <>
      <ControlValidationBillsTable
        openBill={props.openBill}
        setFilteredData={props.setFilteredData}
        filteredData={props.filteredRecallData}
      />

      <VisibleRecallBill
        billId={props.recallBillId}
        closeBill={props.closeBill}
        openBill={props.openBill}
        filteredBillIds={props.filteredRecallData.map((x) => x.id)}
      />
    </>
  )
}

export default compose(
  withRouter,
  withState('recallBillId', 'setBillId', null),
  withState('filteredRecallData', 'setFilteredData', []),
  withHandlers({
    closeBill: (props) => () => {
      props.setBillId(null)
      props.history.push(`/control/3`)
    },
    openBill: (props) => (id) => {
      props.setBillId(id)
      props.history.push(`/recallbill/${id}`)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { url, params } = this.props.match
      if (billUrlRx.test(url)) {
        this.props.setBillId(params.id)
      }
    },
  })
)(ControlValidationBillsTableContainer)
