import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchAdminOptions: () =>
    superFetch({
      url: 'utilisateur/options',
    }),
  updateAdminOptions: (payload) =>
    superFetch({
      url: 'utilisateur/options',
      method: 'PUT',
      body: payload,
    }),
}

// Actions
const FETCH_ADMIN_OPTIONS_REQUEST = 'einvoice/admin/FETCH_ADMIN_OPTIONS_REQUEST'
const FETCH_ADMIN_OPTIONS_SUCCESS = 'einvoice/admin/FETCH_ADMIN_OPTIONS_SUCCESS'
const FETCH_ADMIN_OPTIONS_FAILURE = 'einvoice/admin/FETCH_ADMIN_OPTIONS_FAILURE'
const UPDATE_ADMIN_OPTIONS_REQUEST =
  'einvoice/admin/UPDATE_ADMIN_OPTIONS_REQUEST'
const UPDATE_ADMIN_OPTIONS_SUCCESS =
  'einvoice/admin/UPDATE_ADMIN_OPTIONS_SUCCESS'
const UPDATE_ADMIN_OPTIONS_FAILURE =
  'einvoice/admin/UPDATE_ADMIN_OPTIONS_FAILURE'

// Reducer
export default (
  state = {
    billStateManualMgmt: false,
    isInit: false,
    isFetching: false,
    error: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_ADMIN_OPTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_ADMIN_OPTIONS_SUCCESS:
      return {
        ...state,
        ...action.response,
        isInit: true,
        isFetching: false,
        error: false,
      }
    case FETCH_ADMIN_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      }
    case UPDATE_ADMIN_OPTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case UPDATE_ADMIN_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
      }
    case UPDATE_ADMIN_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      }
    default:
      return state
  }
}

// Selectors
export const getNegativeGap = (state) => state.admin.negativeGap
export const getPositiveGap = (state) => state.admin.positiveGap
export const getBillStateManualMgmt = (state) => state.admin.billStateManualMgmt
export const getIsInit = (state) => state.admin.isInit
export const getIsFetching = (state) => state.admin.isFetching
export const getError = (state) => state.admin.error

// Thunks
export const fetchAdminOptions = () => (dispatch) => {
  dispatch({
    type: FETCH_ADMIN_OPTIONS_REQUEST,
  })

  return api.fetchAdminOptions().then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: FETCH_ADMIN_OPTIONS_SUCCESS,
          response: response,
        })
      })
    },
    (error) => {
      dispatch({
        type: FETCH_ADMIN_OPTIONS_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const updateAdminOptions = (options) => (dispatch) => {
  dispatch({
    type: UPDATE_ADMIN_OPTIONS_REQUEST,
  })

  return api.updateAdminOptions(options).then(
    (response) => {
      response.json().then((response) => {
        dispatch({
          type: UPDATE_ADMIN_OPTIONS_SUCCESS,
          response: response,
        })
        dispatch(fetchAdminOptions())
      })
    },
    (error) => {
      dispatch({
        type: UPDATE_ADMIN_OPTIONS_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}
