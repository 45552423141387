import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import './SelectStatus.scss'

import { defineStatusCodeUsetoChange, changeStatusBills } from 'store/bills'

class SelectStatusToChange extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: 'all',
    }
  }

  handleChange = (e) => {
    this.setState({
      status: e.target.value,
    })
    this.props.defineStatusCodeUsetoChange(e.target.value)
  }

  handleSubmit = (e) => {
    this.props.changeStatusBills(e)
  }

  render() {
    return (
      <div className={'select-status'}>
        <select className={'form-control'} onChange={this.handleChange}>
          <option value="all">{this.props.t('changeStatus.action')}</option>
          <option value="0">{this.props.t('changeStatus.todo')}</option>
          <option value="1">{this.props.t('changeStatus.valid')}</option>
          <option value="2">{this.props.t('changeStatus.nonCompliant')}</option>
        </select>
        <button
          className={'btn btn-secondary'}
          disabled={this.state.status === 'all'}
          onClick={this.handleSubmit}
        >
          {this.props.t('button.appliquer')}
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  defineStatusCodeUsetoChange,
  changeStatusBills,
}

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(SelectStatusToChange)
