import React from 'react'
import { Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import ControlPopupOver from './ControlPopupOver'
import { setPopover, getPopover } from 'store/bills'

const EmptyCell = () => (
  <div style={{ backgroundColor: '#adb5bd', height: '30px' }} />
)

const MonthCell = ({ month, handleClick, ...props }) => {
  const isEmpty = !props.row[month.accessor]

  const open = useSelector(getPopover)
  const dispatch = useDispatch()

  if (isEmpty) return <EmptyCell />

  const id = `_${month.accessor}${props.row[month.accessor][2].replace(
    /\//g,
    '_'
  )}`

  return (
    <div style={{ backgroundColor: '#C59D47' }} id={id}>
      <Button
        className="controlCellButton"
        id={id}
        onClick={() => dispatch(setPopover(id))}
      />

      <ControlPopupOver
        id={id}
        close={() => dispatch(setPopover(null))}
        isOpen={open === id}
        bills={props.row[month.accessor][3]}
        openBill={(billId) => {
          const billIds = props.row[month.accessor][3].map((x) => x.id)
          props.setVisibleBillIds(billIds)
          props.openBill(billId)
        }}
      />
    </div>
  )
}

export default MonthCell
